import React, { useState, useEffect, useRef } from 'react';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import defaultProfilePic from './assets/defaultProfilePic.png';

const scalingFactor = 0.7;
const { width, height } = { width: window.innerWidth * scalingFactor, height: window.innerHeight * scalingFactor };

const ProfileComponentMovie = ({ onClick, profileImageUrl, username, name, accountType }) => {
  const [contentWidth, setContentWidth] = useState(0);
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);
  const textContainerRef = useRef(null);

  const handleImageLoadStart = () => {
    setIsLoadingProfilePicture(true);
  };

  const handleImageLoadEnd = () => {
    setIsLoadingProfilePicture(false);
  };

  const formatUsername = (username) => {
    if (!username) return '@';
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  const profileImageSource = profileImageUrl || defaultProfilePic;

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(username);

  useEffect(() => {
    if (textContainerRef.current) {
      const textWidth = textContainerRef.current.getBoundingClientRect().width;
      const totalWidth = textWidth + width * 0.164 + 20 * scalingFactor; // Adjust padding
      setContentWidth(totalWidth);
    }
  }, [contentWidth]);

  return (
    <div onClick={onClick} style={{ ...styles.container, width: contentWidth || 'auto' }}>
      <div style={styles.imageStack}>
        <img
          src={profileImageSource}
          alt="Profile"
          style={styles.circularImage}
          onLoadStart={handleImageLoadStart}
          onLoad={handleImageLoadEnd}
        />
      </div>
      <div
        style={styles.textContainer}
        ref={textContainerRef}
      >
        <span style={styles.artistName}>{name}</span>
        <div style={styles.textWithIconContainer}>
          <span style={styles.username}>{formatUsername(username)}</span>
          {accountType !== 'Fan' && !isArtistIcon2Visible && (
            <img src={ArtistIcon1} style={styles.profilePicIcon} alt="Artist Icon 1" />
          )}
          {accountType !== 'Fan' && isArtistIcon2Visible && (
            <img src={ArtistIcon2} style={styles.profilePicIcon} alt="Artist Icon 2" />
          )}
        </div>
      </div>
      {isLoadingProfilePicture && (
        <div style={styles.loaderContainer}>
          <span>Loading...</span>
        </div>
      )}
    </div>
  );
};

const imageSize = width * 0.22;
const circularImageSize = width * 0.164;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px', // Adjust padding as necessary
    height: '80px', // Adjust height as necessary
  },
  imageStack: {
    width: '50px', // Adjust the width of the image
    height: '50px', // Adjust the height of the image
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: '7px',
  },
  circularImage: {
    width: '100%', // Adjust to fit the container
    height: '100%', // Adjust to fit the container
    borderRadius: '50%',
    objectFit: 'cover',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  artistName: {
    color: 'white',
    fontSize: '16px', // Adjust font size
    fontWeight: '400',
    textAlign: 'left',
    width: '115px',
    fontFamily: 'brhendrix',
  },
  textWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderContainer: {
    left: `${width * 0.118}px`,
    bottom: `${height * 0.057}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  username: {
    color: '#62fbfa',
    fontSize: '14px', // Adjust font size
    opacity: 0.8,
    fontWeight: '400',
    fontFamily: 'brhendrix',
    marginRight: '2px',
  },
  profilePicIcon: {
    width: '20px', // Adjust icon size
    height: '20px', // Adjust icon size
    objectFit: 'contain',
  },
};

export { ProfileComponentMovie };
