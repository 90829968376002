import React, { useContext, useState, useRef, useEffect, useCallback, useMemo } from 'react';
import {
  getFirestore, collection, query, orderBy, limit, startAfter, getDocs, doc, getDoc
} from 'firebase/firestore';
import SongItem from './SongItem';
import { useNavigate } from 'react-router-dom';
import { ProfileComponentProducerSmall } from './ProfileComponentProducerSmall';
import { AppContext } from './AppContext';

const Music = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  const [groupedByGenres, setGroupedByGenres] = useState({});
  const [lastGenreDocs, setLastGenreDocs] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataFeaturedMusic, setDataFeaturedMusic] = useState([]);
  const [featuredSongs, setFeaturedSongs] = useState([]);
  const [newReleases, setNewReleases] = useState([]);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isFetchingMoreGenre, setIsFetchingMoreGenre] = useState({});
  const [playlists, setPlaylists] = useState([]);
  const firestore = getFirestore();
  const navigate = useNavigate();

  const { followers, setCurrentlyLoadingProfile, currentUser, setActivePopupMedia, activePopupMedia, userId2 } = useContext(AppContext);


  const [producers, setProducers] = useState([]);
  const [featuredArtists, setFeaturedArtists] = useState([]);
  const [lastFeaturedArtistDoc, setLastFeaturedArtistDoc] = useState(null);
  const [lastProducersDoc, setLastProducersDoc] = useState(null);


  const featuredMusicScrollRef = useRef(null);
  const featuredSongsScrollRef = useRef(null);
  const newReleasesScrollRef = useRef(null);
  const genreScrollRefs = useMemo(() => ({}), []);

  // Fetch playlists on mount
  useEffect(() => {
    const fetchPlaylists = async () => {
      
      const playlistsDocRef = doc(firestore, 'Radio Stations Songs', 'AllPlaylists');
      try {
        const docSnap = await getDoc(playlistsDocRef);
        if (docSnap.exists()) {
          setPlaylists(docSnap.data().playlists || []);
          
        } else {
          
        }
      } catch (error) {
        console.error('Error getting document:', error);
      }
    };
    fetchPlaylists();
  }, [firestore]);

  const handlePlaylistSelection = (selectedPlaylist) => {
    // Implement the function logic here
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const handleNavigateSeeAll = async (category) => {
    navigate('/musicseeall', { state: { category: category } } );
  };

  const renderPlaylistItem = ({ item }) => (
    <div onClick={() => handlePlaylistSelection(item)} style={styles.playlistItem}>
      <img src={item.ArtworkURL} alt="" style={styles.coverImage} />
    </div>
  );

  const renderItem = useCallback(({ item }) => {
    if (item === 'loading') {
      return (
        <div style={styles.itemContainer}>
          <div style={styles.horizontalTouchable}>
            <div style={styles.songArtworkPlaceholder}>Loading...</div>
          </div>
        </div>
      );
    }
    return <SongItem item={item} onPress={handlePressBegins} />;
  }, [handlePressBegins]);

  const genresList = [
    { label: "Country", value: "country" },
    { label: "Rock", value: "rock" },
    { label: "Rap", value: "hip_hop_rap" },
    { label: "Pop", value: "pop" },
    { label: "Alternative", value: "Alternative" },
    { label: "Blues", value: "blues" },
    { label: "R&B/Soul", value: "rnb_soul" },
    { label: "Metal", value: "metal" },
    { label: "Indie", value: "indie" },
    { label: "Folk", value: "folk" },
    { label: "Classical", value: "classical" },
    { label: "Electronic/Dance", value: "electronic_dance" },
    { label: "Jazz", value: "jazz" },
    { label: "Latin", value: "latin" },
    { label: "Reggae", value: "reggae" },
    { label: "World/International", value: "world_international" }
  ];

  const filteredGenres = useMemo(() => Object.keys(groupedByGenres).filter(genre => groupedByGenres[genre].length > 0), [groupedByGenres]);

  // Fetch initial genre songs
  const fetchInitialGenreSongs = async () => {
    
    try {
      const fetchSize = 8;
      let genreData = {};
      let genreLastDocs = {};

      const fetchGenreData = async (genreObj) => {
        const genre = genreObj.value;

        const singlesQuery = query(
          collection(firestore, `MusicGenres/${genre}/Singles`),
          orderBy('releaseTimestamp', 'desc'),
          limit(fetchSize / 2)
        );

        const albumsQuery = query(
          collection(firestore, `MusicGenres/${genre}/Albums`),
          orderBy('releaseTimestamp', 'desc'),
          limit(fetchSize / 2)
        );

        const [singlesSnapshot, albumsSnapshot] = await Promise.all([
          getDocs(singlesQuery),
          getDocs(albumsQuery),
        ]);

        const combinedData = [...singlesSnapshot.docs, ...albumsSnapshot.docs].sort((a, b) => {
          return b.data().releaseTimestamp - a.data().releaseTimestamp;
        });

        genreData[genre] = combinedData.map(doc => ({ id: doc.id, ...doc.data() }));
        genreLastDocs[genre] = {
          singles: singlesSnapshot.docs[singlesSnapshot.docs.length - 1],
          albums: albumsSnapshot.docs[albumsSnapshot.docs.length - 1],
        };

        // Update state as soon as data for each genre is fetched
        setGroupedByGenres(prev => ({ ...prev, [genre]: genreData[genre] }));
        setLastGenreDocs(prev => ({ ...prev, [genre]: genreLastDocs[genre] }));
        
      };

      await Promise.all(genresList.map(fetchGenreData));

      
    } catch (error) {
      console.error("Error fetching genre songs:", error);
    }
  };

  const fetchMoreFeaturedMusic = async (lastDoc) => {
    
    

    if (isFetchingMore || !lastDoc) {
      
      return;
    }

    setIsFetchingMore(true);
    

    let fetchedUniqueDocs = false;
    let newDocs = [];

    try {
      while (!fetchedUniqueDocs) {
        const nextQuery = query(
          collection(firestore, 'FeaturedMusic'),
          orderBy('releaseTimestamp', 'desc'),
          startAfter(lastDoc),
          limit(8)
        );
        

        const snapshot = await getDocs(nextQuery);
        

        if (!snapshot.empty) {
          newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          

          // Check if the fetched documents are already in the existing list
          const existingIds = new Set(dataFeaturedMusic.map(doc => doc.id));
          const uniqueDocs = newDocs.filter(doc => !existingIds.has(doc.id));

          if (uniqueDocs.length > 0) {
            
            fetchedUniqueDocs = true;

            setDataFeaturedMusic(prev => {
              const updatedMusic = removeDuplicates([...prev, ...uniqueDocs]);
              
              return updatedMusic;
            });

            lastDoc = snapshot.docs[snapshot.docs.length - 1];
            
          } else {
            
            lastDoc = snapshot.docs[snapshot.docs.length - 1];
          }
        } else {
          
          break;
        }
      }
    } catch (error) {
      console.error('Error fetching more featured music:', error);
    }

    setIsFetchingMore(false);
    
  };

  const fetchMoreFeaturedSongs = async (lastDoc) => {
    
    

    if (isFetchingMore || !lastDoc) {
      
      return;
    }

    setIsFetchingMore(true);
    

    let fetchedUniqueDocs = false;
    let newDocs = [];

    try {
      while (!fetchedUniqueDocs) {
        const nextQuery = query(
          collection(firestore, 'Albums'),
          orderBy('releaseTimestamp', 'desc'),
          startAfter(lastDoc),
          limit(8)
        );
        

        const snapshot = await getDocs(nextQuery);
        

        if (!snapshot.empty) {
          newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          

          // Check if the fetched documents are already in the existing list
          const existingIds = new Set(featuredSongs.map(doc => doc.id));
          const uniqueDocs = newDocs.filter(doc => !existingIds.has(doc.id));

          if (uniqueDocs.length > 0) {
            
            fetchedUniqueDocs = true;

            setFeaturedSongs(prev => {
              const updatedSongs = removeDuplicates([...prev, ...uniqueDocs]);
              
              return updatedSongs;
            });

            lastDoc = snapshot.docs[snapshot.docs.length - 1];
            
          } else {
            
            lastDoc = snapshot.docs[snapshot.docs.length - 1];
          }
        } else {
          
          break;
        }
      }
    } catch (error) {
      console.error('Error fetching more featured songs:', error);
    }

    setIsFetchingMore(false);
    
  };

  const fetchMoreNewReleases = async (lastDoc) => {
    
    

    if (isFetchingMore || !lastDoc) {
      
      return;
    }

    setIsFetchingMore(true);
    

    let fetchedUniqueDocs = false;
    let newDocs = [];

    try {
      while (!fetchedUniqueDocs) {
        const nextQuery = query(
          collection(firestore, 'Songs'),
          orderBy('releaseTimestamp', 'desc'),
          startAfter(lastDoc),
          limit(8)
        );
        

        const snapshot = await getDocs(nextQuery);
        

        if (!snapshot.empty) {
          newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          

          // Check if the fetched documents are already in the existing list
          const existingIds = new Set(newReleases.map(doc => doc.id));
          const uniqueDocs = newDocs.filter(doc => !existingIds.has(doc.id));

          if (uniqueDocs.length > 0) {
            
            fetchedUniqueDocs = true;

            setNewReleases(prev => {
              const updatedReleases = removeDuplicates([...prev, ...uniqueDocs]);
              
              return updatedReleases;
            });

            lastDoc = snapshot.docs[snapshot.docs.length - 1];
            
          } else {
            
            lastDoc = snapshot.docs[snapshot.docs.length - 1];
          }
        } else {
          
          break;
        }
      }
    } catch (error) {
      console.error('Error fetching more new releases:', error);
    }

    setIsFetchingMore(false);
    
  };



  const fetchMoreProducers = async () => {
    if (!lastProducersDoc || isFetchingMore) return;
    
    setIsFetchingMore(true);
  
    try {
      const nextQuery = query(
        collection(firestore, 'ProducersWithBeats'),
        orderBy('lastUpdated', 'desc'),
        startAfter(lastProducersDoc),
        limit(8)
      );
  
      const snapshot = await getDocs(nextQuery);
  
      setProducers(prev => [...prev, ...snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))]);
      setLastProducersDoc(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching more producers:", error);
    } finally {
      setIsFetchingMore(false);
    }
  };
  
  const fetchMoreFeaturedArtists = async () => {
    if (!lastFeaturedArtistDoc || isFetchingMore) return;
    
    setIsFetchingMore(true);
  
    try {
      const nextQuery = query(
        collection(firestore, 'Featured Artists'),
        orderBy('name', 'asc'),
        startAfter(lastFeaturedArtistDoc),
        limit(8)
      );
  
      const snapshot = await getDocs(nextQuery);
  
      setFeaturedArtists(prev => [...prev, ...snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))]);
      setLastFeaturedArtistDoc(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching more featured artists:", error);
    } finally {
      setIsFetchingMore(false);
    }
  };



  const fetchMoreGenreSongs = async (genre) => {
    if (groupedByGenres[genre].length < 3) return;
  
    if (isFetchingMore[genre]) return;
  
    const lastDocs = lastGenreDocs[genre];
    if (!lastDocs || (!lastDocs.singles && !lastDocs.albums)) {
      
      return;
    }
  
    
    setIsFetchingMoreGenre(prev => ({ ...prev, [genre]: true }));
  
    try {
      const singlesQuery = lastDocs.singles ? query(
        collection(firestore, `MusicGenres/${genre}/Singles`),
        orderBy('releaseTimestamp', 'desc'),
        startAfter(lastDocs.singles),
        limit(8)
      ) : null;
  
      const albumsQuery = lastDocs.albums ? query(
        collection(firestore, `MusicGenres/${genre}/Albums`),
        orderBy('releaseTimestamp', 'desc'),
        startAfter(lastDocs.albums),
        limit(8)
      ) : null;
  
      const singlesPromise = singlesQuery ? getDocs(singlesQuery) : Promise.resolve({ docs: [] });
      const albumsPromise = albumsQuery ? getDocs(albumsQuery) : Promise.resolve({ docs: [] });
  
      const [singlesSnapshot, albumsSnapshot] = await Promise.all([
        singlesPromise,
        albumsPromise,
      ]);
  
      const newDocs = [...singlesSnapshot.docs, ...albumsSnapshot.docs].sort((a, b) => {
        return b.data().releaseTimestamp - a.data().releaseTimestamp;
      });
  
      if (newDocs.length > 0) {
        
  
        setGroupedByGenres(prev => {
          const updatedGenre = [...prev[genre], ...newDocs.map(doc => ({ id: doc.id, ...doc.data() }))];
          const uniqueUpdatedGenre = removeDuplicates(updatedGenre, 'id'); // Ensure unique documents
          return {
            ...prev,
            [genre]: uniqueUpdatedGenre
          };
        });
  
        setLastGenreDocs(prev => ({
          ...prev,
          [genre]: {
            singles: singlesSnapshot.docs.length ? singlesSnapshot.docs[singlesSnapshot.docs.length - 1] : lastDocs.singles,
            albums: albumsSnapshot.docs.length ? albumsSnapshot.docs[albumsSnapshot.docs.length - 1] : lastDocs.albums,
          }
        }));
  
        
      } else {
        
      }
  
    } catch (error) {
      console.error(`Error fetching more songs for genre: ${genre}`, error);
    } finally {
      setIsFetchingMoreGenre(prev => ({ ...prev, [genre]: false }));
    }
  };

  const handleScroll = (ref, fetchMoreFunc, lastItem) => {
    if (!ref.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = ref.current;
    

    if (scrollLeft + clientWidth >= scrollWidth - 5) {
      
      setTimeout(() => fetchMoreFunc(lastItem), 100); // Pass the last item rendered
    }
  };

  // Add scroll event listeners for featured music, featured songs, and new releases
  useEffect(() => {
    const handleFeaturedMusicScroll = () => handleScroll(featuredMusicScrollRef, fetchMoreFeaturedMusic, dataFeaturedMusic[dataFeaturedMusic.length - 1]);
    const handleFeaturedSongsScroll = () => handleScroll(featuredSongsScrollRef, fetchMoreFeaturedSongs, featuredSongs[featuredSongs.length - 1]);
    const handleNewReleasesScroll = () => handleScroll(newReleasesScrollRef, fetchMoreNewReleases, newReleases[newReleases.length - 1]);

    if (featuredMusicScrollRef.current) {
      featuredMusicScrollRef.current.addEventListener('scroll', handleFeaturedMusicScroll);
      
    }
    if (featuredSongsScrollRef.current) {
      featuredSongsScrollRef.current.addEventListener('scroll', handleFeaturedSongsScroll);
      
    }
    if (newReleasesScrollRef.current) {
      newReleasesScrollRef.current.addEventListener('scroll', handleNewReleasesScroll);
      
    }

    return () => {
      if (featuredMusicScrollRef.current) {
        featuredMusicScrollRef.current.removeEventListener('scroll', handleFeaturedMusicScroll);
        
      }
      if (featuredSongsScrollRef.current) {
        featuredSongsScrollRef.current.removeEventListener('scroll', handleFeaturedSongsScroll);
        
      }
      if (newReleasesScrollRef.current) {
        newReleasesScrollRef.current.removeEventListener('scroll', handleNewReleasesScroll);
        
      }
    };
  }, [dataFeaturedMusic, featuredSongs, newReleases]);

  // Add and remove scroll event listeners for genre sections
  useEffect(() => {
    Object.keys(genreScrollRefs).forEach(genre => {
      const ref = genreScrollRefs[genre];
      if (ref.current) {
        const handleGenreScroll = () => handleScroll(ref, () => fetchMoreGenreSongs(genre, lastGenreDocs[genre]));
        ref.current.addEventListener('scroll', handleGenreScroll);
        

        return () => {
          if (ref.current) {
            ref.current.removeEventListener('scroll', handleGenreScroll);
            
          }
        };
      }
    });
  }, [genreScrollRefs, groupedByGenres, lastGenreDocs]);

  // Fetch initial albums, singles, and genre songs on mount
  useEffect(() => {
    const fetchSize = 8;

    const fetchAlbumsAndSingles = async () => {
      

      try {
        const featuredMusicQuery = query(collection(firestore, 'FeaturedMusic'), orderBy('releaseTimestamp', 'desc'), limit(fetchSize));
        const featuredMusicSnapshot = await getDocs(featuredMusicQuery);
        setDataFeaturedMusic(featuredMusicSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        

        const featuredQuery = query(collection(firestore, 'Albums'), orderBy('releaseTimestamp', 'desc'), limit(fetchSize));
        const albumsSnapshot = await getDocs(featuredQuery);
        setFeaturedSongs(albumsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        

        const newReleasesQuery = query(collection(firestore, 'Songs'), orderBy('releaseTimestamp', 'desc'), limit(fetchSize));
        const singlesSnapshot = await getDocs(newReleasesQuery);
        setNewReleases(singlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

        const producersQuery = query(collection(firestore, 'ProducersWithBeats'), orderBy('lastUpdated', 'desc'), limit(fetchSize));
        const producersSnapshot = await getDocs(producersQuery);
        setProducers(producersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLastProducersDoc(producersSnapshot.docs[producersSnapshot.docs.length - 1]);
  
        const featuredArtistsQuery = query(collection(firestore, 'Featured Artists'), orderBy('name', 'asc'), limit(fetchSize));
        const featuredArtistsSnapshot = await getDocs(featuredArtistsQuery);
        setFeaturedArtists(featuredArtistsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLastFeaturedArtistDoc(featuredArtistsSnapshot.docs[featuredArtistsSnapshot.docs.length - 1]);
        

        await fetchInitialGenreSongs();
        setLoading(false);
        
      } catch (error) {
        console.error("Error fetching albums and singles:", error);
        setLoading(false);
      }
    };

    fetchAlbumsAndSingles();
  }, []);

  const removeDuplicates = (array) => {
    const seen = new Set();
    return array.filter(item => {
      const isDuplicate = seen.has(item.id);
      seen.add(item.id);
      return !isDuplicate;
    });
  };

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <div style={styles.horizontalSection}>
          <div style={styles.horizontalScroll} ref={featuredMusicScrollRef}>
            {/* Render featured music */}
          </div>
        </div>
        {['Featured Music', 'New Albums', 'New Singles'].map((text, index) => (
          <div key={index} style={styles.horizontalSection}>
            <div style={styles.headerRow}>  
            <p style={styles.horizontalText}>{text}</p>
            <button 
      style={styles.seeAllButtonContainer}
      onClick={() => handleNavigateSeeAll(text)}>
      <span style={styles.seeAllButtonText}>See All</span>
    </button>
            </div>
            <div style={styles.horizontalScroll} ref={index === 0 ? featuredMusicScrollRef : index === 1 ? featuredSongsScrollRef : newReleasesScrollRef}>
              {(index === 0 ? dataFeaturedMusic : index === 1 ? featuredSongs : newReleases).map((item, i, arr) => {
                if (i === arr.length - 1) {
                  if (index === 0) {
                    return (
                      <div key={item.id} id="lastFeaturedMusicItem">
                        {renderItem({ item })}
                      </div>
                    );
                  } else if (index === 1) {
                    return (
                      <div key={item.id} id="lastFeaturedSongItem">
                        {renderItem({ item })}
                      </div>
                    );
                  } else {
                    return (
                      <div key={item.id} id="lastNewReleaseItem">
                        {renderItem({ item })}
                      </div>
                    );
                  }
                }
                return renderItem({ item });
              })}
            </div>
          </div>
        ))}
  
  {featuredArtists.length > 0 && (
          <div style={styles.horizontalSection}>
            <div style={styles.headerRow}>
              <p style={styles.horizontalText}>Featured Artists</p>
              <button
                style={styles.seeAllButtonContainer}
                onClick={() => handleNavigateSeeAll('Featured Artists')}
              >
                <span style={styles.seeAllButtonText}>See All</span>
              </button>
            </div>
            <div style={styles.horizontalScroll}>
              {featuredArtists.map((item) => (
                  <ProfileComponentProducerSmall
                    onClick={() => handlePressProfile(item)}
                    item={item}
                  />
              ))}
              {isFetchingMore && (
                <div style={styles.loadingIndicatorContainer}>
                  <div style={styles.activityIndicator} />
                </div>
              )}
            </div>
          </div>
        )}

        {producers.length > 0 && (
          <div style={styles.horizontalSection}>
            <div style={styles.headerRow}>
              <p style={styles.horizontalText}>Producers</p>
              <button
                style={styles.seeAllButtonContainer}
                onClick={() => handleNavigateSeeAll('Producers')}
              >
                <span style={styles.seeAllButtonText}>See All</span>
              </button>
            </div>
            <div style={styles.horizontalScroll}>
              {producers.map((item) => (
                  <ProfileComponentProducerSmall
                    onClick={() => handlePressProfile(item)}
                    item={item}
                  />
              ))}
              {isFetchingMore && (
                <div style={styles.loadingIndicatorContainer}>
                  <div style={styles.activityIndicator} />
                </div>
              )}
            </div>
          </div>
        )}


  {groupedByGenres.country && groupedByGenres.country.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Country</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Country')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.country = { current: el }}>
      {groupedByGenres.country.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastCountryItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.rock && groupedByGenres.rock.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Rock</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Rock')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.rock = { current: el }}>
      {groupedByGenres.rock.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastRockItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.hip_hop_rap && groupedByGenres.hip_hop_rap.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Rap</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Rap')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.hip_hop_rap = { current: el }}>
      {groupedByGenres.hip_hop_rap.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastHipHopRapItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.pop && groupedByGenres.pop.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Pop</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Pop')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.pop = { current: el }}>
      {groupedByGenres.pop.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastPopItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.Alternative && groupedByGenres.Alternative.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Alternative</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Alternative')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.Alternative = { current: el }}>
      {groupedByGenres.Alternative.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastAlternativeItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.blues && groupedByGenres.blues.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Blues</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Blues')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.blues = { current: el }}>
      {groupedByGenres.blues.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastBluesItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.rnb_soul && groupedByGenres.rnb_soul.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>R&B/Soul</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('R&B/Soul')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.rnb_soul = { current: el }}>
      {groupedByGenres.rnb_soul.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastRnbSoulItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.metal && groupedByGenres.metal.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Metal</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Metal')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.metal = { current: el }}>
      {groupedByGenres.metal.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastMetalItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.indie && groupedByGenres.indie.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Indie</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Indie')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.indie = { current: el }}>
      {groupedByGenres.indie.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastIndieItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.folk && groupedByGenres.folk.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Folk</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Folk')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.folk = { current: el }}>
      {groupedByGenres.folk.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastFolkItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.classical && groupedByGenres.classical.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Classical</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Classical')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.classical = { current: el }}>
      {groupedByGenres.classical.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastClassicalItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.electronic_dance && groupedByGenres.electronic_dance.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Electronic/Dance</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Electronic/Dance')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.electronic_dance = { current: el }}>
      {groupedByGenres.electronic_dance.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastElectronicDanceItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.jazz && groupedByGenres.jazz.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Jazz</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Jazz')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.jazz = { current: el }}>
      {groupedByGenres.jazz.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastJazzItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.latin && groupedByGenres.latin.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Latin</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Latin')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.latin = { current: el }}>
      {groupedByGenres.latin.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastLatinItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.reggae && groupedByGenres.reggae.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>Reggae</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('Reggae')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.reggae = { current: el }}>
      {groupedByGenres.reggae.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastReggaeItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
    </div>
  </div>
)}

{groupedByGenres.world_international && groupedByGenres.world_international.length > 0 && (
  <div style={styles.horizontalSection}>
    <div style={styles.headerRow}>  
      <p style={styles.horizontalText}>World/International</p>
      <button 
        style={styles.seeAllButtonContainer}
        onClick={() => handleNavigateSeeAll('World/International')}>
        <span style={styles.seeAllButtonText}>See All</span>
      </button>
    </div>
    <div style={styles.horizontalScroll} ref={el => genreScrollRefs.world_international = { current: el }}>
      {groupedByGenres.world_international.map((item, i, arr) => {
        if (i === arr.length - 1) {
          return (
            <div key={item.id} id="lastWorldInternationalItem">
              {renderItem({ item })}
            </div>
          );
        }
        return renderItem({ item });
      })}
            </div>
          </div>
        )}
        <div style={styles.extraSpace}></div>
      </div>
    </div>
  );
  
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  extraSpace: {
    height: '300px',
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    width: '105%',
  },
  horizontalSection: {
    marginBottom: '20px',
  },
  horizontalText: {
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
    color: 'white',
  },
  seeAllButtonContainer: {
    marginRight: '20px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    top: '22px',
    cursor: 'pointer',
  },
  headerRow: {
    justifyContent: 'space-between', 
    display: 'flex',
  },
  seeAllButtonText: {
    fontSize: '20px',
    color: '#62fbfa',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  horizontalScroll: {
    display: 'flex',
    overflowX: 'auto',
    gap: '10px',
    whiteSpace: 'nowrap', // Prevent wrapping
  },
  playlistItem: {
    display: 'inline-block', // Change to inline-block to align items horizontally
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
    width: '100px',
  },
  coverImage: {
    width: '100px',
    height: '100px',
    borderRadius: '10px',
  },
  itemContainer: {
    display: 'inline-block', // Change to inline-block to align items horizontally
    marginRight: '10px',
    width: '100px',
  },
  horizontalTouchable: {
    width: '100px',
    height: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtworkPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: '#cccccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default Music;
