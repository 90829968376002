import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';

const ProfileComponentPostsMessages = ({ onPress, userProfile }) => {
  const { } = useContext(AppContext);
  const [contentWidth, setContentWidth] = useState(0);
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);

  const profileImageWidth = window.innerWidth * 0.164;
  const padding = window.innerWidth * 0.01;

  const onTextLayout = (event) => {
    const textWidth = event.target.offsetWidth;
    const totalWidth = profileImageWidth + textWidth + padding;
    setContentWidth(totalWidth);
  };

  if (isLoadingProfilePicture) {
    return (
      <div style={styles.loaderContainer}>
        <div style={styles.activityIndicator}></div>
      </div>
    );
  }

  const profileImageSource = userProfile?.localProfileImageUrl || userProfile?.profileImageUrl || './assets/defaultProfilePic.png';

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(userProfile?.username);

  const formatUsername = username => {
    if (!username) return '@';
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  return (
    <div onClick={onPress} style={{ ...styles.container, width: contentWidth || 'auto' }}>
      <div style={styles.profileViewStyle}>
        <div style={styles.imageStack}>
          <img src={profileImageSource} alt="Profile" style={styles.circularImage} />
        </div>
        <div onLayout={onTextLayout} style={styles.textContainer}>
          <div style={styles.artistName}>{userProfile?.name}</div>
          <div style={styles.textWithIconContainer}>
            <div style={styles.username}>{formatUsername(userProfile?.username)}</div>
            {userProfile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
                <img src={ArtistIcon1} alt="Artist Icon 1" style={styles.profilePicIcon} />
              )}
              {userProfile?.accountType !== 'Fan' && isArtistIcon2Visible && (
                <img src={ArtistIcon2} alt="Artist Icon 2" style={styles.profilePicIcon} />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const imageSize = window.innerWidth * 0.22;
const circularImageSize = window.innerWidth * 0.164;

const styles = {
  container: {
    alignItems: 'center',
    flex: 1,
  },
  profileViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    transform: 'scale(0.8)',
  },
  imageStack: {
    width: '50px', // Adjust the width of the image
    height: '50px',
    borderRadius: '50%',
    position: 'relative', 
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: '7px',
  },
  circularImage: {
    width: '100%', // Adjust to fit the container
    height: '100%',
    borderRadius: '50%',
    position: 'absolute',
    objectFit: 'cover',
    top: '49.7%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textContainer: {
    justifyContent: 'center',
  },
  artistName: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'regular',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  textWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderContainer: {
    left: window.innerWidth * 0.118,
    bottom: window.innerHeight * 0.057,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {},
  username: {
    color: '#62fbfa',
    fontSize: '14px',
    opacity: 0.9,
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    marginRight: 2,
  },
  profilePicIcon: {
    width: '20px',
    opacity: 1,
    height: '20px',
    objectFit: 'contain',
  },
};

export { ProfileComponentPostsMessages };
