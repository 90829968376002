import React, { useState, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, serverTimestamp, collection } from 'firebase/firestore';
import { AppContext } from './AppContext';
import { firestore } from './firebase';

const PostScreenShareDATprofile = ({ onNavigate, onPost, viewingProfile }) => {
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);
  const [tempImageUri, setTempImageUri] = useState(null);

  const { showMediaPopup, centeredRelease, currentUser, posts, setPosts, myPosts, setMyPosts, setCurrentlyLoadingProfile, setViewingProfile, userId2, setActivePopupMedia, activePopupMedia } = useContext(AppContext);

  const handlePost = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert('Please log in to post');
      return;
    }

    setUploading(true);

    try {
      const userRef = userId2 ? doc(firestore, 'users', userId2) : null;
      const artistUserRef = viewingProfile?.userId ? doc(firestore, 'users', viewingProfile.userId) : null;
      const docRef = doc(collection(getFirestore(), 'posts'));
      const profile = {
        userId: viewingProfile?.userId,
        name: viewingProfile?.artistName || viewingProfile?.ArtistName,
        profileImageUrl: viewingProfile.profileImageUrl,
        artistUserRef,
        accountType: viewingProfile.accountType,
        username: viewingProfile?.username,
      };
      const postData = {
        id: docRef.id,
        postId: docRef.id,
        text,
        imageUri: centeredRelease?.ArtworkURL || '',
        userRef,
        accountType: currentUser.accountType,
        userId: user.uid,
        username: currentUser.username,
        name: currentUser.name,
        profileImageUrl: currentUser.profileImageUrl,
        createdAt: serverTimestamp(),
        likeCount: 0,
        repostCount: 0,
        likes: {},
        comments: [],
        repostUserIds: [],
        profile: profile,
      };

      await setDoc(docRef, postData);
      

      setPosts((prevPosts) => [...prevPosts, postData]);

      const navigationPostData = {
        ...postData,
        createdAt: {
          seconds: Math.floor(Date.now() / 1000),
          nanoseconds: 0,
        },
      };

      setText('');
      setTempImageUri(null);
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Error creating post. Please try again later.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div style={{ marginBottom: '-50px', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '10px' }}>
        <textarea
          placeholder="Share to Feed..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{
            backgroundColor: 'rgba(81, 33, 131, 0.5)',
            padding: '10px',
            width: '80%',
            height: '45px',
            marginBottom: '10px',
            fontSize: '16px',
            color: 'white',
            borderRadius: '10px',
            borderColor: 'rgba(189, 252, 251, 0.3)',
          }}
        />

        {viewingProfile?.profileImageUrl && (
          <img
            src={viewingProfile.profileImageUrl}
            alt="Profile"
            style={{ width: '150px', height: '150px', borderRadius: '10px', marginBottom: '10px' }}
          />
        )}

        {uploading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <button
            onClick={handlePost}
            style={{
              width: '140px',
              height: '50px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              color: 'white',
              fontSize: '16px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <img src={require('./assets/postDAT.png')} alt="Post" style={{ width: '100%', height: '100%' }} />
          </button>
        )}
      </div>
    </div>
  );
};

export { PostScreenShareDATprofile };
