import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectHandler = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');
    const path = location.pathname;

    console.log('Source:', source);
    console.log('Path:', path);

    if (source === 'app') {
      let appLink = '';
      const fallbackLink = 'https://apps.apple.com/us/app/spndat/id6503178936'; // Update with your App Store URL

      if (path.includes('/userprofile/')) {
        const username = path.split('/userprofile/')[1];
        console.log('User Profile Username:', username);
        if (username) {
          const sanitizedUsername = username.replace('@', ''); // Remove "@" if it exists
          appLink = `spndat://userprofile/${sanitizedUsername}`;
        }
      } else if (path.includes('/music/')) {
        const parts = path.split('/music/');
        console.log('Music Parts:', parts);
        if (parts.length === 2) {
          const username = parts[1].split('/')[0];
          const contentId = parts[1].split('/')[1];
          console.log('Music Username:', username);
          console.log('Music Content ID:', contentId);
          if (username && contentId) {
            const sanitizedUsername = username.replace('@', ''); // Remove "@" if it exists
            appLink = `spndat://music/${sanitizedUsername}/${contentId}`;
          }
        }
      }

      if (appLink) {
        console.log('App Link:', appLink);
        // Listen for the blur event to detect if the app was opened
        const handleBlur = () => {
          clearTimeout(timer);
        };

        window.addEventListener('blur', handleBlur);

        // Try to open the app
        window.location.href = appLink;

        // If the app is not installed, redirect to the App Store after a short delay
        const timer = setTimeout(() => {
          console.log('Redirecting to fallback:', fallbackLink);
          window.location.href = fallbackLink;
          window.removeEventListener('blur', handleBlur);
        }, 2000); // Adjust the timeout as needed

        // Cleanup the event listener if the component unmounts
        return () => {
          window.removeEventListener('blur', handleBlur);
        };
      }
    }
  }, [location]);

  return null;
};

export default RedirectHandler;
