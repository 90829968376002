import React, { useContext, useMemo } from 'react';
import { AppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';
import BlankBG from './assets/BlankBG.png';
import Dot from './assets/dot.png';

const bottomAreaHeight = '18vh';
const scrollAreaTopPadding = '16vh';
const imageSize = '2vh';

const ArtistsScreenMyContent = () => {
  const { currentUser, purchasedMedia } = useContext(AppContext);
  const navigate = useNavigate();

  const artists = useMemo(() => {
    if (!currentUser || !currentUser.name) return [];
    const allArtists = purchasedMedia
      .map(item => item.ArtistName)
      .filter(artistName => artistName !== currentUser.name); // Exclude current user's name
    return [...new Set(allArtists)].sort(); // Remove duplicates and sort
  }, [purchasedMedia]);

  const handleSelectArtist = (artist) => {
    navigate(`/artistsmedia/${artist}`);
  };

  const categories = currentUser?.accountType === 'Filmmaker' ? [
    { name: 'Movie +', route: 'ReleaseFilm' },
    { name: 'Trailer +', route: 'ReleaseTrailer' },
    { name: 'My Submissions', route: 'mysubmissions' },
  ] : currentUser?.accountType === 'Producer' ? [
    { name: 'Music +', route: 'ReleaseMusic' },
    { name: 'Beats +', route: 'ReleaseBeats' },
    { name: 'Videos +', route: 'ReleaseMusicVideos' },
    { name: 'Voice Drop +', route: 'VoiceDropPage' },
    { name: 'ScreenSKN +', route: 'ScreenSKNPage' },
    { name: 'Review and Delete', route: 'RemoveReleasesPage' }
  ] : [
    { name: 'Music +', route: 'ReleaseMusic' },
    { name: 'Videos +', route: 'ReleaseMusicVideos' },
    { name: 'Voice Drop +', route: 'VoiceDropPage' },
    { name: 'ScreenSKN +', route: 'ScreenSKNPage' },
    { name: 'Review and Delete', route: 'RemoveReleasesPage' }
  ];
  

  const handleSelectCategory = (category) => {
    navigate(`/mydat/mycontent/${category.route}`);
  };

  return (
    <div style={styles.fullScreen}>
      <img src={BlankBG} alt="Background" style={styles.fullScreenImage} />
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <h2 style={styles.headerText}>MY CONTENT</h2>
        </div>
        <ul style={styles.scrollContainer}>
          {categories.map((category, index) => (
            <li key={`category-${index}`} style={styles.listItem}>
              <button onClick={() => handleSelectCategory(category)} style={styles.genreItem}>
                <div style={styles.row}>
                  <img src={Dot} alt="Dot" style={{ width: imageSize, height: imageSize, marginRight: '1vw' }} />
                  <span style={styles.genreText}>{category.name}</span>
                </div>
              </button>
              <div style={styles.borderContainer}>
                <div style={styles.centeredBorder}></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: `calc(100vh - ${bottomAreaHeight})`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  genreItem: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1vh',
    margin: '1vh 0',
    cursor: 'pointer',
    zIndex: 10,
    textAlign: 'left',
    width: '100%',
  },
  genreText: {
    color: 'white',
    fontSize: '2.4vh',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  scrollContainer: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
    height: `calc(100vh - ${bottomAreaHeight})`,
    zIndex: 2,
    overflowY: 'auto',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0',
  },
  centeredBorder: {
    marginLeft: '18%',
    width: '110%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vh 0',
    width: '100%',
    backgroundColor: 'transparent',
    zIndex: 10,
  },
  headerText: {
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'brhendrix',
  },
  listItem: {
    marginBottom: '1vh',
  },
};

export default ArtistsScreenMyContent;
