import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';
import { ProfilesDisplay } from './ProfilesDisplay';
import { ProfilesDisplayFollowers } from './ProfilesDisplayFollowers';

const TheFollowersPage = ({ onProfilePress }) => {
  const {
    numberOfFollowers,
    numberOfFollowing,
  } = useContext(AppContext);

  const [activeTab, setActiveTab] = useState('following');

  return (
    <div style={styles.fullScreen}>
      <div style={styles.tabContainer}>
        <button
          style={{ ...styles.tab, ...(activeTab === 'following' && styles.activeTab) }}
          onClick={() => setActiveTab('following')}
        >
          Following ({numberOfFollowing})
        </button>
        <button
          style={{ ...styles.tab, ...(activeTab === 'followers' && styles.activeTab) }}
          onClick={() => setActiveTab('followers')}
        >
          Followers ({numberOfFollowers})
        </button>
      </div>
      {activeTab === 'following' ? (
        <div style={styles.profileContainersFollowers}>
          <ProfilesDisplay onProfilePress={onProfilePress} />
        </div>
      ) : (
        <div style={styles.profileContainersFollowers}>
          <ProfilesDisplayFollowers onProfilePress={onProfilePress} />
        </div>
      )}
      <div style={{ height: '18vh', backgroundColor: 'transparent' }} />
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100vh',
    overflow: 'hidden',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    
  },
  profileContainersFollowers: {
    left: '1%',
    top: '4%',
    zIndex: 100,
    position: 'absolute',
    width: '98%',
    height: 'calc(100vh - 26%)', // Adjust height to fit within the remaining viewport

  },
  
  
  tabContainer: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 16,
    top: '20%',
  },
  tab: {
    zIndex: 100,
    flex: 1,
    textAlign: 'center',
    padding: '10px 0',
    borderBottom: '2px solid transparent',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontSize: '16px',
    textDecoration: 'none',
    color: '#62fbfa',
  },
  activeTab: {
    zIndex: 100,
    borderBottomColor: '#62fbfa',

  },
};

export default TheFollowersPage;
