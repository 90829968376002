import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  getFirestore, collection, query, orderBy, limit, startAfter, getDocs, doc, getDoc, where
} from 'firebase/firestore';
import SongItemMusicVideo from './SongItemMusicVideo';
import { useNavigate } from 'react-router-dom';

const Video = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {

  const [activeVideoTab, setActiveVideoTab] = useState('musicVideos');

  const [moviesNowPlaying, setMoviesNowPlaying] = useState([]);
  const [moviesComingSoon, setMoviesComingSoon] = useState([]);

  const [movieTrailers, setMovieTrailers] = useState([]);
  
  useEffect(() => {
    const fetchMoviesAndTrailers = async () => {
      try {
        const firestore = getFirestore();
  
        // Fetch Now Playing and Coming Soon movies
        const nowPlayingSnapshot = await getDocs(collection(firestore, 'NowPlayingMovies'));
        const comingSoonSnapshot = await getDocs(collection(firestore, 'ComingSoonMovies'));
  
        // Map data to include IDs and fetch movie data
        const nowPlayingMovies = nowPlayingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const comingSoonMovies = comingSoonSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        // Aggregate all trailers from coming soon movies
        const movieTrailers = comingSoonMovies
          .map(movie => movie.trailers || []) // Get the trailers array or an empty array if not present
          .flat() // Flatten the array of arrays into a single array
          .map((trailer, index) => ({
            ...trailer,
            parentMovieId: comingSoonMovies[index]?.id || '',
            parentMovieTitle: comingSoonMovies[index]?.MovieTitle || '',
          })); // Optionally, include the parent movie ID or title if needed
  
        // Update states
        setMoviesNowPlaying(nowPlayingMovies);
        setMoviesComingSoon(comingSoonMovies);
        setMovieTrailers(movieTrailers);
  
      } catch (error) {
        console.error("Error fetching movies and trailers:", error);
      }
    };
  
    fetchMoviesAndTrailers();
  }, []);

  const [groupedByGenres, setGroupedByGenres] = useState({});
  const [lastGenreDocs, setLastGenreDocs] = useState({});
  const [loading, setLoading] = useState(true);
  const [lastFeaturedVideoDoc, setLastFeaturedVideoDoc] = useState(null);
  const [dataFeaturedVideos, setDataFeaturedVideos] = useState([]);
  const [newReleases, setNewReleases] = useState([]);
  const [lastNewReleaseDoc, setLastNewReleaseDoc] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isFetchingMoreGenre, setIsFetchingMoreGenre] = useState({});
  const firestore = getFirestore();

  const navigate = useNavigate();

  const featuredVideosScrollRef = useRef(null);
  const newReleasesScrollRef = useRef(null);
  const genreScrollRefs = useMemo(() => ({}), []);

  const removeDuplicates = (array) => {
    const seen = new Set();
    return array.filter(item => {
      const isDuplicate = seen.has(item.id);
      seen.add(item.id);
      return !isDuplicate;
    });
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const handleNavigateSeeAll = async (category) => {
    navigate('/videoseeall', { state: { category: category } } );
  };

  const handleNavigateMovie = async (movie) => {
    navigate('/movieview', { state: { movie: movie } } );
  };

  const renderItem = useCallback(({ item }) => {
    if (item === 'loading') {
      return (
        <div style={styles.itemContainer}>
          <div style={styles.horizontalTouchable}>
            <div style={styles.songArtworkPlaceholder}>Loading...</div>
          </div>
        </div>
      );
    }
    return <SongItemMusicVideo item={item} onPress={handlePressBegins} />;
  }, [handlePressBegins]);

  const fetchMoreFeaturedVideos = async (lastDoc) => {
    if (!lastDoc || isFetchingMore) return;
    setIsFetchingMore(true);
    const nextQuery = query(collection(firestore, 'FeaturedVideos'), orderBy('releaseTimestamp', 'desc'), startAfter(lastDoc), limit(8));
    const snapshot = await getDocs(nextQuery);
    setDataFeaturedVideos(prev => removeDuplicates([...prev, ...snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))]));
    setLastFeaturedVideoDoc(snapshot.docs[snapshot.docs.length - 1]);
    setIsFetchingMore(false);
  };

  const fetchMoreVideos = async () => {
    if (!lastNewReleaseDoc || isFetchingMore) return;
    setIsFetchingMore(true);
    const nextQuery = query(collection(firestore, 'Videos'), orderBy('releaseTimestamp', 'desc'), startAfter(lastNewReleaseDoc), limit(8));
    const snapshot = await getDocs(nextQuery);
    const newDocs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const uniqueReleases = removeDuplicates(newDocs);
    setNewReleases(prev => removeDuplicates([...prev, ...uniqueReleases]));
    setLastNewReleaseDoc(snapshot.docs[snapshot.docs.length - 1]);
    setIsFetchingMore(false);
  };
  

  const fetchInitialGenreVideos = async () => {
    try {
      const fetchSize = 8;
      let genreData = {};
      let genreLastDocs = {};

      const fetchGenreData = async (genreObj) => {
        const genre = genreObj.value;
        const videosQuery = query(
          collection(firestore, 'Videos'),
          where('genres', 'array-contains', genre),
          orderBy('releaseTimestamp', 'desc'),
          limit(fetchSize / 2)
        );

        const videosSnapshot = await getDocs(videosQuery);
        const combinedData = videosSnapshot.docs.sort((a, b) => {
          return b.data().releaseTimestamp - a.data().releaseTimestamp;
        });

        genreData[genre] = combinedData.map(doc => ({ id: doc.id, ...doc.data() }));
        genreLastDocs[genre] = combinedData[combinedData.length - 1];

        // Update state as soon as data for each genre is fetched
        setGroupedByGenres(prev => ({ ...prev, [genre]: genreData[genre] }));
        setLastGenreDocs(prev => ({ ...prev, [genre]: genreLastDocs[genre] }));
      };

      await Promise.all(genresList.map(fetchGenreData));

      
    } catch (error) {
      console.error("Error fetching genre videos:", error);
    }
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const fetchSize = 8;
        const initialQuery = query(collection(firestore, 'Videos'), orderBy('releaseTimestamp', 'desc'), limit(fetchSize));
        const videosSnapshot = await getDocs(initialQuery);
        setNewReleases(videosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLastNewReleaseDoc(videosSnapshot.docs[videosSnapshot.docs.length - 1]);

        const featuredVideoQuery = query(collection(firestore, 'FeaturedVideos'), orderBy('releaseTimestamp', 'desc'), limit(fetchSize));
        const featuredVideoSnapshot = await getDocs(featuredVideoQuery);
        setDataFeaturedVideos(featuredVideoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setLastFeaturedVideoDoc(featuredVideoSnapshot.docs[featuredVideoSnapshot.docs.length - 1]);

        await fetchInitialGenreVideos();
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const fetchMoreGenreVideos = async (genre) => {
    if (groupedByGenres[genre].length < 3) return;

    if (isFetchingMoreGenre[genre]) return;

    const lastDocs = lastGenreDocs[genre];
    if (!lastDocs) {
      
      return;
    }

    
    setIsFetchingMoreGenre(prev => ({ ...prev, [genre]: true }));

    try {
      const videosQuery = query(
        collection(firestore, 'Videos'),
        where('genres', 'array-contains', genre),
        orderBy('releaseTimestamp', 'desc'),
        startAfter(lastDocs),
        limit(8)
      );

      const videosSnapshot = await getDocs(videosQuery);
      const newDocs = videosSnapshot.docs.sort((a, b) => {
        return b.data().releaseTimestamp - a.data().releaseTimestamp;
      });

      if (newDocs.length > 0) {
        

        setGroupedByGenres(prev => {
          const updatedGenre = [...prev[genre], ...newDocs.map(doc => ({ id: doc.id, ...doc.data() }))];
          const uniqueUpdatedGenre = removeDuplicates(updatedGenre); // Ensure unique documents
          return {
            ...prev,
            [genre]: uniqueUpdatedGenre
          };
        });

        setLastGenreDocs(prev => ({
          ...prev,
          [genre]: newDocs[newDocs.length - 1]
        }));

        
      } else {
        
      }

    } catch (error) {
      console.error(`Error fetching more videos for genre: ${genre}`, error);
    } finally {
      setIsFetchingMoreGenre(prev => ({ ...prev, [genre]: false }));
    }
  };

  const handleScroll = (ref, fetchMoreFunc, lastItem) => {
    if (!ref.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = ref.current;
    

    if (scrollLeft + clientWidth >= scrollWidth - 5) {
      
      setTimeout(() => fetchMoreFunc(lastItem), 100); // Pass the last item rendered
    }
  };

  // Add scroll event listeners for featured videos and new releases
  useEffect(() => {
    const handleFeaturedVideosScroll = () => handleScroll(featuredVideosScrollRef, fetchMoreFeaturedVideos, dataFeaturedVideos[dataFeaturedVideos.length - 1]);
    const handleNewReleasesScroll = () => handleScroll(newReleasesScrollRef, fetchMoreVideos, newReleases[newReleases.length - 1]);

    if (featuredVideosScrollRef.current) {
      featuredVideosScrollRef.current.addEventListener('scroll', handleFeaturedVideosScroll);
      
    }
    if (newReleasesScrollRef.current) {
      newReleasesScrollRef.current.addEventListener('scroll', handleNewReleasesScroll);
      
    }

    return () => {
      if (featuredVideosScrollRef.current) {
        featuredVideosScrollRef.current.removeEventListener('scroll', handleFeaturedVideosScroll);
        
      }
      if (newReleasesScrollRef.current) {
        newReleasesScrollRef.current.removeEventListener('scroll', handleNewReleasesScroll);
        
      }
    };
  }, [dataFeaturedVideos, newReleases]);

  // Add and remove scroll event listeners for genre sections
  useEffect(() => {
    Object.keys(genreScrollRefs).forEach(genre => {
      const ref = genreScrollRefs[genre];
      if (ref.current) {
        const handleGenreScroll = () => handleScroll(ref, () => fetchMoreGenreVideos(genre, lastGenreDocs[genre]));
        ref.current.addEventListener('scroll', handleGenreScroll);
        

        return () => {
          if (ref.current) {
            ref.current.removeEventListener('scroll', handleGenreScroll);
            
          }
        };
      }
    });
  }, [genreScrollRefs, groupedByGenres, lastGenreDocs]);

  const genresList = [
    { label: "Rap", value: "hip_hop_rap" },
    { label: "Rock", value: "rock" },
    { label: "Country", value: "country" },
    { label: "Pop", value: "pop" },
    { label: "Alternative", value: "Alternative" },
    { label: "Blues", value: "blues" },
    { label: "R&B/Soul", value: "rnb_soul" },
    { label: "Metal", value: "metal" },
    { label: "Indie", value: "indie" },
    { label: "Folk", value: "folk" },
    { label: "Classical", value: "classical" },
    { label: "Electronic/Dance", value: "electronic_dance" },
    { label: "Jazz", value: "jazz" },
    { label: "Latin", value: "latin" },
    { label: "Reggae", value: "reggae" },
    { label: "World/International", value: "world_international" }
  ];

  const filteredGenres = useMemo(() => Object.keys(groupedByGenres).filter(genre => groupedByGenres[genre].length > 0), [groupedByGenres]);

  return (
    <div style={styles.fullScreen}>
      <div style={styles.tabContainer}>
        <button
          style={activeVideoTab === 'musicVideos' ? styles.activeTab : styles.tab}
          onClick={() => setActiveVideoTab('musicVideos')}
        >
          Music Videos
        </button>
        <button
          style={activeVideoTab === 'movieTheater' ? styles.activeTab : styles.tab}
          onClick={() => setActiveVideoTab('movieTheater')}
        >
          Movies
        </button>
      </div>
  
      <div style={styles.scrollContainer}>
        {activeVideoTab === 'musicVideos' ? (
          <>
            {['Featured Videos', 'New Music Videos'].map((text, index) => (
              <div key={index} style={styles.horizontalSection}>
                <div style={styles.headerRow}>
                  <p style={styles.horizontalText}>{text}</p>
                  <button
                    style={styles.seeAllButtonContainer}
                    onClick={() => handleNavigateSeeAll(text)}
                  >
                    <span style={styles.seeAllButtonText}>See All</span>
                  </button>
                </div>
                <div
                  style={styles.horizontalScroll}
                  ref={index === 0 ? featuredVideosScrollRef : newReleasesScrollRef}
                >
                  {(index === 0 ? dataFeaturedVideos : newReleases).map((item, i, arr) => (
                    <div key={item.id}>
                      {renderItem({ item })}
                    </div>
                  ))}
                </div>
              </div>
            ))}
  
            {loading ? (
              <div style={{ marginTop: 20 }}>Loading...</div>
            ) : (
              filteredGenres.map((genre, index) => (
                <div key={index} style={styles.horizontalSection}>
                  <div style={styles.headerRow}>
                    <p style={styles.horizontalText}>{genresList.find((g) => g.value === genre).label}</p>
                    <button
                      style={styles.seeAllButtonContainer}
                      onClick={() =>
                        handleNavigateSeeAll(genresList.find((g) => g.value === genre).label)
                      }
                    >
                      <span style={styles.seeAllButtonText}>See All</span>
                    </button>
                  </div>
                  <div
                    style={styles.horizontalScroll}
                    ref={(el) => (genreScrollRefs[genre] = { current: el })}
                  >
                    {groupedByGenres[genre].map((item, i, arr) => (
                      <div key={item.id}>{renderItem({ item })}</div>
                    ))}
                  </div>
                </div>
              ))
            )}
            <div style={styles.extraSpace}></div>
          </>
        ) : (
          <>
            {moviesNowPlaying.length > 0 || moviesComingSoon.length > 0 ? (
              <>
{moviesNowPlaying.length > 0 && (
  <>
    <p style={styles.sectionTitle}>Now Playing</p>
    {moviesNowPlaying.map((item) => (
      <div
        key={item.id}
        style={styles.movieItemContainer}
        onClick={() => handleNavigateMovie(item)}  // Handle the click event here
      >
        <img src={item.VideoThumbnailURL} style={styles.movieThumbnail} />
        <p style={styles.movieTitle}>{item.MovieTitle}</p>
      </div>
    ))}
  </>
)}




                {moviesComingSoon.length > 0 && (
                  <>
                    <p style={styles.sectionTitle}>Coming Soon</p>
                    {moviesComingSoon.map((item) => {
                      const premiereDate = new Date(item.premiereDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      });
                      return (
                        <div key={item.id} style={styles.movieItemContainer} onClick={() => handleNavigateMovie(item)} >
                          <img src={item.VideoThumbnailURL} style={styles.movieThumbnail} />
                          <p style={styles.movieTitle}>{item.MovieTitle}</p>
                          <p style={styles.movieReleaseDate}>Premiering {premiereDate}</p>
                        </div>
                      );
                    })}
                  </>
                  
                )}
                {movieTrailers.length > 0 && (
  <>
    <p style={styles.sectionTitle}>Trailers</p>
    {movieTrailers.map((item) => (
      <div
        key={item.id}
        style={styles.movieItemContainer}
        onClick={() => handleNavigateMovie(item)}  // Handle the click event here
      >
        <img src={item.VideoThumbnailURL} style={styles.movieThumbnail} />
        <p style={styles.movieTitle}>{item.title}</p>
      </div>
    ))}
  </>
)}
            <div style={styles.extraSpace}></div>
              </>
            ) : (
              <div style={styles.movieTheaterContainer}>
                <p style={styles.movieTheaterText}>Movie Premieres Coming Soon</p>
              </div>
            )}
            
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  movieTheaterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: '30px',
  },
  movieTheaterText: {
    color: 'white',
    fontSize: 18,
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  movieItemContainer: {
    marginBottom: 20,
    alignItems: 'center',
    zIndex: 100,
    cursor: 'pointer',

  },
  movieThumbnail: {
    width: '90%',
    alignSelf: 'center',
    height: undefined,
    aspectRatio: 16 / 9,
    borderRadius: 8,
    marginBottom: 10,
    marginLeft: '10px',
    
  },
  movieTitle: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',

    textAlign: 'center',
    marginBottom: 5,
  },
  movieReleaseDate: {
    color: '#b8b8b8',
    fontSize: 14,
    textAlign: 'center',
  },
  sectionTitle: {
    fontSize: 18,
    color: '#62fbfa',
    fontWeight: 'bold',
    marginVertical: 10,
  },
  tabContainer: {
    zIndex: 100,
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: '30px',
  },
  tab: {
    zIndex: 100,
    fontSize: 18,
    flex: 1,
    color: '#62fbfa',
    alignItems: 'center',
    borderBottomColor: 'transparent',
    paddingVertical: 10,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  activeTab: {
    fontSize: 18,
    zIndex: 100,
    color: '#62fbfa',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderBottomColor: '#62fbfa', // Blue color for active tab
  },
  tabText: {
    fontSize: 16,
    zIndex: 100,
    color: '#62fbfa',
  },
  seeAllButtonContainer: {
    marginRight: '20px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    top: '22px',
    cursor: 'pointer',
  },
  headerRow: {
    justifyContent: 'space-between', 
    display: 'flex',
  },
  seeAllButtonText: {
    fontSize: '24px',
    color: '#62fbfa',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  extraSpace: {
    height: '300px',
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    width: '105%',
  },
  horizontalSection: {
    marginBottom: '20px',
  },
  horizontalText: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'white',
  },
  horizontalScroll: {
    display: 'flex',
    overflowX: 'auto',
    gap: '10px',
    whiteSpace: 'nowrap', // Prevent wrapping
  },
  itemContainer: {
    display: 'inline-block', // Change to inline-block to align items horizontally
    marginRight: '10px',
    width: '100px',
  },
  horizontalTouchable: {
    width: '100px',
    height: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtworkPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: '#cccccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default Video;
