import React, { useState, useRef, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { AppContext } from './AppContext';
import { getFirestore, doc, query, where, limit, getDocs, getDoc, setDoc, updateDoc, onSnapshot, serverTimestamp, addDoc, collection } from 'firebase/firestore';
import { firestore } from './firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

const PostScreen = ({ onNavigate, onPost }) => {
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);
  const [tempImageUri, setTempImageUri] = useState(null);
  const { showMediaPopup, currentUser, posts, setPosts, setJustPosted } = useContext(AppContext);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [mediaUri, setMediaUri] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [searchResultsComments, setSearchResultsComments] = useState([]);
  const [isSearchingComments, setIsSearchingComments] = useState(false);
  const [tags, setTags] = useState([]);
  const commentInputRef = useRef(null);
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  useEffect(() => {
    const searchUsers = async (queryText) => {
      const lastAtPos = queryText.lastIndexOf("@");
      if (lastAtPos === -1) {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
        return;
      }
  
      const searchQuery = queryText.substring(lastAtPos + 1);
      if (searchQuery.length > 0) {
        setIsSearchingComments(true);
        const searchValue = searchQuery.toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef, 
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );
  
        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSearchResultsComments(users);
        } catch (error) {
          console.error("Error searching users:", error);
          setSearchResultsComments([]);
        }
      } else {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
      }
    };
  
    if (text.includes("@")) {
      const delayDebounce = setTimeout(() => {
        searchUsers(text);
      }, 300);
  
      return () => clearTimeout(delayDebounce);
    }
  }, [text]);

  const handleSelectUser = (user) => {
    const cursorPosition = selection.start;
    const lastAtPos = text.lastIndexOf("@", cursorPosition);

    const beforeAt = text.substring(0, lastAtPos);
    const afterAt = text.substring(cursorPosition);

    const newComment = `${beforeAt}@${user.username} ${afterAt}`;
    setText(newComment);

    const newTag = {
      username: user.username,
      userId: user.id,
      start: beforeAt.length + 1,
      end: beforeAt.length + 1 + user.username.length
    };

    const updatedTags = [...tags.filter(tag => tag.end <= lastAtPos), newTag].sort((a, b) => a.start - b.start);
    setTags(updatedTags);

    setIsSearchingComments(false);
    setSearchResultsComments([]);

    const newCursorPos = beforeAt.length + user.username.length + 2;
    setSelection({ start: newCursorPos, end: newCursorPos });
  };

  const handleTextChange = (text) => {
    setText(text);

    if (text.lastIndexOf("@") > (tags.length > 0 ? Math.max(...tags.map(tag => tag.end)) : -1)) {
      setIsSearchingComments(true);
    } else {
      setIsSearchingComments(false);
    }

    const adjustedTags = tags.map(tag => {
      if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
        return tag;
      } else {
        const startPos = text.indexOf(`@${tag.username}`);
        if (startPos !== -1) {
          return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
        }
      }
      return null;
    }).filter(tag => tag !== null);

    setTags(adjustedTags);
  };

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const handlePost = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert('Please log in to post');
      return;
    }

    if (!text && !mediaUri) {
      alert('Your post must contain text or media');
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    try {
      let downloadURL = null;
      if (mediaUri) {
        downloadURL = await uploadMedia(mediaUri, (progress) => {
          setUploadProgress(progress);
          
        });
      }

      const postData = {
        text,
        mediaType,
        accountType: currentUser.accountType,
        imageUri: downloadURL || '',
        tags,
      };

      const newPostData = {
        ...postData,
        userId: user.uid,
        username: currentUser.username,
        name: currentUser.name,
        profileImageUrl: currentUser.profileImageUrl,
        accountType: currentUser.accountType,
        createdAt: new Date(),
        likeCount: 0,
        repostCount: 0,
        likes: {},
        comments: [],
        repostUserIds: [],
      };

      setPosts(prevPosts => [...prevPosts, newPostData]);

      setText('');
      setMediaUri(null);
      setMediaType(null);
      setTempImageUri(null);
      setUploading(false);

      const functions = getFunctions();
      const addPostTest = httpsCallable(functions, 'addPostTest');
      addPostTest(postData).then(response => {
        
      }).catch(error => {
        console.error('Error creating post:', error);
        alert('Error creating post. Please try again later.');
      });
    } catch (error) {
      console.error('Error during the post creation process:', error);
      alert('Error creating post. Please try again later.');
      setUploading(false);
    }
  };

const uploadMedia = async (uri, onProgress) => {
  if (!uri) return null;
  const storage = getStorage();
  const userId = getCurrentUserId();
  const storageRef = ref(storage, `posts/${userId}/${Date.now()}`);
  
  const response = await fetch(uri);
  const blob = await response.blob();

  const metadata = {
    contentType: blob.type || 'image/png', // Use the correct MIME type
  };

  const uploadTask = uploadBytesResumable(storageRef, blob, metadata);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
        
        switch (snapshot.state) {
          case 'paused':
            
            break;
          case 'running':
            
            break;
        }
      },
      (error) => {
        console.error('Upload failed:', error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          
          resolve(downloadURL);
        }).catch((error) => {
          console.error('Error getting download URL:', error);
          reject(error);
        });
      }
    );
  });
};


  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleMediaSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType.startsWith('image/') || fileType.startsWith('video/')) {
        setMediaUri(URL.createObjectURL(file));
        setMediaType(fileType.startsWith('video') ? 'video' : 'image');
      } else {
        alert('Please select an image or video file.');
      }
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.commentSectionContainer}>
        {isSearchingComments && (
          <div style={styles.userSuggestionContainer}>
            {searchResultsComments.map(user => (
              <div key={user.id} onClick={() => handleSelectUser(user)} style={styles.userSuggestion}>
                {user.username}
              </div>
            ))}
          </div>
        )}
        <div style={styles.addCommentContainer}>
          <textarea
            ref={commentInputRef}
            placeholder="What's happening?"
            value={text}
            onChange={(e) => handleTextChange(e.target.value)}
            placeholderTextColor="white"
            onSelect={(e) => setSelection({ start: e.target.selectionStart, end: e.target.selectionEnd })}
            style={styles.textInput}
          />
        </div>
      </div>
  
      <div style={styles.fileInputContainer}>
      <input
        type="file"
        style={styles.fileInput}
        onChange={handleMediaSelection}
        id="fileInput"
        accept="image/*,video/*"
      />
        <label htmlFor="fileInput" style={styles.customFileInputButton}>Select Media</label>
      </div>
  
      {mediaUri && (
        <div onClick={toggleModal} style={styles.previewContainer}>
          {mediaType === 'video' ? (
            <video src={mediaUri} style={styles.mediaPreview} controls />
          ) : (
            <img src={mediaUri} style={styles.mediaPreview} alt="Preview" />
          )}
        </div>
      )}
  
      <button style={styles.button} onClick={handlePost} disabled={uploading}>POST DAT</button>
  
      {modalVisible && (
        <div style={styles.modal}>
          <div style={styles.fullScreenContainer} onClick={toggleModal}>
            {mediaType === 'video' ? (
              <video src={mediaUri} style={styles.fullScreenMedia} controls autoPlay loop />
            ) : (
              <img src={mediaUri} style={styles.fullScreenMedia} alt="Full Screen Preview" />
            )}
          </div>
        </div>
      )}
  
      {uploading && (
        <div style={styles.progressBarContainer}>
          <div style={{ ...styles.progressBar, width: `${uploadProgress}%` }} />
        </div>
      )}
    </div>
  );
  
  
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '7vh',
  },
  textInput: {
    backgroundColor: 'rgba(81, 33, 131, 0.5)',
    padding: '1.5vh 2vw',
    width: '80%',
    fontSize: '1.6rem',
    color: 'white',
    borderRadius: '20px',
    resize: 'none',
    marginBottom: '10px',
    textAlign: 'left', // Ensure text is aligned left
    alignSelf: 'center', // Center the input container
  },
  mediaPreview: {
    width: '50%',
    height: 'auto',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '20px',
    marginBottom: '10px',
    color: '#62fbfa',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    fontSize: '18px',
    alignSelf: 'center', // Center the button
  },
  fileInputContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '10px 0',
    alignItems: 'center',
    alignSelf: 'center',
  },
  fileInput: {
    display: 'none',
  },
  customFileInputButton: {
    padding: '5px 10px',
    borderRadius: '5px',
    color: '#62fbfa',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    fontSize: '18px',
    alignSelf: 'center',
  },
  commentSectionContainer: {
    width: '80%',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  addCommentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  userSuggestionContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '5px 10px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  userSuggestion: {
    color: '#FFFFFF',
    padding: '10px',
    fontSize: '16px',
    borderWidth: '1px',
    borderColor: '#CCCCCC',
    borderRadius: '5px',
    marginVertical: '2px',
    cursor: 'pointer',
  },
  previewContainer: {
    cursor: 'pointer',
    marginBottom: '10px',
    textAlign: 'center',
  },
  progressBarContainer: {
    width: '80%',
    backgroundColor: '#ccc',
    marginTop: '10px',
  },
  progressBar: {
    height: '10px',
    backgroundColor: '#6200EE',
  },
  fullScreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  fullScreenMedia: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

export default PostScreen;


