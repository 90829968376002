import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';

const ProfileComponentShareMessages = ({ onPress, userProfile }) => {
  const {} = useContext(AppContext);
  const [contentWidth, setContentWidth] = useState(0); // State to store dynamic content width
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Measure the width of the text and icon container
  const onTextLayout = (event) => {
    const { width: textWidth } = event.nativeEvent.layout;
    const totalWidth = imageSize + textWidth + padding; // Calculate total width
    setContentWidth(totalWidth); // Update state with calculated width
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      setIsLoading(true);
      try {
        const docSnap = await getDoc(userProfile?.artistUserRef);
        if (docSnap.exists()) {
          setProfileImageUrl(docSnap.data().profileImageUrl);
        } else {
          
        }
      } catch (error) {
        console.error("Error getting document:", error);
      }
      setIsLoading(false);
    };

    if (userProfile?.artistUserRef) {
      fetchProfileImage();
    }
  }, [userProfile?.artistUserRef]);

  const profileImageSource = profileImageUrl || './assets/defaultProfilePic.png';

  const formatUsername = username => {
    if (!username) return '@'; // Default to '@' if username is undefined or null
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(userProfile?.username);

  if (isLoadingProfilePicture) {
    return (
      <div className="loader-container">
        <div className="activity-indicator" />
      </div>
    );
  }

  return (
    <div onClick={onPress} styles={styles.container} style={{ width: contentWidth || 'auto' }}>
      <div style={styles.profileViewStyle}>
        <div style={styles.imageStack}>
          <img src={profileImageSource} alt="Profile" style={styles.circularImage} />
        </div>
        <div className="text-container" onLayout={onTextLayout}>
          <div style={styles.artistName} className="artist-name">{userProfile?.ArtistName}</div>
          <div style={styles.textWithIconContainer}>
            <div style={styles.username} className="username"> {formatUsername(userProfile?.username)}</div>
            {userProfile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
                <img src={ArtistIcon1} alt="Artist Icon 1" style={styles.profilePicIcon} />
              )}
              {userProfile?.accountType !== 'Fan' && isArtistIcon2Visible && (
                <img src={ArtistIcon2} alt="Artist Icon 2" style={styles.profilePicIcon} />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const width = window.innerWidth;
const height = window.innerHeight;
const imageSize = '50px';
const circularImageSize = width * 0.164;
const padding = '10px'; // Total padding (left + right)

const styles = {
  container: {
    alignItems: 'center',
    flex: 1,  // Ensure it tries to fill the space
  },
  profileViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    transform: 'scale(0.8)',
    marginRight: '10px',
  },
  imageStack: {
    width: '50px', // Adjust the width of the image
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: '10px',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
  },
  circularImage: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    position: 'absolute',
    objectFit: 'cover',
    top: '49.7%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  artistName: {
    color: 'white',
    fontSize: '16px', // Adjust font size
    fontWeight: '400',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  textWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderContainer: {
    left: width * 0.118,
    bottom: height * 0.057,
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {},
  username: {
    color: '#62fbfa',
    fontSize: '14px', // Adjust font size
    opacity: 0.8,
    fontWeight: '400',
    fontFamily: 'brhendrix',
    marginRight: '2px',
  },
  profilePicIcon: {
    width: '20px', // Adjust icon size
    height: '20px', // Adjust icon size
    objectFit: 'contain',
  },
  fullScreenImageContainer: {
    width: '100%', // Cover the entire screen width
    height: '100%', // Cover the entire screen height
    position: 'absolute', // Ensure it covers the background fully
    top: 0, // Align to the top of the screen
    left: 0, // Align to the left of the screen
    zIndex: -1, // Ensure it stays behind all other content
  },
  fullScreenImage: {
    width: '100%', // Ensure the image stretches to cover the container width
    height: '100%', // Ensure the image stretches to cover the container height
    position: 'absolute', // Position absolutely within its container
    alignSelf: 'center', // Center horizontally within its container
    objectFit: 'contain', // Scale the image uniformly (maintain the image's aspect ratio)
  },
};

export { ProfileComponentShareMessages };
