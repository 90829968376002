
import React, { useState, useRef, useEffect, useContext } from 'react';
import { DynamicHeightImage } from './DynamicHeightImage';
import { RealTimeAgo } from './RealTimeAgo';
import ProfilesDisplaySharePost from './ProfilesDisplaySharePost';
import { AppContext } from './AppContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from "firebase/auth";
import { firestore } from './firebase';
import { getFirestore, runTransaction, deleteDoc, increment, addDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, updateDoc, startAfter, limit, arrayUnion } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import LikeIcon from './assets/LikeIcon.png'; // Import the image
import LikeIconActive from './assets/LikeIconActive.png'; // Import the active image
import CommentIcon from './assets/CommentIcon.png'; // Import other icons similarly
import RepostIcon from './assets/RepostIcon.png';
import RepostIconActive from './assets/RepostIconActive.png';
import ellipsis from './assets/ellipsis-v.png';
import Modal from 'react-modal';
import { ProfilesDisplayShareDATpost } from './ProfilesDisplayShareDATpost';
import { SharePostPopup } from './SharePostPopup';


const { width, height } = { width: window.innerWidth, height: window.innerHeight };

const bottomAreaHeight = height * 0.21;
const horizontalAreaSpacing = height * 0.015;
const scrollAreaTopPadding = height * 0.17;

const CACHE_DURATION = 1;


const ViewPostScreen = ({onMoveMP, onProfilePress}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { post, isLiked: initialLiked, isReposted: initialReposted, isOriginalFromRepost } = location.state;

  const { 
sharePostActive, showSharePostPopup, setShowSharePostPopup,
showMediaPopup, currentUser, setPostToShare, setSharePostActive, setCurrentlyLoadingProfile, setActivePopupMedia, activePopupMedia, setTrackToView, setIsExpandedShare, setIsLoadingHideEA, setInitiallySelectedItemId, setActiveTab, setIsExpanded, setIsLoadingArtwork, setIsLoadingProfilePicture, setIsLoadingExpand, setIsLoadingNewPopup, setArtworkUri, setInitialExpandedAlbumAndSong, setCenteredRelease, setHideExpandedAlbum, likes, userId2, setLikes, setPosts, reposts, setReposts

} = useContext(AppContext);


useEffect(() => {
  
}, [location.state]);


const [isSelecting, setIsSelecting] = useState(false);
const [isLoading, setIsLoading] = useState(false);

const [modalVisibleVideo, setModalVisibleVideo] = useState(false);
const [modalVisible, setModalVisible] = useState(false);
const [currentImage, setCurrentImage] = useState(null);

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

const [localRepostCount, setLocalRepostCount] = useState(post.repostCount);

  const [localLikeCount, setLocalLikeCount] = useState(post.likeCount || 0);

  const [localCommentCount, setLocalCommentCount] = useState(post.commentCount || 0);

  const [isLiked, setIsLiked] = useState(likes[post.id] || false)
  const [isReposted, setIsReposted] = useState(initialReposted);

const [activeCommentId, setActiveCommentId] = useState(null);
const [replyText, setReplyText] = useState("");

const [replyTexts, setReplyTexts] = useState({});

  const [activeItemId, setActiveItemId] = useState(null);

const createKey = (userId, index) => `${userId}_${index}`;


const [visibleReplies, setVisibleReplies] = useState({});

const [loadedCommentCount, setLoadedCommentCount] = useState(10); // Load 10 comments initially
const [hasMoreComments, setHasMoreComments] = useState(true);


const [comment, setComment] = useState("");
const [searchResultsComments, setSearchResultsComments] = useState([]);
const [isSearchingComments, setIsSearchingComments] = useState(false);

const [searchResultsReplies, setSearchResultsReplies] = useState([]);
const [isSearchingReplies, setIsSearchingReplies] = useState(false);
const [tags, setTags] = useState([]);
    const commentInputRef = useRef(null);
const [selection, setSelection] = useState({ start: 0, end: 0 });


const [replyTags, setReplyTags] = useState({});
    const replyInputRef = useRef(null);
const [selectionReplies, setSelectionReplies] = useState({ start: 0, end: 0 });
const textInputRef = useRef();


const [nestedReplyTexts, setNestedReplyTexts] = useState({});
const [nestedReplyTags, setNestedReplyTags] = useState({});

const videoRefs = useRef({});
  const videoRef = useRef(null);

const [currentVideoUri, setCurrentVideoUri] = useState(null);

  const handleFullscreenUpdate = (event) => {
    
    if (event.fullscreenUpdate === 3) {  // FULLSCREEN_UPDATE_PLAYER_DID_DISMISS
      
      setModalVisibleVideo(false);  // Close the modal after fullscreen is completely exited
    }
  };


useEffect(() => {
  const searchUsers = async (queryText) => {
    const lastAtPos = queryText.lastIndexOf("@");
    if (lastAtPos === -1) {
      setIsSearchingComments(false);
      setSearchResultsComments([]);
      return;
    }

    const searchQuery = queryText.substring(lastAtPos + 1);
    if (searchQuery.length > 0) {
      setIsSearchingComments(true);
      const searchValue = searchQuery.toLowerCase();
      const usersRef = collection(firestore, 'users');
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue),
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsComments(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsComments([]);
      }
    } else {
      setIsSearchingComments(false);
      setSearchResultsComments([]);
    }
  };

  if (comment.includes("@")) {
    const delayDebounce = setTimeout(() => {
      searchUsers(comment);
    }, 300);

    return () => clearTimeout(delayDebounce);
  }
}, [comment]);


useEffect(() => {
  const searchUsers = async (queryText, commentId) => {
    const lastAtPos = queryText.lastIndexOf("@");
    if (lastAtPos === -1) {
      setIsSearchingReplies(false);
      setSearchResultsReplies([]);
      return;
    }

    const searchQuery = queryText.substring(lastAtPos + 1);
    if (searchQuery.length > 0) {
      setIsSearchingReplies(true);
      const searchValue = searchQuery.toLowerCase();
      const usersRef = collection(firestore, 'users');
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue),
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3)
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsReplies(users);
      } catch (error) {
        console.error("Error searching users in replies:", error);
        setSearchResultsReplies([]);
      }
    } else {
      setIsSearchingReplies(false);
      setSearchResultsReplies([]);
    }
  };

  // Assuming `replyTexts` is a state tracking all replies' texts
  Object.entries(replyTexts).forEach(([commentId, text]) => {
    if (text.includes("@")) {
      const delayDebounce = setTimeout(() => {
        searchUsers(text, commentId);
      }, 300);

      return () => clearTimeout(delayDebounce);
    }
  });
}, [replyTexts]);




const handleSelectUser = (user) => {
    const cursorPosition = selection.start;
    const lastAtPos = comment.lastIndexOf("@", cursorPosition);

    const beforeAt = comment.substring(0, lastAtPos);
    const afterAt = comment.substring(cursorPosition);

    const newComment = `${beforeAt}@${user.username} ${afterAt}`;
    setComment(newComment);

    const newTag = {
        username: user.username,
        userId: user.id,
        start: beforeAt.length + 1,  // +1 for '@'
        end: beforeAt.length + 1 + user.username.length
    };

    const updatedTags = [...tags.filter(tag => tag.end <= lastAtPos), newTag].sort((a, b) => a.start - b.start);
    setTags(updatedTags);

    setIsSearchingComments(false);
    setSearchResultsComments([]);

    // Set cursor position right after the newly inserted tag
    const newCursorPos = beforeAt.length + user.username.length + 2; // +2 for '@' and space
    setSelection({ start: newCursorPos, end: newCursorPos });
};



const handleSelectUserForReply = (user, commentId) => {
    const cursorPosition = selectionReplies.start;  // Using reply-specific selection state
    const replyText = replyTexts[commentId] || '';
    
    // Find the position of the last "@" before the cursor to replace the entire mention query
    const lastAtPos = replyText.lastIndexOf("@", cursorPosition);
    const endOfMentionPos = replyText.indexOf(' ', lastAtPos);
    const beforeAt = replyText.substring(0, lastAtPos);
    const afterAt = endOfMentionPos > -1 ? replyText.substring(endOfMentionPos) : '';

    // Construct new reply text by inserting the full username tag at the last "@" position
    const newReplyText = `${beforeAt}@${user.username} ${afterAt}`;
    setReplyTexts(prev => ({ ...prev, [commentId]: newReplyText }));

    const newTag = {
        username: user.username,
        userId: user.id,
        start: beforeAt.length + 1,  // +1 for '@'
        end: beforeAt.length + 1 + user.username.length
    };

    // Update replyTags for the specific commentId
    const updatedReplyTags = [...(replyTags[commentId] || []).filter(tag => tag.end <= lastAtPos), newTag].sort((a, b) => a.start - b.start);
    setReplyTags(prev => ({ ...prev, [commentId]: updatedReplyTags }));

    setIsSearchingReplies(false);
    setSearchResultsReplies([]);

    // Set cursor position right after the newly inserted tag
    const newCursorPos = beforeAt.length + user.username.length + 2; // +2 for '@' and space
    setSelectionReplies({ start: newCursorPos, end: newCursorPos });
};





const handleTextChange = (event) => {
  const text = event.target.value;
  setComment(text);

  if (text.lastIndexOf("@") > (tags.length > 0 ? Math.max(...tags.map(tag => tag.end)) : -1)) {
    setIsSearchingComments(true);
  } else {
    setIsSearchingComments(false);
  }

  // Update tag positions based on text editing
  const adjustedTags = tags.map(tag => {
    if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
      return tag;  // Tag is unchanged
    } else {
      // Find the new position if the username still exists in text
      const startPos = text.indexOf(`@${tag.username}`);
      if (startPos !== -1) {
        return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
      }
    }
    return null;  // Tag no longer valid
  }).filter(tag => tag !== null);

  setTags(adjustedTags);

  
};








const updateTagsBasedOnText = (text) => {
  

  // Parse current text to find all tags
  const currentTags = parseTagsFromText(text);

  // Update the tags state
  
  setTags(currentTags);
};


const parseTagsFromText = (text) => {
  
  // Adjust regex to match tags followed directly by any characters
  const tagRegex = /@(\w+)\{(\w+)\}/g;
  let match;
  const foundTags = [];

  while ((match = tagRegex.exec(text)) !== null) {
    const username = match[1];
    const userId = match[2];
    foundTags.push({ username, userId });
    
  }

  return foundTags;
};





useEffect(() => {
  const fetchComments = async () => {
    const db = getFirestore();
    const commentsRef = collection(db, 'posts', post.postId, 'comments');
    

    try {
      const snapshot = await getDocs(commentsRef);
      let commentsData = await Promise.all(snapshot.docs.map(async (doc) => {
        const comment = {
          ...doc.data(),
          commentId: doc.id,
          replies: []
        };

        

        if (Array.isArray(comment.text)) {
          comment.text.forEach((part, index) => {
            
          });
        } else if (typeof comment.text === 'string') {
          
        }

        const repliesRef = collection(db, 'posts', post.id, 'comments', doc.id, 'replies');
        const replySnapshot = await getDocs(repliesRef);
        const replies = replySnapshot.docs.map(replyDoc => ({
          ...replyDoc.data(),
          replyId: replyDoc.id
        }));

        // Sort replies such that the newest are at the top
        replies.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

        comment.replies = replies;
        return comment;
      }));

      // Sort comments such that the newest are at the top
      commentsData.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      

      // Update the local state to reflect the total count of comments and replies
      const totalReplies = commentsData.reduce((acc, comment) => acc + comment.replies.length, 0);
      const totalCommentsAndReplies = commentsData.length + totalReplies;

      setComments(commentsData);
      setLocalCommentCount(totalCommentsAndReplies);  // Updated to include replies in the count
    } catch (error) {
      console.error('Error fetching comments and replies:', error);
    }
  };

  fetchComments();
}, [post.id]);








const toggleRepliesVisibility = (commentId) => {
  setVisibleReplies(prev => ({
    ...prev,
    [commentId]: !prev[commentId]  // Toggle the visibility
  }));
};




const handleLoadMoreComments = () => {
  if (hasMoreComments) {
    setLoadedCommentCount(prevCount => prevCount + 10);  // Adjust the count as necessary
  }
};




useEffect(() => {
  if (isSelecting && activePopupMedia) {
    onProfilePress(); // Call the function after activePopupMedia has been updated
setCurrentlyLoadingProfile(false);
    setIsSelecting(false);
  }
}, [activePopupMedia]);



const initiateReply = (comment, post) => {

    const replyText = `@${comment.username} `;
    setReplyTexts({ ...replyTexts, [comment.commentId]: replyText });


    const newReplyTag = { username: comment.username, userId: comment.userId };
    setReplyTags({ ...replyTags, [comment.commentId]: [newReplyTag] });

    // Optionally focus on the reply input field
    // replyInputRef.current?.focus();
};



const initiateReplyToReply = (reply, commentId) => {
  const repliesUpdate = comments.map(comment => {
    if (comment.commentId === commentId) {
      return {
        ...comment,
        replies: comment.replies.map(r => {
          if (r.replyId === reply.replyId) {
            return { ...r, isReplying: !r.isReplying };  // Toggle isReplying state
          }
          return r;
        })
      };
    }
    return comment;
  });
  setComments(repliesUpdate);

  // Set the initial reply text with the username tagged for nested reply
  const replyInitialText = `@${reply.username} `;
  setNestedReplyTexts({ ...nestedReplyTexts, [reply.replyId]: replyInitialText });

  // Set the tag for the nested reply
  const newTag = {
    username: reply.username,
    userId: reply.userId,  // Assuming each reply has a userId property
    start: 0,  // Tag starts at the beginning of the text
    end: reply.username.length + 1  // +1 accounts for the '@' symbol
  };
  setNestedReplyTags({ ...nestedReplyTags, [reply.replyId]: [newTag] });

  // Set focus on the input (adjust based on actual input handling)
  setTimeout(() => {
    textInputRef?.current?.focus();
  }, 100);
};





const onReplyToReplySubmit = (replyId, commentId) => {
  
  handleAddNestedReply(replyId, commentId);

};




const onNestedReplySubmit = (replyId, commentId) => {
    setLocalCommentCount(localCommentCount + 1);
    

    const nestedReplyText = nestedReplyTexts[replyId] || '';
    if (!nestedReplyText.trim()) return;  // Ensure there's text to submit

    const userRef = userId2 ? doc(firestore, 'users', userId2) : null;

    const newReply = {
        replyId: doc(collection(getFirestore(), 'posts', post.id, 'comments', commentId, 'replies')).id,
        userId: userId2,
        username: currentUser.username,
        name: currentUser.name,
        userRef: userRef,
        profileImageUrl: currentUser.profileImageUrl,
        text: nestedReplyText,
        taggedUsers: nestedReplyTags[replyId] || [],
        createdAt: {
            seconds: Math.floor(Date.now() / 1000),
            nanoseconds: 0,
        },
        isReplying: false  // Ensure new replies are not in reply mode
    };

    // Optimistically update UI for both the new reply and toggling the reply state
    setNestedReplyTexts(prevTexts => ({ ...prevTexts, [replyId]: '' }));  // Clear the text input
    setComments(currentComments => currentComments.map(comment => {
        if (comment.commentId === commentId) {
            // Add the new reply to the top of the list
            return {
                ...comment,
                replies: [newReply].concat(comment.replies.map(reply => {
                    if (reply.replyId === replyId) {
                        return { ...reply, isReplying: false };  // Ensure the toggling off of isReplying
                    }
                    return reply;
                }))
            };
        }
        return comment;
    }));
    setNestedReplyTags({ ...nestedReplyTags, [replyId]: [] }); // Clear any tags related to the reply

    // Process the addition of the reply asynchronously
    handleAddNestedReply(newReply, replyId, commentId);
};


const handleAddNestedReply = async (newReply, replyId, commentId) => {
    const db = getFirestore();
    const functions = getFunctions();
    const addReply = httpsCallable(functions, 'addReply');

    try {
        const addReplyResult = await addReply({
            postId: post.id,
            commentId,
            replyId: newReply.replyId,
            userId2,
            replyText: newReply.text,
            replyTags: nestedReplyTags[replyId],
            currentUser
        });

        if (!addReplyResult.data.success) {
            throw new Error("Cloud function execution failed");
        }
        
    } catch (error) {
        console.error('Error adding nested reply:', error);
        // Roll back if there's an error
        rollbackNestedReply(replyId, commentId, newReply);
    }
};

const rollbackNestedReply = (replyId, commentId, newReply) => {
    // Remove the optimistically added reply and restore the previous text
    setNestedReplyTexts(prevTexts => ({ ...prevTexts, [replyId]: newReply.text }));
    setComments(currentComments => currentComments.map(comment => {
        if (comment.commentId === commentId) {
            return {...comment, replies: comment.replies.filter(reply => reply.replyId !== newReply.replyId)};
        }
        return comment;
    }));
};













const handleAddReply = async (commentId) => {
  if (!post) return; // Ensure the post exists

  const db = getFirestore();
  const replyText = replyTexts[commentId] || '';
  if (!replyText.trim()) return; // Ensure there's text to submit

  // Prepare for creating a new reply reference, not actually creating it here
  const replyRef = doc(collection(db, 'posts', post.id, 'comments', commentId, 'replies'));
  const timestamp = {
      seconds: Math.floor(Date.now() / 1000),
      nanoseconds: 0,
  };

  const userRef = userId2 ? doc(firestore, 'users', userId2) : null;

  // Preparing the newReply object that will be sent to the cloud function
  const newReply = {
      replyId: replyRef.id, // Just using ID generation, not setting document here
      userId: userId2,
      username: currentUser.username,
      name: currentUser.name,
      profileImageUrl: currentUser.profileImageUrl,
      text: replyText,
      accountType: currentUser.accountType,
    userRef: userRef,
      taggedUsers: replyTags[commentId] || [],
      createdAt: timestamp,
  };

  // Setup the Firebase Functions call
  const functions = getFunctions();
  const addReply = httpsCallable(functions, 'addReply');

  // Optimistically update local state
  setLocalCommentCount(localCommentCount + 1);
  setReplyTexts({ ...replyTexts, [commentId]: '' });
  setComments(currentComments => currentComments.map(comment => {
      if (comment.commentId === commentId) {
          const updatedReplies = [newReply, ...(comment.replies || [])];
          return { ...comment, replies: updatedReplies, repliesCount: (comment.repliesCount || 0) + 1 };
      }
      return comment;
  }));
  setPosts(prevPosts => prevPosts.map(p => {
      if (p.id === post.id) {
          return { ...p, commentCount: p.commentCount + 1 };
      }
      return p;
  }));

  // Call Cloud Function
  try {
      const addReplyResult = await addReply({
          postId: post.postId,
          commentId,
          userId2,
      accountType: currentUser.accountType,
          replyText,
          replyTags: replyTags[commentId],
          currentUser
      });

      if (addReplyResult.data.success) {
          
      } else {
          throw new Error("Cloud function execution failed");
      }
  } catch (error) {
      console.error('Error adding reply:', error);
      // Rollback optimistic updates if cloud function fails
      rollbackLocalChangesOnError(commentId, newReply);

      // Check if the error message is about being blocked
      if (error.details?.message) {
          if (error.details.message.includes('blocked you from')) {
          } else {
              // Handle other potential errors
          }
      } else {
          // Generic error handling
      }
  }
};

function rollbackLocalChangesOnError(commentId, newReply) {
    // Rollback local comment count
    setLocalCommentCount(localCommentCount - 1);

    // Reset reply texts and update comments state
    setReplyTexts({ ...replyTexts, [commentId]: newReply.text });
    setComments(currentComments => currentComments.map(comment => {
        if (comment.commentId === commentId) {
            return { ...comment, replies: comment.replies.filter(reply => reply.replyId !== newReply.replyId) };
        }
        return comment;
    }));
    setPosts(prevPosts => prevPosts.map(p => {
        if (p.id === post.id) {
            return { ...p, commentCount: p.commentCount - 1 };
        }
        return p;
    }));
}







const handleAddComment = async () => {
  
  try {
      

      if (!post) {
          console.error("Post is undefined");
          return; // Ensure the post exists
      }

      

      let db;
      try {
          db = getFirestore();
          
      } catch (error) {
          console.error('Error obtaining Firestore DB:', error);
          return;
      }
      
      let commentRef;
      try {
          const postId = post.id;
          const commentsPath = `posts/${postId}/comments`;
          

          const commentsCollection = collection(db, commentsPath);
          

          commentRef = doc(commentsCollection);
          
      } catch (error) {
          console.error('Error creating comment reference:', error);
          return;
      }

      const timestamp = {
          seconds: Math.floor(Date.now() / 1000),
          nanoseconds: 0,
      };

      

      let userRef;
      try {
          userRef = userId2 ? doc(firestore, 'users', userId2) : null;
          
      } catch (error) {
          console.error('Error creating user reference:', error);
          return;
      }

      
      
      
      

      if (!currentUser) {
          console.error("currentUser is undefined");
          return;
      }

      if (!currentUser.username || !currentUser.name || !currentUser.profileImageUrl || !currentUser.accountType) {
          console.error("Some properties of currentUser are undefined:", currentUser);
          return;
      }

      if (!Array.isArray(tags)) {
          console.error("tags is not an array:", tags);
          return;
      }

      const commentData = {
          commentId: commentRef.id, // Directly use the unique ID generated for the comment
          userId: userId2,
          userRef: userRef,
          username: currentUser.username,
          name: currentUser.name,
          profileImageUrl: currentUser.profileImageUrl,
          text: comment,
          accountType: currentUser.accountType,
          taggedUsers: tags,
          createdAt: timestamp,
      };

      

      // Check if any of the commentData fields are undefined
      for (const key in commentData) {
          if (commentData[key] === undefined) {
              console.error(`Comment data field ${key} is undefined`);
          }
      }

      // Optimistically update local state
      setComments(prevComments => {
          
          return [commentData, ...prevComments];
      });

      setLocalCommentCount(localCommentCount + 1); 
      setNewComment('');
      setComment('');
      setPosts(prevPosts => {
          
          return prevPosts.map(p => {
              if (p.id === post.id) {
                  return { ...p, commentCount: p.commentCount + 1 }; // Use existing comment count and increment
              }
              return p;
          });
      });

      // Setup the Firebase Functions call
      let functions, addComment;
      try {
          functions = getFunctions();
          
          
          addComment = httpsCallable(functions, 'addComment');
          
      } catch (error) {
          console.error('Error setting up Firebase Functions call:', error);
          return;
      }

      console.log('Calling addComment function with:', {
          postId: post.id,
          userId2,
          comment,
          accountType: currentUser.accountType,
          tags,
          currentUser,
          commentId: commentRef.id // Include the generated commentId here
      });

      // Call Cloud Function
      try {
          const addCommentResult = await addComment({
              postId: post.id,
              userId2,
              comment,
              accountType: currentUser.accountType,
              tags,
              currentUser,
              commentId: commentRef.id // Include the generated commentId here
          });

          
      } catch (error) {
          console.error('Error adding comment:', error);
          // Roll back optimistic updates
          setComments(prevComments => {
              
              return prevComments.filter(c => c.commentId !== commentRef.id);
          });
          setLocalCommentCount(localCommentCount - 1);
          setPosts(prevPosts => {
              
              return prevPosts.map(p => {
                  if (p.id === post.id) {
                      return { ...p, commentCount: p.commentCount - 1 };
                  }
                  return p;
              });
          });
          if (error.details?.message) {
              // Check if the error message is about being blocked
              if (error.details.message.includes('blocked you from commenting')) {
              } else {
                  // Handle other potential errors
              }
          } else {
              // Generic error handling
          }
      }
  } catch (globalError) {
      console.error('Global error in handleAddComment:', globalError);
  }
};














const sortReleasesByTimestampSHARE = (releases) => {
  const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);

  return sortedReleases;
};



const handlePressBeginsSHARE = async (song) => {
  const fetchedUserData = await getCachedUserDocSHARE(song.artistUserId);
  const profileData = fetchedUserData;
  const updatedProfileData = await updateSongWithProfileData(profileData, song);
  
  if (updatedProfileData) {
    const clonedSong = JSON.parse(JSON.stringify(updatedProfileData));
    navigate('/view-song', { state: { song: clonedSong } });
  }
};

const updateSongWithProfileData = async (profileData, song) => {
  const { singles = [], albums = [], videos = [] } = profileData;
  const allReleases = [...singles, ...albums, ...videos];
  const matchedSong = allReleases.find(release => release.contentId === song.contentId);
  
  if (matchedSong) {
    song.ArtistName = matchedSong.ArtistName || song.ArtistName;
    song.SongName = matchedSong.SongName || song.SongName;
    song.albumName = matchedSong.albumName || song.albumName;
    song.producers = matchedSong.producers || song.producers;
    song.songwriters = matchedSong.songwriters || song.songwriters;
    song.recordLabels = matchedSong.recordLabels || song.recordLabels;
    song.VideoURL = matchedSong.VideoURL || song.VideoURL;
    song.ArtworkURL = matchedSong.ArtworkURL || song.ArtworkURL;
    return matchedSong;
  }
  return song;
};






const preparePopupDataSHARE = (song, userData) => {
  let profileImageUrl = null;
  const itemUserData = [];


    profileImageUrl = userData.profileImageUrl || null;


  let userAlbums = sortReleasesByTimestampSHARE(userData.albums || []);
  let userSingles = sortReleasesByTimestampSHARE(userData.singles || []);
  let userVideos = sortReleasesByTimestampSHARE(userData.videos || []);

  let selectedAlbum = userAlbums.find(album => album.contentId === song.contentId) || null;
  let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;
  let selectedVideo = userVideos.find(video => video.contentId === song.contentId) || null;


  const artworkUri = selectedVideo?.VideoThumbnailURL || (selectedAlbum || selectedSingle)?.ArtworkURL || song?.ArtworkURL;
  setArtworkUri(artworkUri);

  let dimensions = [];
  if (song.isVideo) {
    dimensions = song.dimensions || [];
  }

  if (selectedAlbum && selectedAlbum.songs.length > 0) {
    setInitialExpandedAlbumAndSong(selectedAlbum, selectedAlbum.songs[0]);
    setCenteredRelease(selectedAlbum);
  } else if (selectedSingle && selectedSingle.length > 0) {
    setCenteredRelease(selectedSingle);
  } else if (selectedVideo) {
    setCenteredRelease(selectedVideo);
  }

  const songName = song.isVideo ? song.VideoName : song.SongName;

  return {
    url: song.SongURL,
    dimensions: dimensions,
    isVideo: song.isVideo,
    artistName: song.ArtistName,
    popupProfileImage: userData?.profileImageUrl,
    username: song.username,
    songName: songName,
    artworkUrl: artworkUri,
    contentId: song.contentId,
    localArtworkURL: song.localArtworkURL,
    userId: song.artistUserId,
    isAlbum: song.isAlbum,
    albumName: selectedAlbum?.albumName || '',
    producers: song.producers,
    songwriters: song.songwriters,
    recordLabels: song.recordLabels,
    otherReleases: { albums: userAlbums, singles: userSingles, videos: userVideos },
    albumSongs: selectedAlbum?.songs || [],
    single: selectedSingle,
    video: selectedVideo,
    timestamp: new Date().getTime(),
    vocalDrop: userData.vocalDrop,
    bio: userData.bio,
    blurb: userData.blurb,
    location: userData.location,
    ScreenSKN: userData.ScreenSKN,
  };
};






const getCachedUserDocSHARE = async (userId) => {
  let userDataCached = [];
  let userDataCachedNew = [];
    
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      
      const freshUserData = userDocSnap.data();

      



      // Update userDataCached structure with fresh data and timestamp, keep old local URLs initially.
      userDataCachedNew = {
        ...freshUserData, // overwrite with fresh data from Firestore
        profileImageUrl: freshUserData.profileImageUrl,
      };



      // Persist any updates in the cache and database

    } else {
      console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
    }
  

  return userDataCachedNew;
};











useEffect(() => {
  
  const fetchPostData = async () => {
    
    const db = getFirestore();
    const postRef = doc(db, 'posts', post.id);
    const postSnap = await getDoc(postRef);

    if (postSnap.exists()) {
      const postData = postSnap.data();
      
      updatePostInView(postData);
    } else {
      
    }
  };

  if (isOriginalFromRepost) {
    fetchPostData();
  }
}, [isOriginalFromRepost]);  // Ensure the right dependencies are here

  const updatePostInView = (postData) => {
    setLocalRepostCount(postData.repostCount || 0);
    setLocalLikeCount(postData.likeCount || 0);
    
    // Maintain the user's interaction unless data clearly updates it
    setIsLiked(prev => postData.likes && postData.likes[currentUser.userId] ? true : prev);
    setIsReposted(!!postData.reposts && !!postData.reposts[currentUser.userId]);

    setPosts(prevPosts =>
      prevPosts.map(p => (p.id === postData.id ? { ...p, ...postData } : p))
    );
  };


  const handlePressOriginalPost = (originalPost) => {
    navigate('/viewpost', {
      state: {
        post: originalPost,
        profileImageUrl: originalPost.profileImageUrl,
        key: originalPost.id,
        isLiked: !!likes[originalPost.id],
        isReposted: !!reposts[originalPost.id],
        isOriginalFromRepost: true
      }
    });
  };


const handleRepost = async (post, newUserText, newUserImageUri) => {
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user || !post) {
    console.error("User or post is undefined");
    return;
  }

  if (!currentUser) {
    console.error("Current user data is not available");
    return;
  }

  const targetPostId = post.originalPost ? post.originalPost.id : post.id;
  const originalPostRef = doc(db, 'posts', targetPostId);
  const userRepostsRef = doc(db, `users/${user.uid}/reposts`, targetPostId);



  await runTransaction(db, async (transaction) => {
    const originalPostDoc = await transaction.get(originalPostRef);
    if (!originalPostDoc.exists()) {
      console.error("Original post does not exist!");
      return;
    }

    const userRepostDoc = await transaction.get(userRepostsRef);
    if (userRepostDoc.exists()) {
      // Remove the repost if it already exists
      const repostId = userRepostDoc.data().repostId;
      const existingRepostRef = doc(db, 'posts', repostId);
      transaction.delete(existingRepostRef);
      transaction.delete(userRepostsRef);

      // Update repostUserIds by removing current user's ID
      const repostUserIds = originalPostDoc.data().repostUserIds.filter(id => id !== user.uid);

      // Ensure repostCount is never NaN
      const updatedRepostCount = Math.max((originalPostDoc.data().repostCount || 0) - 1, 0);
      transaction.update(originalPostRef, { repostCount: updatedRepostCount, repostUserIds: repostUserIds });

      updateLocalStateForRepostDeletion(repostId, targetPostId, updatedRepostCount);
      setPosts(prevPosts => prevPosts.filter(p => p.id !== repostId));
    } else {
      const repostRef = doc(collection(db, 'posts'));
    const userRef = userId2 ? doc(firestore, 'users', userId2) : null;
const repostData = {
  postId: repostRef.id,
  text: newUserText || '',
  imageUri: newUserImageUri || '',
  userId: user.uid,
  accountType: currentUser.accountType,
  username: currentUser.username,
  name: currentUser.name,
  userRef,
  profileImageUrl: currentUser.profileImageUrl,
  createdAt: serverTimestamp(),
  originalPost: {
    id: targetPostId,
    postId: originalPostDoc.data().postId,
    userId: originalPostDoc.data().userId,
    username: originalPostDoc.data().username,
    name: originalPostDoc.data().name,
    mediaType: originalPostDoc.data().mediaType ? originalPostDoc.data().mediaType : '',
    profileImageUrl: originalPostDoc.data().profileImageUrl,
    text: originalPostDoc.data().text,
    imageUri: originalPostDoc.data().imageUri,
    createdAt: originalPostDoc.data().createdAt,
    ...(originalPostDoc.data().accountType ? { accountType: originalPostDoc.data().accountType } : ''),
    ...(originalPostDoc.data().userRef ? { userRef: originalPostDoc.data().userRef } : ''),
    ...(originalPostDoc.data().profile ? { profile: originalPostDoc.data().profile } : {}),
    ...(originalPostDoc.data().media ? { media: originalPostDoc.data().media } : {})
  },
  likeCount: 0,
  repostCount: 0,
  likes: {},
  comments: [],
  repostUserIds: []
};

      transaction.set(repostRef, repostData);
      transaction.set(userRepostsRef, { originalPostId: targetPostId, repostId: repostRef.id });

      // Update repostUserIds by adding current user's ID
      const repostUserIds = [...(originalPostDoc.data().repostUserIds || []), user.uid];

      // Ensure repostCount is never NaN
      const updatedRepostCount = (originalPostDoc.data().repostCount || 0) + 1;
      transaction.update(originalPostRef, { repostCount: updatedRepostCount, repostUserIds: repostUserIds });

      updateLocalStateForNewRepost(post.id, updatedRepostCount, repostRef.id);
    }
  });
  
};













const handleProfileSelect = async (profileData, userId) => {


  try {
    setIsSelecting(true);
    const popupData = preparePopupData(profileData, userId);
    setActivePopupMedia(popupData); // This triggers the useEffect
  } catch (error) {
setCurrentlyLoadingProfile(false);
    console.error('Error in handleProfileSelect:', error);
  }
};









const sortReleasesByTimestamp = (releases) => {
  const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);

  return sortedReleases;
};

const preparePopupData = (userData, userId) => {
  
   
   
  const albums = sortReleasesByTimestamp(userData.albums || []);
  const singles = sortReleasesByTimestamp(userData.singles || []);
  const videos = sortReleasesByTimestamp(userData.videos || []);

  let profileImageUrl = null;


    profileImageUrl = userData?.profileImageUrl || null;


  const popupData = {
    artistName: userData.artistName || userData.ArtistName,
    popupProfileImage: userData?.profileImageUrl,
    username: userData.username,
    accountType: userData.accountType,
    userId: userId,
    otherReleases: { albums, singles, videos },
    vocalDrop: userData.vocalDrop,
    voiceDropMuted: userData?.voiceDropMuted,
    bio: userData.bio,
    blurb: userData.blurb,
    location: userData.location,
    timestamp: new Date().getTime(),
    ScreenSKN: userData.ScreenSKN,
  };

  
  return popupData;
};




const getCachedUserDoc = async (userId) => {
  let userDataCached = [];
  let userDataCachedNew = [];
  const now = new Date().getTime();

  if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
      
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
          
          const freshUserData = userDocSnap.data();

          
          // Update userDataCached structure with fresh data and timestamp
          userDataCachedNew = {
              ...freshUserData, // overwrite with fresh data from Firestore
              profileImageUrl: freshUserData?.profileImageUrl,
              timestamp: now
          };



      } else {
          console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
      }
  } else {
      
      userDataCachedNew = userDataCached;
  }

  return userDataCachedNew;
};




const fetchUserProfileByUsername = async (username) => {
  if (!username) return;

setCurrentlyLoadingProfile(true);

  try {
    const q = query(collection(firestore, 'users'), where('username_lower', '==', username.toLowerCase()));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const userData = await getCachedUserDoc(doc.id);

      if (userData) {
        handleProfileSelect(userData, doc.id);
      } else {
        console.error(`UserData could not be prepared for username: ${username}`);
      }
    } else {
      console.error(`No user found with username: ${username}`);
    }
  } catch (error) {
    console.error('Error fetching user by username:', error);
setCurrentlyLoadingProfile(false);
  } finally {
    setIsLoading(false);
  }
};





const handleLikePress = async (postId) => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    console.error("No user logged in");
    return;
  }

  const db = getFirestore();
  const postRef = doc(db, 'posts', postId);
  const userLikesRef = doc(db, `users/${user.uid}/likes`, postId);
  const likesCollectionRef = collection(db, 'likes');
  const likeDocRef = doc(likesCollectionRef, `${user.uid}_${postId}`);

  // Assume optimistic UI updates
  setPosts((currentPosts) => {
    const postIndex = currentPosts.findIndex(p => p.id === postId);
    if (postIndex !== -1) {
      const updatedPosts = [...currentPosts];
      const currentPost = updatedPosts[postIndex];
      const currentlyLiked = currentPost.likes && currentPost.likes[user.uid];

      // Toggle the like state optimistically
      if (currentlyLiked) {
        
        currentPost.likeCount = Math.max(currentPost.likeCount - 1, 0); // Prevent negative count
        delete currentPost.likes[user.uid];
      } else {
        currentPost.likeCount += 1;
        currentPost.likes = currentPost.likes || {};
        currentPost.likes[user.uid] = true;
      }
      updatedPosts[postIndex] = currentPost;

      return updatedPosts;
    }
    return currentPosts;
  });

  // Update local likedPosts state optimistically
  setLikes((likedPosts) => ({
    ...likedPosts,
    [postId]: !(likedPosts && likedPosts[postId])  // Toggle the liked state
  }));


  try {
    await runTransaction(db, async (transaction) => {
      const postDoc = await transaction.get(postRef);
      if (!postDoc.exists()) {
        throw new Error("Post document does not exist!");
      }

      const postData = postDoc.data();
      const userLikeDoc = await transaction.get(userLikesRef);
      const likeDoc = await transaction.get(likeDocRef);
      const likesMap = postData.likes || {};
      let newLikeCount = postData.likeCount || 0;

      if (likeDoc.exists()) {
        // User is unliking the post
        transaction.delete(userLikesRef);
        transaction.delete(likeDocRef); // Delete the like from the main likes collection
        if (postData.likeCount > 0) {
          newLikeCount -= 1;
        }
        if (likesMap[user.uid]) {
          delete likesMap[user.uid];
        }
      } else {
        transaction.set(userLikesRef, { createdAt: serverTimestamp() });
        transaction.set(likeDocRef, { // Add the like into the main likes collection
          postId: postId,
          userId: user.uid,
          creatorId: postData.userId,
          name: currentUser.name,
          profileImageUrl: currentUser?.profileImageUrl ? currentUser?.profileImageUrl : '',
          createdAt: serverTimestamp()
        });
        likesMap[user.uid] = true;
        newLikeCount += 1;
      }

      // Update the post document with new like count and updated likes map
      transaction.update(postRef, {
        likeCount: newLikeCount,
        likes: likesMap
      });
    });
    
  } catch (error) {
    console.error("Transaction failed: ", error);
  }
};




function updateLocalStateForRepostDeletion(repostId, originalPostId, updatedRepostCount) {
  setPosts(posts => posts.map(post => {
    if (post.id === originalPostId || post.id === repostId) {
      return { ...post, repostCount: updatedRepostCount };
    }
    return post;
  }));

setLocalRepostCount(localRepostCount - 1);

  // Correctly remove the repost entry from the reposts state
  setReposts(reposts => {
    const updatedReposts = { ...reposts };
    delete updatedReposts[repostId];  // Ensure this matches how reposts are keyed
    delete updatedReposts[originalPostId];  // Also make sure the original post's reference is updated
    return updatedReposts;
  });
}


function updateLocalStateForNewRepost(postId, updatedRepostCount, repostRefId) {

setLocalRepostCount(post.repostCount + 1);
  setPosts(posts => posts.map(post => {
    if (post.id === postId) {
      return { ...post, repostCount: updatedRepostCount };
    }
    return post;
  }));

  // Add the new repost to the reposts state
  setReposts(reposts => ({
    ...reposts,
    [postId]: repostRefId  // This should uniquely identify the repost for UI updates
  }));
}


// Call this function after successful deletion of a repost
const handleRepostDeletion = (postId) => {
  // Find the repostId in the reposts state if it exists
  const repostId = reposts[postId];
  if (repostId) {
    setReposts(reposts => {
      const updatedReposts = { ...reposts };
      delete updatedReposts[postId]; // Remove the repost entry
      return updatedReposts;
    });
  }
}


  const likeIcon = (isOriginalFromRepost ? isLiked : likes[post.id]) 
    ? require('./assets/LikeIconActive.png')
    : require('./assets/LikeIcon.png');


    const handleReplyTextChange = (event, commentId) => {
      const text = event.target.value;
  
    setReplyTexts({ ...replyTexts, [commentId]: text });

    if (text.lastIndexOf("@") > (replyTags[commentId]?.length > 0 ? Math.max(...replyTags[commentId].map(tag => tag.end)) : -1)) {
        setIsSearchingReplies(true); // or another state to manage visibility of user suggestions
    } else {
        setIsSearchingReplies(false);
    }

    const adjustedTags = replyTags[commentId]?.map(tag => {
        if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
            return tag; // Tag is unchanged
        } else {
            const startPos = text.indexOf(`@${tag.username}`);
            if (startPos !== -1) {
                return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
            }
        }
        return null; // Tag no longer valid
    }).filter(tag => tag !== null) || [];

    setReplyTags({ ...replyTags, [commentId]: adjustedTags });

    
};



const handleReplyTextChangeReplies = (event, replyId) => {
  const text = event.target.value;
    setNestedReplyTexts({ ...nestedReplyTexts, [replyId]: text });

    if (text.lastIndexOf("@") > (nestedReplyTags[replyId]?.length > 0 ? Math.max(...nestedReplyTags[replyId].map(tag => tag.end)) : -1)) {
        setIsSearchingReplies(true); // or another state to manage visibility of user suggestions
    } else {
        setIsSearchingReplies(false);
    }

    const adjustedTags = nestedReplyTags[replyId]?.map(tag => {
        if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
            return tag; // Tag is unchanged
        } else {
            const startPos = text.indexOf(`@${tag.username}`);
            if (startPos !== -1) {
                return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
            }
        }
        return null; // Tag no longer valid
    }).filter(tag => tag !== null) || [];

    setNestedReplyTags({ ...nestedReplyTags, [replyId]: adjustedTags });

    
};



const onReplySubmit = (commentId) => {
  
  handleAddReply(commentId);
};




const handleDelete = async () => {
    if (!activeItemId) return; // No active item

    const { id, type, commentId, postId } = activeItemId;
    const db = getFirestore();
    let ref;

    if (type === 'post') {
        ref = doc(db, 'posts', id);
    } else if (type === 'comment') {
        ref = doc(db, 'posts', postId, 'comments', id);
    } else if (type === 'reply') {
        ref = doc(db, 'posts', postId, 'comments', commentId, 'replies', id);
    }

    if (ref) {
        try {
            if (type === 'post') {
                setPosts(prevPosts => prevPosts.filter(p => p.id !== id));

            } else {
                const postRef = doc(db, 'posts', postId);

                if (type === 'comment') {
                    setComments(prev => prev.filter(comment => comment.commentId !== id));
                    setPosts(prevPosts => prevPosts.map(p => {
                        if (p.id === postId) {
                            const newCommentCount = p.commentCount ? p.commentCount - 1 : 0;
                            return { ...p, commentCount: newCommentCount };
                        }
                        return p;
                    }));
                    setLocalCommentCount(prevCount => prevCount - 1);
                    await updateDoc(postRef, { commentCount: increment(-1) });
                } else if (type === 'reply') {
                    setComments(prev => prev.map(comment => {
                        if (comment.commentId === commentId) {
                            const updatedReplies = comment.replies.filter(reply => reply.replyId !== id);
                            return { ...comment, replies: updatedReplies };
                        }
                        return comment;
                    }));
                    setLocalCommentCount(prevCount => prevCount - 1);
                    await updateDoc(postRef, { commentCount: increment(-1) });
                }
            }

            await deleteDoc(ref);
            
            setActiveItemId(null);
        } catch (error) {
            console.error("Error deleting the document:", error);
        }
    } else {
        console.error("Failed to build Firestore reference for deletion.");
    }
};



const [reportComplete, setReportComplete] = useState(false);


const handleReport = async (post) => {
  const db = getFirestore();
  const reportRef = doc(collection(db, 'reports'));

  const reportData = {
    reportId: reportRef.id,
    postId: post.id,  // Assuming 'post' has an 'id' field
    text: post.text || '',
    imageUri: post.imageUri || '',
    userId: post.userId,
    username: post.username,
    name: post.name,
    profileImageUrl: post.profileImageUrl,
    createdAt: serverTimestamp(),
    reportedAt: serverTimestamp(),  // Timestamp when the report is created
  };

  try {
    await setDoc(reportRef, reportData);
    
    setReportComplete(true);
  } catch (error) {
    console.error("Error reporting the post: ", error);
  }
};


const handleShare = (post) => {
  setPostToShare(post);
  setSharePostActive(true);
};



const renderOptions = () => {
  if (!activeItemId) return null;
  const { id, type } = activeItemId;

  // Determine if the current user is the owner of the post
  let isOwner = false;
  if (type === 'post') {
    isOwner = post.userId === userId2;
  } else if (type === 'comment') {
    const comment = comments.find(c => c.commentId === id);
    if (comment) {
      isOwner = comment.userId === userId2;
    }
  } else if (type === 'reply') {
    const commentWithReply = comments.find(c => c.replies?.some(r => r.replyId === id));
    if (commentWithReply) {
      const reply = commentWithReply.replies.find(r => r.replyId === id);
      if (reply) {
        isOwner = reply.userId === userId2;
      }
    }
  }

  

  return (
    <div style={styles.optionsContainer} onClick={(e) => e.stopPropagation()}>
      {type === 'post' && (
        <button style={{marginBottom: '4px'}} onClick={(e) => { e.stopPropagation(); handleShare(post); }}>
          Share
        </button>
      )}
      {isOwner && (
        <button onClick={(e) => { e.stopPropagation(); handleDelete(); }}>
          Delete
        </button>
      )}
      {!isOwner && (
        <button onClick={(e) => { e.stopPropagation(); handleReport(post); }}>
          Report
        </button>
      )}
    </div>
  );
};







const handleToggleOptions = (id, type, commentId, postId = null) => {
  const newItemActive = { id, type, commentId, postId };
   // Add this line
  if (activeItemId && activeItemId.id === id && activeItemId.type === type) {
    
    setActiveItemId(null); // Same item, toggle off
  } else {
    
    setActiveItemId(newItemActive); // Different item or different state, toggle on
  }
};



const formatDisplayComment = (comment) => {
  return comment.replace(/@\w+\{\w+\}/g, (match) => {
    // Extract just the username part before '{'
    return match.split('{')[0];
  });
};



  const onRefresh = () => {};


  const handleVideoPress = async (videoRef) => {
    if (videoRef) {
      await videoRef.presentFullscreenPlayer();  // This will trigger native fullscreen
      videoRef.setOnPlaybackStatusUpdate(status => {
        if (status.didJustFinish && !status.isLooping) {
          videoRef.dismissFullscreenPlayer(); // Optionally dismiss after playback
        }
      });
    }
  };


  const onModalOpen = () => {
    if (videoRef.current) {
      videoRef.current.playAsync(); // Ensure the video plays when the modal opens
    }
  };



const handleMediaUriTap = (mediaUri, mediaType) => {
  if (mediaType === 'video') {
    setCurrentVideoUri(mediaUri); // Set the current video URI
    setModalVisibleVideo(true);
  } else {
    setModalVisible(true);
    setCurrentImage(mediaUri);
  }
};


const handlePressProfile = (profile) => {
  const clonedProfile = JSON.parse(JSON.stringify(profile));
  const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
  navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
};

const fetchUserProfile = async (userId) => {
  try {
    const userDocRef = doc(firestore, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      handlePressProfile(userDoc.data());
    } else {
      
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
  }
};

const fetchUserProfileByUserId = async (userId) => {
  if (!userId) {
    
    return;
  }

  


  try {
    fetchUserProfile(userId);

  } catch (error) {
    console.error('fetchUserProfileByUserId: Error fetching user by userId:', error);
  } finally {

    setIsLoading(false);
  }
};


  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <div style={styles.postContainer}>
          <div style={styles.contentRow}>
            <ProfilesDisplaySharePost userProfile={post} onPress={() => fetchUserProfileByUserId(post.userId)} />
            <RealTimeAgo timestamp={post.createdAt} style={styles.timeAgoStyle} />
            <button style={styles.moreInfoButton}   onClick={(e) => {
    e.stopPropagation();
    
    handleToggleOptions(post.postId, 'post', post.postId, post.postId);
  }}>
                    <img src={ellipsis} style={{ position: 'relative', bottom: '5px', width: '20px', height: '20px', backgroundColor: 'transparent', zIndex: '50000', pointerEvents: 'auto' }}></img>
                  </button>
            {activeItemId && activeItemId.id === post.postId && activeItemId.type === 'post' && renderOptions()}
          </div>
          {post?.media ? (
            <button onClick={() => handlePressBeginsSHARE(post.media)} style={styles.imageContainer}>
              <DynamicHeightImage uri={post.media.ArtworkURL} />
            </button>
          ) : post.imageUri ? (
            <button onClick={() => post.mediaType === 'video' ? handleMediaUriTap(post.imageUri, post.mediaType) : handleMediaUriTap(post.imageUri, post.mediaType)} style={styles.mediaContainer}>
              {post.mediaType === 'video' ? (
                <button onClick={() => handleMediaUriTap(post.imageUri, post.mediaType)} style={styles.mediaContainer2}>
                  <video ref={ref => { videoRefs.current[post.imageUri] = ref }} src={post.imageUri} style={styles.mediaThumbnail2} controls={false} />
                </button>
              ) : (
                <DynamicHeightImage uri={post.imageUri} />
              )}
            </button>
          ) : null}
          <p style={post.mediaType === 'video' ? styles.postText2 : (post.imageUri ? styles.postText : styles.postTextNoImage)}>
            {post.text.split(/(\@\w+)/g).map((part, index) => {
              if (part.startsWith('@') && post.taggedUsers) {
                const userTag = post.taggedUsers.find(tag => `@${tag.username}` === part);
                if (userTag) {
                  return (
                    <span style={styles.tagContainer} key={index} onClick={() => fetchUserProfileByUserId(userTag.userId)}>
                      <span style={{ color: '#62fbfa' }}>{part}</span>
                    </span>
                  );
                }
              }
              return <span key={index}>{part}</span>;
            })}
          </p>

          {post.profile && (
            <ProfilesDisplaySharePost userProfile={post.profile} onPress={() => fetchUserProfileByUserId(post.profile.userId)} />
          )}

          {post.originalPost && (
            <button onClick={() => handlePressOriginalPost(post.originalPost)} style={styles.postContainer2}>
              <div style={styles.contentRow}>
                <ProfilesDisplaySharePost userProfile={post.originalPost} onClick={() => fetchUserProfileByUserId(post.originalPost.userId)} />
                <RealTimeAgo timestamp={post.originalPost.createdAt} style={styles.timeAgoStyle} />
              </div>
              {post.originalPost.media ? (
                <button onClick={() => handlePressBeginsSHARE(post.originalPost.media)} style={styles.imageContainer}>
                  <DynamicHeightImage uri={post?.originalPost.media?.ArtworkURL} />
                </button>
              ) : post.originalPost.imageUri ? (
                <button onClick={() => post.originalPost.mediaType === 'video' ? handleMediaUriTap(post.originalPost.imageUri, post.originalPost.mediaType) : handleMediaUriTap(post.originalPost.imageUri, post.originalPost.mediaType)} style={styles.mediaContainer}>
                  {post.originalPost.mediaType === 'video' ? (
                    <video ref={ref => { videoRefs.current[post.imageUri] = ref }} src={post.originalPost.imageUri} style={styles.mediaThumbnail} controls={false} />
                  ) : (
                    <DynamicHeightImage uri={post.originalPost.imageUri} />
                  )}
                </button>
              ) : null}
              <p style={post.originalPost.imageUri ? styles.postText : styles.postTextNoImage}>{post.originalPost.text}</p>
              {post?.originalPost?.profile && (
                <ProfilesDisplaySharePost userProfile={post?.originalPost?.profile} onClick={() => fetchUserProfileByUserId(post?.originalPost?.profile?.userId)} />
              )}
            </button>
          )}

          <div style={styles.iconsContainer}>
            <button style={styles.iconButton} onClick={() => handleLikePress(post.postId)}>
              <div style={styles.likeButtonContainer}>
                <img src={likes[post.id] ? LikeIconActive : LikeIcon} style={styles.iconImage} />
                <span style={styles.likeCount}>{isOriginalFromRepost ? localLikeCount : post.likeCount}</span>
              </div>
            </button>

            <button style={styles.iconButton}>
              <div style={styles.likeButtonContainer}>
                <img src={CommentIcon} style={styles.iconImage} />
                <span style={styles.likeCount}>{localCommentCount || 0}</span>
              </div>
            </button>

            {!post.originalPost && (
              <button style={styles.iconButton} onClick={() => handleRepost(post)}>
                <div style={styles.likeButtonContainer}>
                  <img src={reposts[post.id] || (post.originalPost && reposts[post.originalPost.id]) ? RepostIconActive
                          : RepostIcon
                      } style={styles.iconImage} />
                  <span style={styles.likeCount}>{localRepostCount || 0}</span>
                </div>
              </button>
            )}
          </div>
        </div>

        <div style={styles.commentSectionContainer}>
          {isSearchingComments && (
            <div style={styles.userSuggestionContainer}>
              {searchResultsComments.map(user => (
                <button style={{backgroundColor: 'transparent', border: 'none'}} key={user.id} onClick={() => handleSelectUser(user)}>
                  <span style={styles.userSuggestion}>{user.username}</span>
                </button>
              ))}
            </div>
          )}

          <div style={styles.addCommentContainer}>
            <textarea
              ref={commentInputRef}
              style={{ ...styles.commentInput, maxHeight: height * 0.1 }}
              onChange={handleTextChange}
              placeholder="Write a comment or @username..."
              value={comment}
              onSelect={(event) => {
                setSelection({ start: event.target.selectionStart, end: event.target.selectionEnd });
              }}
              placeholderTextColor="#b3b3b3"
            ></textarea>

            <button style={styles.commentButton} onClick={handleAddComment}>
              <span style={styles.commentButtonText}>Comment</span>
            </button>
          </div>
        </div>

        <div style={styles.commentsContainer}>
          <span style={styles.commentsHeading}>Comments</span>
          {comments.map((comment) => {
            const key = comment.commentId;
            return (
              <div key={key} style={styles.commentContainer}>
                <div style={styles.commentContentRow}>
                  <ProfilesDisplaySharePost userProfile={comment} onClick={() => fetchUserProfileByUserId(comment.userId)} />
                  <RealTimeAgo timestamp={comment.createdAt} style={styles.timeAgoStyle} />
                  <button
  style={styles.moreInfoButton}
  onClick={(e) => {
    e.stopPropagation();
    if (!comment || !post) {
      console.error('comment or post is undefined', { comment, post });
      return;
    }
    
    handleToggleOptions(comment.commentId, 'comment', comment.commentId, post.postId);
  }}
>
                    <img src={ellipsis} style={{ width: '25px', height: '25px', backgroundColor: 'transparent', zIndex: '50000', pointerEvents: 'auto' }}></img>
                  </button>
                  {activeItemId && activeItemId.id === comment.commentId && activeItemId.type === 'comment' && renderOptions()}
                </div>
                <p style={styles.commentText}>
                  {Array.isArray(comment.text) ? comment.text.map((part, index) => {
                    if (part.type === 'tag') {
                      return (
                        <button key={index} onClick={() => fetchUserProfileByUserId(part.userId)}>
                          <span style={{ color: '#62fbfa' }}>{`@${part.username}`}</span>
                        </button>
                      );
                    } else {
                      return <span key={index}>{part.content}</span>;
                    }
                  }) : comment.text.split(/(\@\w+)/g).map((part, index) => {
                    if (part.startsWith('@') && comment.taggedUsers) {
                      const userTag = comment.taggedUsers.find(tag => `@${tag.username}` === part);
                      if (userTag) {
                        return (
                          <button style={{backgroundColor: 'transparent', border: 'none',}} key={index} onClick={() => fetchUserProfileByUserId(userTag.userId)}>
                            <span style={{ color: '#62fbfa', backgroundColor: 'transparent' }}>{part}</span>
                          </button>
                        );
                      }
                    }
                    return <span key={index}>{part}</span>;
                  })}
                </p>
                <button style={{backgroundColor: 'transparent', border: 'none', }} onClick={() => toggleRepliesVisibility(comment.commentId)}>
                  <span style={styles.repliesText}>Replies({comment?.replies?.length})</span>
                </button>
                {visibleReplies[comment.commentId] && (
                  <>
                    <div style={styles.userSuggestionContainer}>
                      {isSearchingReplies && searchResultsReplies.map(user => (
                        <button style={{backgroundColor: 'transparent', border: 'none'}} key={user.id} onClick={() => handleSelectUserForReply(user, comment.commentId)}>
                          <span style={styles.userSuggestion}>{user.username}</span>
                        </button>
                      ))}
                    </div>
                    <div style={styles.addReplyContainer}>
                      
                    <textarea
                      ref={replyInputRef}
                      style={{ ...styles.replyInput, maxHeight: height * 0.1 }}
                      value={replyTexts[comment.commentId] || ''}
                      onChange={(event) => handleReplyTextChange(event, comment.commentId)}
                      placeholder="Write a reply or @username..."
                      placeholderTextColor="#b3b3b3"
                      onSelect={(event) => setSelectionReplies({ [comment.commentId]: { start: event.target.selectionStart, end: event.target.selectionEnd } })}
                    ></textarea>
                    <button style={styles.replyButton}  onClick={() => onReplySubmit(comment.commentId)}>
                      <span style={styles.replyButton}>Reply</span>
                    </button>
                    </div>
                    {comment?.replies?.slice(0, 7).map((reply, index) => (
                      <div key={reply.replyId} style={styles.commentContainerReplies}>
                        <div style={styles.commentContentRow}>
                          <ProfilesDisplaySharePost userProfile={reply} onClick={() => fetchUserProfileByUserId(reply.userId)} />
                          <RealTimeAgo timestamp={reply.createdAt} style={styles.timeAgoStyle} />
                          <button style={styles.moreInfoButton} onClick={(e) => { e.stopPropagation(); }}>
                    <img src={ellipsis} style={{ width: '25px', height: '25px', backgroundColor: 'transparent', zIndex: '50000', pointerEvents: 'auto' }}></img>
                  </button>
                          {activeItemId && activeItemId.id === reply.replyId && activeItemId.type === 'reply' && renderOptions()}
                        </div>
                        <p style={styles.commentText}>
                          {Array.isArray(reply.text) ? reply.text.map((part, i) => {
                            if (part.type === 'tag') {
                              return (
                                <button key={i} onClick={() => fetchUserProfileByUserId(part.userId)}>
                                  <span style={{ color: '#62fbfa' }}>{`@${part.username}`}</span>
                                </button>
                              );
                            } else {
                              return <span key={i}>{part.content}</span>;
                            }
                          }) : reply.text.split(/(\@\w+)/g).map((part, i) => {
                            if (part.startsWith('@') && reply.taggedUsers) {
                              const userTag = reply.taggedUsers.find(tag => `@${tag.username}` === part);
                              if (userTag) {
                                return (
                                  <button style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer', }} key={i} onClick={() => fetchUserProfileByUserId(userTag.userId)}>
                                    <span style={{ cursor: 'pointer', color: '#62fbfa', backgroundColor: 'transparent' }}>{part}</span>
                                  </button>
                                );
                              }
                            }
                            return <span key={i}>{part}</span>;
                          })}
                        </p>
                        <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => initiateReplyToReply(reply, comment.commentId)}>
                          <span style={{ color: '#62fbfa', marginTop: 5, backgroundColor: 'transparent', border: 'none' }}>Reply</span>
                        </button>
                        {reply.isReplying && (
                          <>
                            <textarea
                              ref={textInputRef}
                              style={{ ...styles.replyInput, maxHeight: height * 0.1 }}
                              value={nestedReplyTexts[reply.replyId] || `@${reply.username} `}
                              onChange={(event) => handleReplyTextChangeReplies(event, reply.replyId)}
                              placeholder="Write a reply..."
                              placeholderTextColor="#b3b3b3"
                              autoFocus={true}
                            ></textarea>
                            <button style={{backgroundColor: 'transparent', border: 'none'}} onClick={() => onNestedReplySubmit(reply.replyId, comment.commentId)}>
                              <span style={styles.replyButton}>Submit Reply</span>
                            </button>
                          </>
                        )}
                      </div>
                    ))}
                    {comment?.replies?.length > 7 && (
                      <button >
                        <span style={styles.moreRepliesText}>More replies({comment?.replies?.length - 7})</span>
                      </button>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>

        {modalVisible && (
          <div style={styles.fullScreenContainer}>
            <button style={{ ...styles.fullScreenContainer, activeOpacity: 1 }} onClick={() => setModalVisible(false)}>
              <img src={currentImage} style={styles.fullScreenImage} />
            </button>
          </div>
        )}

        {modalVisibleVideo && (
          <div style={styles.fullscreenVideo}>
            <video
              ref={videoRef}
              src={currentVideoUri}
              controls={true}
              style={{ resizeMode: 'contain' }}
              onFullscreenChange={handleFullscreenUpdate}
              onLoad={() => videoRef.current?.requestFullscreen().catch(error => console.error("Failed to enter fullscreen mode", error))}
              loop
            ></video>
            <div style={styles.controlButton}>
            </div>
          </div>
        )}

        {reportComplete && (
          <button style={styles.fullScreenOverlay} onClick={() => setReportComplete(false)}>
            <img src='./assets/DATBG.png' style={styles.fullScreenImage} />
            <span style={styles.overlayText}>Report Successful</span>
          </button>
        )}


      </div>
      <Modal
      isOpen={sharePostActive}
      onRequestClose={() => setSharePostActive(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '200px',
          width: '80%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>
      <ProfilesDisplayShareDATpost/>
      </div>
      <div style={styles.cancelContainer}>
      <button style={styles.cancelButton} onClick={() => setSharePostActive(false)}>Cancel</button>
      </div>
    </Modal>
    <Modal
      isOpen={showSharePostPopup}
      onRequestClose={() => setShowSharePostPopup(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '400px',
          width: '80%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>
      <SharePostPopup/>
      </div>
      <div style={styles.cancelContainer}>
      <button style={styles.cancelButton} onClick={() => setShowSharePostPopup(false)}>Cancel</button>
      </div>
    </Modal>
    <div style={styles.extraSpace}></div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
  },
  extraSpace: {
    height: '300px',
      },
  sharingPost: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
        },
        cancelContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
        },
        cancelButton: {
          padding: '10px 20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          margin: '0 auto',
          position: 'absolute',
          bottom: '10px',
          backgroundColor: 'transparent',
          color: '#62fbfa',
          border: 'none',
          fontSize: '20px',
          borderRadius: '4px',
          cursor: 'pointer',
        },
  fullScreenOverlay: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  },
  overlayText: {
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
  replyInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '5px',
  },
  iconButton: {
    paddingLeft: '20px',
    marginLeft: '-20px',
    paddingRight: '20px',
    marginRight: '-20px',
    marginBottom: '-10px',
    paddingBottom: '10px',
    paddingTop: '8px',
    marginTop: '-8px',
  },
  userSuggestionContainer: {
    backgroundColor: 'transparent',
    paddingVertical: '5px',
    paddingHorizontal: '10px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  userSuggestion: {
    color: '#FFFFFF',
    padding: '10px',
    fontSize: '16px',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    borderColor: '#CCCCCC',
    borderRadius: '5px',
    border: 'none',
    marginVertical: '2px',
  },
  replyInput: {
    flex: 1,
    backgroundColor: 'rgba(81, 33, 131, 0.5)',
    borderRadius: '20px',
    paddingHorizontal: '3%',
    paddingVertical: '1%',
    marginRight: '1%',
    border: 'none',
    resize: 'none',
    marginLeft: '2%',
    color: 'white',
    marginTop: '1%',
  },
  replyButton: {
    backgroundColor: 'transparent',
    padding: '7px',
    justifyContent: 'center',
    alignSelf: 'center',
    border: 'none',
    fontFamily: 'brhendrix',
    fontSize: '14px',
    marginTop: '1%',
    color: 'white',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  contentContainer: {
    paddingVertical: '17%',
  },
  commentsContainer: {
marginBottom: '50%',
  },
  buttonTouchableArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 'calc(100% - 10vh)',
    zIndex: 10,
    overflow: 'visible',

  },
  popupImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 5,
  },
  likeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  postContainer: {
    padding: '0.7%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'rgba(23, 195, 250, 0.5)',
    marginLeft: '10px',
    marginRight: '10px',
    zIndex: 10,
  },
  postContainer2: {
    padding: '0.7%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'rgba(23, 195, 250, 0.5)',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '2%',
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    zIndex: 10,
  },
  timeAgoStyle: {
    color: 'white',
    fontSize: '14px',
    flexShrink: 1,
    right: 50,
    position: 'relative',
    marginBottom: '15px',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: '5px',
    paddingBottom: '21px',
  },
  postText: {
    marginTop: '5%',
    marginLeft: '5%',
    marginBottom: '1.8%',
    marginRight: '5%',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'brhendrix',
  },
  postText2: {
    marginTop: '5%',
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '1.5%',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'brhendrix',
  },
  postTextNoImage: {
    marginTop: '-2%',
    marginLeft: '23%',
    marginBottom: '1.8%',
    marginRight: '5%',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'brhendrix',
  },
  iconImage: {
    width: '5%',
    height: '5%',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    bottom: '0.5%',
  },
  likeCount: {
    color: 'white',
    fontSize: '3%',
  },
  commentContainer: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'rgba(23, 195, 250, 0.5)',
    padding: '10px',
    marginLeft: '15px',
    marginHorizontal: '10px',
    marginBottom: '5px',
  },
  commentContainerReplies: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'rgba(23, 195, 250, 0.5)',
    padding: '10px',
    marginHorizontal: '10px',
    marginBottom: '5px',
    marginTop: '1%',
  },
  commentContentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.8%',
    marginLeft: '-6.9%',
  },
  commentsHeading: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginLeft: '2.7%',
    marginBottom: '1%',
    color: 'white',
  },
  commentItem: {
    marginBottom: '1%',
  },
  commentText: {
    color: 'white',
    fontSize: '18px',
    marginTop: '-2%',
    fontFamily: 'brhendrix',
    marginLeft: '2%',
  },
  addCommentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2%',
    marginTop: '2%',
  },
  addReplyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2%',
    marginTop: '2%',
  },
  commentInput: {
    flex: 1,
    backgroundColor: 'rgba(81, 33, 131, 0.5)',
    borderRadius: '20px',
    border: 'none',
    resize: 'none',
    paddingHorizontal: '3%',
    paddingVertical: '1%',
    marginRight: '1%',
    marginLeft: '4%',
    fontSize: '20px',
    paddingLeft: '10px',
    paddingTop: '2%',

    color: 'white',
  },
  commentButton: {
    borderRadius: '28px',
    paddingHorizontal: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
    marginRight: '4%',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  commentButtonText: {
    color: 'white',
    fontSize: '14px',
    fontFamily: 'brhendrix',
    fontWeight: 'bold',
  },
  nestedCommentContainer: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'rgba(23, 195, 250, 0.5)',
    padding: '10px',
    marginHorizontal: '20px',
    marginBottom: '5px',
  },
  postImage: {
    width: '100%',
    height: '30vh',
    alignSelf: 'center',
    marginTop: '1%',
    marginBottom: '2%',
    resizeMode: 'contain',
    
  },
  optionsContainer: {
    position: 'absolute',
    right: 20,
    display: 'flex',
    flexDirection: 'column',
    top: 25,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: '5px',
    padding: '5px',
    marginRight: '5%',
  },
  optionText: {
    color: 'white',
    padding: '5px',
    fontFamily: 'brhendrix',
  },
  repliesText: {
    color: 'white',
    marginLeft: '20px',
    fontFamily: 'brhendrix',
    marginTop: '1.6%',
  },
  moreRepliesText: {
    color: 'white',
    marginLeft: '20px',
    marginBottom: '10px',
    fontFamily: 'brhendrix',
    cursor: 'pointer',
  },
  fullScreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  fullscreenVideo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
  },
  controlButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    color: 'red',
  },
  mediaContainer2: {
    width: '100%',
    height: '27vh',
    overflow: 'hidden',
    borderRadius: '2%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    border: 'none',
  },
  mediaThumbnail2: {
    width: '100%',
    height: '120%',
    resizeMode: 'cover',
  },
  additionalMargin: {
    marginBottom: '2.3%',
  },
  playIcon: {
    position: 'absolute',
    width: '6.8%',
    height: '8%',
    alignSelf: 'center',
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1%',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    borderRadius: '10px',
    border: 'none',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    marginBottom: '2%',
  },
  postContainer: {
    padding: '7px',
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: '8px',
    border: '1px solid rgba(23, 195, 250, 0.5)', // Use shorthand property for border
    marginLeft: '10px',
    marginRight: '10px',
    zIndex: 10,
  },

activityIndicator: {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)'
},
controlButton: {
  position: 'absolute',
  top: '20px',
  right: '20px',
  padding: '10px'
},
iconButton: {
  paddingLeft: '20px',
  marginLeft: '-20px',
  paddingRight: '20px',
  marginRight: '-20px',
  marginBottom: '-10px',
  paddingBottom: '10px',
  paddingTop: '8px',
  marginTop: '-8px',
  backgroundColor: 'transparent',
  border: 'none',
},
iconButtonLike: {
  paddingLeft: '20px',
  marginLeft: '-20px',
  paddingRight: '20px',
  marginRight: '-20px',
  marginBottom: '-10px',
  paddingBottom: '10px',
  paddingTop: '8px',
  marginTop: '-8px',
  backgroundColor: 'transparent',
  border: 'none',
  // Any other styles you want to apply to the touchable opacity
},

likeButtonContainer: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundColor: 'transparent',
  cursor: 'pointer',
},
iconImage: {
  width: '16px',
  height: '16px',
  marginRight: '5px',
  backgroundColor: 'transparent',
  
},
likeCount: {
  color: 'white',
  fontSize: '16px',
},
postContainer2: {
  padding: '7px',
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: '8px',
  borderWidth: '1px',
  borderColor: 'rgba(23, 195, 250, 0.5)',
  marginLeft: '10px',
  marginRight: '10px',
  marginBottom: '20px',
  cursor: 'pointer',
},
postText: {
  marginTop: '20px',
  marginLeft: '20px',
  marginBottom: '18px',
  marginRight: '20px',
  color: 'white',
  fontSize: '18px',
  fontFamily: 'brhendrix',
},
postText2: {
  marginTop: '20px',
  marginLeft: '20px',
  marginRight: '20px',
  marginBottom: '15px',
  color: 'white',
  fontSize: '18px',
  fontFamily: 'brhendrix',
},
postTextNoImage: {
  marginTop: '-8px',
  marginLeft: '30px',
  marginBottom: '18px',
  marginRight: '20px',
  color: 'white',
  fontSize: '18px',
  fontFamily: 'brhendrix',
},
postImage: {
  width: '100%',
  height: '300px',
  backgroundColor: 'transparent',
  alignSelf: 'center',
  marginTop: '10px',
  marginBottom: '20px',
  objectFit: 'contain',
},
fullScreen: {
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflow: 'hidden',
  position: 'relative',
  overflowY: 'scroll',
  marginTop: '10px',
},
scrollContainer2: {
  position: 'relative',
  width: '100%',
  height: '100%',
  zIndex: 2,
  listStyleType: 'none',
  padding: 0,
  overflowY: 'scroll', // Ensure vertical scrolling
},
scrollContainer: {
  position: 'relative',
  width: '100%',
  height: '100%',
  zIndex: 3,
  overflowY: 'scroll', // Ensure vertical scrolling
},
iconsContainer: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: '5px',
},
moreInfoButton: {
  marginLeft: 'auto',
  marginBottom: '20px',
  width: '50px',
  pointerEvents: 'auto',
  height: '100%',
  padding: '10px',
  border: 'none',
  backgroundColor: 'transparent',
  zIndex: '50000',
  position: 'relative',
  cursor: 'pointer',
},
contentRow: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
},
timeAgoStyle: {
  color: 'white',
  fontSize: '14px',
  flexShrink: 1,
  right: 50,
  position: 'relative',
  marginBottom: '15px',
  justifyContent: 'center',
  alignSelf: 'center',
  paddingHorizontal: '5px',
  paddingBottom: '21px',
},
buttonTouchableArea: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
},
optionsContainer: {
  position: 'absolute',
  right: 30,
  display: 'flex',
  marginBottom: '20px',
  flexDirection: 'column',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '5px',
  padding: '5px',
  marginRight: '5%',
},
fullscreenVideo: {
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
},
modal: {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
closeButton: {
  position: 'absolute',
  top: '20px',
  right: '20px',
  padding: '10px',
  backgroundColor: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
},
tagContainer: {
  display: 'inline',
  cursor: 'pointer',
},
playIcon: {
  position: 'absolute',
  width: '68px',
  height: '80px',
  alignSelf: 'center',
},
fullScreenOverlay: {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10000,
},
fullScreenImage: {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
},
overlayText: {
  color: 'white',
  fontSize: '20px',
  fontWeight: 'bold',
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
},
imageContainer: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
  overflow: 'hidden',
  borderRadius: '10px',
  backgroundColor: 'transparent',
  border: 'none',
  marginBottom: '20px',
},
mediaContainer: {
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '10px',
  border: 'none',
  objectFit: 'contain',
  backgroundColor: 'transparent',
  zIndex: 500,
  border: 'none',
},
mediaContainer2: {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  borderRadius: '20px',
  
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'black',
  zIndex: 500,
  border: 'none',
},
mediaThumbnail2: {
  width: '100%',
  height: '120%',
  objectFit: 'cover',
},
};

export default ViewPostScreen;
