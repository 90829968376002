import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';

const ShareCreditsPopup = ({ onShare, onCancel }) => {
  const [amount, setAmount] = useState('');
  const { selectedUserIdShare, setShowCreditSharePopup, creditsIOS, showCreditSharePopupProfile, setShowCreditSharePopupProfile, showSuccessAlertShareCredits, setShowSuccessAlertShareCredits, currentlySharingCredits, setCurrentlySharingCredits } = useContext(AppContext);



  const handleShare = () => {
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount) || numericAmount <= 0 || numericAmount > creditsIOS) {
      alert('Invalid Amount', 'Please enter a valid amount within your available credits.');
      return;
    }
    onShare(numericAmount);
  };

  const handleAmountChange = (input) => {
    const numericValue = parseFloat(input);
    if (!isNaN(numericValue) && numericValue > creditsIOS) {
      setAmount(String(creditsIOS));  // Automatically adjust to max credits if input exceeds
    } else {
      setAmount(input);  // Otherwise, allow the input
    }
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.touchableWithoutFeedback}>
        <div style={styles.popupContainer}>
          <div style={styles.keyboardAvoidingView}>
            <p style={styles.text}>Credits to share</p>
            <input
              style={styles.input}
              placeholder="Enter amount"
              type="number"
              value={amount}
              onChange={(e) => handleAmountChange(e.target.value)}
              maxLength={String(creditsIOS).length}
            />
            <button style={styles.buttonContainer} onClick={handleShare}>
              <span style={styles.buttonText}>Share Credits</span>
            </button>
            <button style={styles.buttonContainer} onClick={() => setShowCreditSharePopup(false)}>
              <span style={styles.buttonText}>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    height: '60vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '20px',
  },
  popupContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '10px',
  },
  text: {
    fontSize: '20px',
    marginBottom: '20px',
    color: 'white',
  },
  input: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    alignSelf: 'center',
    marginBottom: '20px',
    borderWidth: '1px',
    borderColor: 'white',
    color: 'white',
    fontSize: '16px',
    backgroundColor: 'transparent',
  },
  buttonContainer: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#62fbfa',
    borderRadius: '5px',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    color: 'white',
    cursor: 'pointer',
  },
  buttonText: {
    color: '#62fbfa',
    fontSize: '16px',
  },
  touchableWithoutFeedback: {
    width: '100%',
    height: '100%',
  },
  keyboardAvoidingView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export { ShareCreditsPopup };
