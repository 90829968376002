import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, query, setDoc, orderBy, limit, onSnapshot, getDoc, doc, updateDoc, getFirestore, increment, where, deleteDoc, runTransaction } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ref, getDownloadURL } from 'firebase/storage';
import ScreenSKNList from './ScreenSKNList';
import { firestore, storage } from './firebase';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const CACHE_DURATION = 100000000;

const bottomAreaHeight = height * 0.18;
const horizontalAreaSpacing = height * 0.015;
const scrollAreaTopPadding = height * 0.16;

const RemoveReleasesPage = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  const [featuredSongs, setFeaturedSongs] = useState([]);
  const [newReleases, setNewReleases] = useState([]);
  const [musicVideos, setMusicVideos] = useState([]);
  const [activeURL, setActiveURL] = useState(null);
  const db = getFirestore();
  const {
    playMedia,
    isPopupVisible, setIsPopupVisible,
    showMediaPopup,
    activePopupMedia,
    closePopup,
    httpArtworkUrl, setHttpArtworkUrl,
    isLoadingArtwork, setIsLoadingArtwork,
    popupSongArtworkUrl, setPopupSongArtworkUrl,
    popupVideoArtworkUrl, setPopupVideoArtworkUrl,
    showPopup, setShowPopup,
    handlePlayAndClose,
    isExpanded, setIsExpanded,
    userId, setUserId,
    setInitialExpandedAlbumAndSong,
    isLoadingNewPopup, setIsLoadingNewPopup,
    isLoadingExpand, setIsLoadingExpand,
    isLoadingSameUser, setIsLoadingSameUser,
    isLoadingProfilePicture, setIsLoadingProfilePicture,
    lastViewedMedia, setLastViewedMedia,
    showSameMediaPopup,
    setArtworkUri, centeredRelease, setCenteredRelease,
    hideExpandedAlbum, setHideExpandedAlbum,
    activeTab, setActiveTab,
    setInitiallySelectedItemId,
    isLoadingHideEA, setIsLoadingHideEA, currentUser, setUser, mediaData, setMediaData,
  } = useContext(AppContext);

  const firestore = getFirestore();
  const auth = getAuth();

  const removeContent = async (contentId, type) => {
    let collectionName, genres = [], tags = [];

    switch (type) {
        case 'albums':
        case 'videoAlbums':
            collectionName = 'Albums';
            break;
        case 'videos':
            collectionName = 'Videos';
            break;
        default:
            collectionName = 'Songs'; 
    }

    

    try {
        if (type === 'albums') {
            
            const albumDocRef = doc(db, collectionName, contentId);
            const albumDocSnap = await getDoc(albumDocRef);
            if (albumDocSnap.exists()) {
                const albumData = albumDocSnap.data();
                genres = albumData.genres || [];
                tags = albumData.tags || [];
                
            } else {
                
            }
        }

        
        await deleteDoc(doc(db, collectionName, contentId));

        
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await runTransaction(db, async (transaction) => {
            const userDocSnap = await transaction.get(userDocRef);
            if (!userDocSnap.exists()) {
                throw new Error("User Document does not exist!");
            }
            const userData = userDocSnap.data();
            const updatedArray = userData[type].filter(item => item.contentId !== contentId);
            transaction.update(userDocRef, { [type]: updatedArray });


            
        });


    } catch (error) {
        console.error(`Failed to remove ${type.slice(0, -1)}:`, error);

    }
};

  const mediaTypes = [
    { title: 'Singles', data: mediaData.singles },
    { title: 'Albums', data: mediaData.albums },
    { title: 'Music Videos', data: mediaData.videos },
    { title: 'Video Albums', data: mediaData.videoAlbums },
  ];

  const getCachedUserDoc = async (userId) => {
    let userData = [];
    const now = new Date().getTime();
  
    if (!userData || now - userData.timestamp >= CACHE_DURATION) {
      
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        
        userData = { ...userDocSnap.data(), timestamp: now };

      } else {
        console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
      }
    }
    return userData;
  };

  const handlePressBegins = async (song) => {
    setIsLoadingHideEA(true);
    setInitiallySelectedItemId(song.contentId);
    if (song.isVideo) {
      setActiveTab('Videos');
    } else {
      setActiveTab('Music');
    }
    setHideExpandedAlbum(true);
    onMoveMP();
    setIsExpanded(true);
    setIsLoadingArtwork(true);
    setIsLoadingProfilePicture(true);
    setIsLoadingExpand(true);

    try {
      let userData = [];
      const currentTime = new Date().getTime();

      if (!userData || currentTime - userData.timestamp >= CACHE_DURATION) {
        setIsLoadingNewPopup(true);
        const fetchedUserData = await getCachedUserDoc(song.userId);
        if (!fetchedUserData) throw new Error('User data not available');
        userData = { ...fetchedUserData, timestamp: currentTime, profileImageUrl: fetchedUserData.localProfileImageUrl };

      }

      const popupData = preparePopupData(song, userData);
      showMediaPopup(popupData);
    } catch (error) {
      console.error('Error in handlePressBegins:', error);
    } finally {
      setIsLoadingNewPopup(false);
      setIsLoadingArtwork(false);
      setIsLoadingProfilePicture(false);
      setIsLoadingExpand(false);
    }
  };

  const preparePopupData = (song, userData) => {
    let profileImageUrl = null;
    const itemUserData = [];

    if (itemUserData) {
      profileImageUrl = itemUserData.localProfileImageUrl || null;
    }

    let userAlbums = sortReleasesByTimestamp(userData.albums || []);
    let userSingles = sortReleasesByTimestamp(userData.singles || []);
    let userVideos = sortReleasesByTimestamp(userData.videos || []);

    let selectedAlbum = userAlbums.find(album => album.contentId === song.contentId) || null;
    let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;
    let selectedVideo = userVideos.find(video => video.contentId === song.contentId) || null;

    const artworkUri = selectedVideo?.VideoThumbnailURL || (selectedAlbum || selectedSingle)?.localArtworkURL || song.localArtworkURL;
    setArtworkUri(artworkUri);

    let dimensions = [];
    if (song.isVideo) {
      dimensions = song.dimensions || [];
    }

    if (selectedAlbum && selectedAlbum.songs.length > 0) {
      setInitialExpandedAlbumAndSong(selectedAlbum, selectedAlbum.songs[0]);
      setCenteredRelease(selectedAlbum);
    } else if (selectedSingle && selectedSingle.length > 0) {
      setCenteredRelease(selectedSingle);
    } else if (selectedVideo) {
      setCenteredRelease(selectedVideo);
    }

    const songName = song.isVideo ? song.VideoName : song.SongName;

    return {
      url: song.SongURL,
      dimensions: dimensions,
      isVideo: song.isVideo,
      artistName: song.ArtistName,
      popupProfileImage: profileImageUrl,
      username: song.username,
      songName: songName,
      artworkUrl: artworkUri,
      contentId: song.contentId,
      localArtworkURL: song.localArtworkURL,
      userId: song.userId,
      isAlbum: song.isAlbum,
      accountType: userData.accountType,
      albumName: selectedAlbum?.albumName || '',
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      otherReleases: { albums: userAlbums, singles: userSingles, videos: userVideos },
      albumSongs: selectedAlbum?.songs || [],
      single: selectedSingle,
      video: selectedVideo,
      timestamp: new Date().getTime(),
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
    };
  };

  const sortReleasesByTimestamp = (releases) => {
    const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
    return sortedReleases;
  };

  const SongItem = ({ item, onPress, contentType }) => {
    const [songArtworkUrl, setSongArtworkUrl] = useState(null);

    useEffect(() => {
      const fetchAndSetArtworkUrl = async () => {
        try {
          const artworkUrl = item?.ArtworkURL || item?.artworkURL;
          if (!artworkUrl) {
            console.error('No artwork URL provided');
            return;
          }

          let url;
          if (artworkUrl.startsWith('https://')) {
            url = artworkUrl;
          } else if (artworkUrl.startsWith('gs://')) {
            const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
            url = await getDownloadURL(ref(storage, imagePath));
          }

          const localUri = url;
          setSongArtworkUrl(localUri);
        } catch (error) {
          console.error('Failed to load and cache image', error);
        }
      };

      fetchAndSetArtworkUrl();
    }, [item]);

    if (!item) {
      return <div className="songArtworkPlaceholder" />;
    }

    return (
      <div style={styles.itemContainer}>
        <div style={styles.horizontalTouchable} onClick={() => onPress(item)}>
          {songArtworkUrl ? (
            <img src={songArtworkUrl} style={styles.songArtwork} alt="Artwork" />
          ) : (
            <div style={styles.songArtworkPlaceholder}>
              <div className="loadingIndicator" />
            </div>
          )}
        </div>
        <p style={styles.artistName} >{item.ArtistName}</p>
        <p style={styles.songName}>
          {item.isAlbum ? (item.albumName || 'Unknown Album') : (item.SongName || 'Unknown Song')}
        </p>
        <button style={styles.removeButton} onClick={() => confirmAndRemoveContent(item.contentId, item.isAlbum, item.isVideo)}>
          <span style={styles.removeButtonText}>Remove</span>
        </button>
      </div>
    );
  };

  const confirmAndRemoveContent = async (contentId, isAlbum, isVideo) => {
    let type = getType(isAlbum, isVideo);
    if (window.confirm("Are you sure? This action cannot be undone.")) {
      removeContent(contentId, type);
    }
  };

  const getType = (isAlbum, isVideo) => {
    if (isVideo && isAlbum) {
      return 'videoAlbums';
    }
    if (isVideo) {
      return 'videos';
    }
    if (isAlbum) {
      return 'albums';
    }
    return 'singles';
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        {mediaTypes.map((section, index) => (
          <div key={index} style={styles.horizontalSection}>
            <p style={styles.horizontalText}>{section.title}</p>
            <div style={styles.horizontalScroll}>
              {section.data.map((item) => (
                <SongItem key={item.contentId} item={item} onPress={handlePressBegins} />
              ))}
            </div>
          </div>
        ))}
        <div style={styles.horizontalSection}>
          <p style={styles.horizontalText}>ScreenSKN</p>
          <ScreenSKNList />
        </div>
      </div>
      <div style={{ height: bottomAreaHeight, backgroundColor: 'transparent' }} />
      <div style={styles.extraSpace}></div>
    </div>
  );
};

const styles = {
  fullScreen: {
    position: 'relative',
    width: '100%',
    height: '100vh', // Ensure it takes full viewport height
    overflow: 'auto',
    zIndex: 20,
  },
  extraSpace: {
    height: '200px',
  },
  scrollContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 20,
    overflowY: 'scroll',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  fullScreenImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  horizontalSection: {
    marginTop: '1.5vh',
    marginBottom: '1.5vh',
    overflowX: 'auto',
  },
  horizontalText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: '1vh',
    paddingLeft: '4.2vw',
    letterSpacing: '-0.4px',
  },
  horizontalTouchable: {
    width: '30vw',
    height: '30vw',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  horizontalScroll: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    gap: '10px',
  },
  songArtwork: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },
  songArtworkPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },
  itemContainer: {
    width: '30vw',
    alignItems: 'flex-start',
    marginRight: '10px',
  },
  artistName: {
    marginTop: '4px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: 'regular',
  },
  removeButton: {
    marginTop: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  removeButtonText: {
    color: '#62fbfa',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  loadingIndicator: {
    width: '50px',
    height: '50px',
    border: '5px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '5px solid #3498db',
    animation: 'spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
};

export default RemoveReleasesPage;
