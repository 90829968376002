import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from './AppContext';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';
import { ProfileComponentFollowing } from './ProfileComponentFollowing';

const CACHE_DURATION = 30000;

const ProfilesDisplayShareDATpost = ({ onShareDAT }) => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { showMediaPopup, followers, currentUser, setViewingProfile, userId2, setActivePopupMedia, activePopupMedia, setShowShareDATPopup, setShowSharePostPopup, setSharePostActive, selectedUserIdShare, setSelectedProfile, setSelectedUserIdShare } = useContext(AppContext);

  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const isLoadingRef = useRef(false);

  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
    }
  }, [activePopupMedia, isSelecting]);

  useEffect(() => {
    loadInitialProfiles();
  }, [followers]);

  async function loadInitialProfiles() {
    if (Array.isArray(followers)) {
      setIsLoading(true);
      let loadedProfiles = [];
      for (const userId of followers.slice(0, 9)) {
        if (userId !== userId2) {
          const userData = await getCachedUserDocMinimal(userId);
          if (userData) {
            loadedProfiles.push(userData);
          }
        }
      }
      setProfiles(loadedProfiles);
      setFilteredProfiles(loadedProfiles);
      setIsLoading(false);
    } else {
      console.warn("Followers data is not valid.");
    }
  }

  const getCachedUserDocMinimal = async (userId) => {
    let userDataCached = [];


    
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      
      const freshUserData = userDocSnap.data();
      userDataCached = {
        ...freshUserData,
        timestamp: new Date().getTime(),
      };



      return {
        userId: userId,
        voiceDropMuted: userDataCached.voiceDropMuted,
        accountType: userDataCached.accountType,
        name: userDataCached.name,
        username: userDataCached.username,
        profileImageUrl: userDataCached.profileImageUrl,
      };
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const loadMoreProfiles = async () => {
    if (isLoadingRef.current) return;
    if (!isSearching && profiles.length < followers.length) {
      if (profiles.length >= followers.length) return;

      isLoadingRef.current = true;
      setIsLoading(true);
      let newProfiles = [];
      let index = profiles.length;
      let loadedCount = 0;

      while (loadedCount < 9 && index < followers.length) {
        const userId = followers[index];
        index++;

        if (userId !== userId2 && !profiles.some((p) => p.userId === userId)) {
          const userData = await getCachedUserDocMinimal(userId);
          if (userData) {
            newProfiles.push(userData);
            loadedCount++;
          }
        }
      }

      setProfiles((prevProfiles) => {
        const existingIds = new Set(prevProfiles.map((p) => p.userId));
        const filteredNewProfiles = newProfiles.filter((p) => !existingIds.has(p.userId));
        return [...prevProfiles, ...filteredNewProfiles];
      });
      setFilteredProfiles((prevFilteredProfiles) => {
        const existingIds = new Set(prevFilteredProfiles.map((p) => p.userId));
        const filteredNewProfiles = newProfiles.filter((p) => !existingIds.has(p.userId));
        return [...prevFilteredProfiles, ...filteredNewProfiles];
      });
      setIsLoading(false);
      isLoadingRef.current = false;
    }
  };

  const handleProfileSelect = async (item) => {
    
    setSelectedProfile(item);
    const selectedUserId = item.userId;
    setSelectedUserIdShare(selectedUserId);
    setShowSharePostPopup(true);
    setSharePostActive(false);
  };

  const renderProfile = (item) => {
    if (!item) return null;
    
    return <ProfileComponentFollowing userProfile={item} onPress={() => handleProfileSelect(item)} />;
  };

  const handleSearch = async (text) => {
    setSearchText(text);
    if (!text.trim()) {
      setFilteredProfiles(profiles);
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    const lowercasedText = text.toLowerCase();
    const usersRef = collection(firestore, 'users');
    const searchQuery = query(usersRef, where('username_lower', '>=', lowercasedText), where('username_lower', '<=', lowercasedText + '\uf8ff'));
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(searchQuery);
      const searchResults = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData && followers.includes(doc.id)) {
          searchResults.push({
            userId: doc.id,
            voiceDropMuted: userData.voiceDropMuted,
            accountType: userData.accountType,
            name: userData.name,
            username: userData.username,
            profileImageUrl: userData.profileImageUrl,
          });
        }
      });
      setFilteredProfiles(searchResults);
    } catch (error) {
      console.error('Error searching profiles:', error);
      alert('Search Error: There was an error while searching. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div style={styles.container}>
      <input
        style={styles.searchInput}
        placeholder="Search..."
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <div style={styles.profileList}>
        {filteredProfiles.map((profile, index) => (
          <ProfileComponentFollowing key={index} userProfile={profile} onPress={() => handleProfileSelect(profile)} />
        ))}
        {isLoading && <div style={styles.activityIndicator}>Loading...</div>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: '100%',
    position: 'absolute',
alignSelf: 'center',
justifyContent: 'center',
    width: '100%',
  },
  searchInput: {
    alignSelf: 'center',
    height: '4vh',
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    borderColor: 'rgba(189, 252, 251, 0.3)',
    fontSize: '16px',
    borderWidth: '1px',
    border: '1px solid #6b92c2',
    margin: '10px',
    margin: '0 auto',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    paddingLeft: '10px',
    color: 'white',
    fontFamily: 'brhendrix',
  },
  activityIndicator: {
    marginTop: '5vh',
  },
  profileList: {
    display: 'flex',
    overflowX: 'scroll',
    paddingRight: '10vw',
  },
};

export { ProfilesDisplayShareDATpost };
