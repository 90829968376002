import React, { useState, useEffect, useRef, useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate, Route, Routes, Link, useLocation } from 'react-router-dom';
import Social from './Social';
import Music from './Music';
import MusicSeeAll from './MusicSeeAll';
import MovieView from './MovieView';
import VideoSeeAll from './VideoSeeAll';
import Library from './Library';
import Video from './Video';
import MYDAT from './MYDAT';
import { ProfilesDisplaySearchdat } from './ProfilesDisplaySearchdat';
import ProfilesDisplayShareCredits from './ProfilesDisplayShareCredits';
import PostScreen from './PostScreen';
import NotificationsScreen from './NotificationsScreen';
import SPNSKN from './SPNSKN';
import ViewPostScreen from './ViewPostScreen';
import messagingIcon from './assets/messagingIcon.png';
import notificationBell from './assets/notificationBell.png';
import postingIcon from './assets/postingIcon.png';
import searchdat from './assets/searchdat.png';
import searchdatHover from './assets/searchdatHover.png';
import ArtistIconCredits from './assets/ArtistIconCredits.png';
import PlayButton from './assets/PlayButton.png';
import PauseButton from './assets/PauseButton.png';
import NextSongIcon from './assets/nextSongIcon.png';
import PreviousSongIcon from './assets/previousSongIcon.png';
import unread from './assets/unreadMain.png';
import topFade from './assets/topFade.png';
import bottomFade from './assets/bottomFade.png';
import tinyCloseButton from './assets/tinyCloseButton.png'; // Import the close button image
import { AppContext } from './AppContext';
import SongDetails from './SongDetails';
import SongDetailsPlaying from './SongDetailsPlaying';
import MYDATprofileUser from './MYDATprofileUser';
import ScreenSKNowned from './ScreenSKNowned';

const AppWrapped = ({ user }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { hasUnread, hasUnreadNotifications, playPreviousSong, playNextSong, isPlaylist, handleCloseClick, creditsIOS, miniPlayer, togglePlayPause, isPlaying, setMiniPlayer, audioRef } = useContext(AppContext);
  const location = useLocation();
  const [contentBottomMargin, setContentBottomMargin] = useState(0);

  const [hoveredLink, setHoveredLink] = useState(null);
  const [isSearchHovered, setIsSearchHovered] = useState(false);
  const [isCreditsHovered, setIsCreditsHovered] = useState(false);
  const [isSpnsknHovered, setIsSpnsknHovered] = useState(false);

  const handlePressBegins = (miniPlayer) => {
    const clonedSong = JSON.parse(JSON.stringify(miniPlayer));
    navigate('/view-song-playing', { state: { song: clonedSong } });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');
    const path = location.pathname;

    if (source === 'app' && path.includes('/mydat/profile/')) {
      const username = path.split('/mydat/profile/')[1];
      if (username) {
        window.location.href = `myapp://profile/${username}`;
      }
    }
  }, [location]);

  useEffect(() => {
    if (miniPlayer?.ArtworkURL) {
      setContentBottomMargin('93%'); // Adjust this value based on the height of your miniPlayerBar
    } else {
      setContentBottomMargin('93%');
    }
  }, [miniPlayer]);

  const textRef = useRef(null);
  const iconRef = useRef(null);
  const centeredViewRef = useRef(null);

  const [contentWidth, setContentWidth] = useState(0);
  const [iconWidth, setIconWidth] = useState(0);

  useEffect(() => {
    if (textRef.current && iconRef.current) {
      setContentWidth(textRef.current.offsetWidth);
      setIconWidth(iconRef.current.offsetWidth);
    }
  }, [creditsIOS]);

  useEffect(() => {
    const updateCenteredView = () => {
      if (centeredViewRef.current) {
        const totalWidth = contentWidth + iconWidth;
        centeredViewRef.current.style.left = `calc(50% - ${totalWidth / 2}px)`;
      }
    };
    updateCenteredView();
    window.addEventListener('resize', updateCenteredView);
    return () => {
      window.removeEventListener('resize', updateCenteredView);
    };
  }, [contentWidth, iconWidth]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleCreditsClick = () => {
    navigate('/profiles-display-share-credits');
  };

  const handlePostingIconClick = () => {
    navigate('/post');
  };

  const handleNotificationClick = () => {
    navigate('/notifications');
  };

  const handleMessagingIconClick = () => {
    navigate('/spnskn');
  };

  const styles = {
    container: {
      backgroundColor: '#0e061e',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      marginLeft: '0',
      
    },
    header: {
      width: '100%',
      maxWidth: '480px',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px',
      backgroundColor: 'transparent',
    },
    signOutButton: {
      backgroundColor: '#62fbfa',
      border: 'none', 
      color: 'black',
      padding: '10px 20px',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '1rem',
    },
    centeredView: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: '30px',
      maxWidth: '480px',
      marginLeft: '5px',
      zIndex: 2,
      backgroundColor: 'transparent',
    },
    headerTop: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: '30px',
      maxWidth: '480px',
      width: '480px',
      zIndex: 2,
    },
    navbar: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      backgroundColor: 'transparent',
      padding: '5px 0',
      position: 'fixed',
      top: '70px',
      zIndex: 2,
      alignSelf: 'center',
      alignItems: 'center',
      maxWidth: '480px',
    },
    iconBar: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      backgroundColor: 'transparent',
      padding: '0px 0',
      position: 'fixed',
      top: '110px',
      alignSelf: 'center',
      maxWidth: '480px',
      zIndex: 2,
    },
    iconContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      cursor: 'pointer',
      width: '30px',
      height: '30px',
    },
    iconUnread: {
      position: 'absolute',
      top: '0px', // Adjust this to fine-tune positioning
      right: '20px', // Adjust this to fine-tune positioning
      width: '10px',
      height: '10px',
    },
    iconUnreadNotifications: {
      position: 'absolute',
      top: '4px', // Adjust this to fine-tune positioning
      right: '4px', // Adjust this to fine-tune positioning
      width: '10px',
      height: '10px',
    },
    navLink: {
      color: 'white',
      textDecoration: 'none',
      fontSize: '18px',
      fontFamily: 'brhendrix',
      transition: 'color 0.3s',
      zIndex: 2000000,
    },
    navLinkHovered: {
      color: '#62fbfa',
      zIndex: 2000000,
    },
    content: {
      maxWidth: '480px',
      alignSelf: 'center',
      marginTop: '140px',
      flex: 1,
      overflowY: 'none',
      width: '100%',
      position: 'fixed',
      height: contentBottomMargin,
      borderLeft: '2px solid #62fbfa',
      borderRight: '2px solid #62fbfa',
    },
    miniPlayerBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#0e061e',
      border: '1px solid rgba(23, 195, 250, 0.5)',
      padding: '10px',
    },
    artwork: {
      width: '50px',
      height: '50px',
      borderRadius: '5px',
    },
    songInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
      justifyContent: 'center',
    },
    songInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: '5px',
    },
    artistName: {
      color: 'white',
      fontSize: '1rem',
    },
    songName: {
      color: 'white',
      fontSize: '0.8rem',
    },
    controlButtons: {
      display: 'flex',
      alignItems: 'center',
    },
    playPauseButton: {
      width: '25px',
      height: '25px',
      cursor: 'pointer',
      marginRight: '15px',
    },
    searchLink: {
      color: '#62fbfa',
      display: 'flex',
      marginLeft: '0px',
      textDecoration: 'none',
      fontSize: '18px',
      backgroundColor: 'transparent',
      fontFamily: 'brhendrix',
    },
    spnsknLink: {
      color: 'white',
      display: 'flex',
      marginLeft: '230px',
      textDecoration: 'none',
      fontSize: '18px',
      backgroundColor: 'transparent',
      fontFamily: 'brhendrix',
      transition: 'color 0.3s',
    },
    spnsknLinkHovered: {
      color: '#62fbfa',
      display: 'flex',
      marginLeft: '230px',
      textDecoration: 'none',
      fontSize: '18px',
      backgroundColor: 'transparent',
      fontFamily: 'brhendrix',
    },
    iconSearch: {
      cursor: 'pointer',
      width: '55px',
      height: '30px',
    },
    closeButton: {
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      marginRight: '5px',
    },
    topFade: {
      width: '100%',
      height: '250px',
      position: 'fixed',
      top: 0,
      zIndex: 1,
      pointerEvents: 'none',
    },
    bottomFade: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      height: '150px',
      pointerEvents: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}></div>
      <div style={styles.headerTop}>
        <Link
          to="/profiles-display-searchdat"
          style={styles.searchLink}
          onMouseEnter={() => setIsSearchHovered(true)}
          onMouseLeave={() => setIsSearchHovered(false)}
        >
          <img
            src={isSearchHovered ? searchdatHover : searchdat}
            alt="Search"
            style={styles.iconSearch}
          />
        </Link>
        <div
          ref={centeredViewRef}
          style={styles.centeredView}
          onClick={handleCreditsClick}
          onMouseEnter={() => setIsCreditsHovered(true)}
          onMouseLeave={() => setIsCreditsHovered(false)}
        >
          <span
            ref={textRef}
            style={{
              color: isCreditsHovered ? '#62fbfa' : 'white',
              fontFamily: 'brhendrix',
              textAlign: 'right',
              justifyContent: 'center',
              alignSelf: 'center',
              marginRight: '0.7%',
              fontSize: '20px',
              cursor: 'pointer',
            }}
          >
            {creditsIOS}
          </span>
          <img
            ref={iconRef}
            src={ArtistIconCredits}
            alt="Artist Icon Credits"
            style={{
              opacity: 1,
              position: 'relative',
              bottom: '3%',
              marginBottom: '4px',
              width: '25px',
              height: '25px',
              resizeMode: 'contain',
              cursor: 'pointer',
            }}
          />
        </div>
        <Link
          to="/screensknowned"
          style={{
            ...styles.spnsknLink,
            ...(hoveredLink === 'spnskn' ? styles.spnsknLinkHovered : {}),
          }}
          onMouseEnter={() => setHoveredLink('spnskn')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          SPNSKN
        </Link>
      </div>
      <div style={styles.navbar}>
        <Link
          to="/social"
          style={{
            ...styles.navLink,
            ...(hoveredLink === 'social' ? styles.navLinkHovered : {}),
          }}
          onMouseEnter={() => setHoveredLink('social')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          SOCIAL
        </Link>
        <Link
          to="/music"
          style={{
            ...styles.navLink,
            ...(hoveredLink === 'music' ? styles.navLinkHovered : {}),
          }}
          onMouseEnter={() => setHoveredLink('music')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          MUSIC
        </Link>
        <Link
          to="/library"
          style={{
            ...styles.navLink,
            ...(hoveredLink === 'library' ? styles.navLinkHovered : {}),
          }}
          onMouseEnter={() => setHoveredLink('library')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          LIBRARY
        </Link>
        <Link
          to="/video"
          style={{
            ...styles.navLink,
            ...(hoveredLink === 'video' ? styles.navLinkHovered : {}),
          }}
          onMouseEnter={() => setHoveredLink('video')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          VIDEO
        </Link>
        <Link
          to="/mydat"
          style={{
            ...styles.navLink,
            ...(hoveredLink === 'mydat' ? styles.navLinkHovered : {}),
          }}
          onMouseEnter={() => setHoveredLink('mydat')}
          onMouseLeave={() => setHoveredLink(null)}
        >
          MYDAT
        </Link>
      </div>
      <div style={styles.iconBar}>
        <div style={styles.iconContainer}>
          <img src={notificationBell} alt="Notifications" style={styles.icon} onClick={handleNotificationClick} />
          {hasUnreadNotifications && (
            <img src={unread} style={styles.iconUnreadNotifications} />
          )}
        </div>
        <div style={styles.iconContainer}>
          <img src={postingIcon} alt="Post" style={styles.icon} onClick={handlePostingIconClick} />
        </div>
        <div style={styles.iconContainer}>
          <img src={messagingIcon} alt="Messages" style={styles.icon} onClick={handleMessagingIconClick} />
          {hasUnread && (
            <img src={unread} style={styles.iconUnread} />
          )}
        </div>
      </div>
      <div style={styles.content}>
        <Routes>
          <Route path="/social" element={<Social />} />
          <Route path="/music" element={<Music />} />
          <Route path="/musicseeall" element={<MusicSeeAll />} />
          <Route path="/videoseeall" element={<VideoSeeAll />} />
          <Route path="/movieview" element={<MovieView />} />
          <Route path="/library/*" element={<Library />} />
          <Route path="/video" element={<Video />} />
          <Route path="/mydat/*" element={<MYDAT />} />
          <Route path="/profiles-display-share-credits" element={<ProfilesDisplayShareCredits />} />
          <Route path="/post" element={<PostScreen />} />
          <Route path="/notifications" element={<NotificationsScreen />} />
          <Route path="/spnskn" element={<SPNSKN />} />
          <Route path="/viewpost" element={<ViewPostScreen />} />
          <Route path="/view-song" element={<SongDetails />} />
          <Route path="/view-song-playing" element={<SongDetailsPlaying />} />
          <Route path="/userprofile/:username" element={<MYDATprofileUser />} />
          <Route path="/profiles-display-searchdat" element={<ProfilesDisplaySearchdat />} /> {/* Added the search route */}
          <Route path="/screensknowned" element={<ScreenSKNowned />} />
          <Route path="/" element={<Music />} />
        </Routes>
      </div>
      <img src={topFade} style={styles.topFade} alt="Top Fade" />
      <img src={bottomFade} style={styles.bottomFade} alt="Bottom Fade" />
      {miniPlayer?.ArtworkURL && (
        <div style={styles.miniPlayerBar}>
          <div style={styles.songInfoContainer} onClick={() => handlePressBegins(miniPlayer)}>
            <img src={miniPlayer.ArtworkURL} alt="Artwork" style={styles.artwork} />
            <div style={styles.songInfo}>
              <span style={styles.artistName}>{miniPlayer.ArtistName}</span>
              <span style={styles.songName}>{miniPlayer.SongName}</span>
            </div>
          </div>
          <div style={styles.controlButtons}>
            {isPlaylist && (
              <img
                src={PreviousSongIcon}
                style={styles.playPauseButton}
                onClick={playPreviousSong}
              />
            )}
            <img
              src={isPlaying ? PauseButton : PlayButton}
              alt={isPlaying ? 'Pause' : 'Play'}
              style={styles.playPauseButton}
              onClick={togglePlayPause}
            />
            {isPlaylist && (
              <img
                src={NextSongIcon}
                style={styles.playPauseButton}
                onClick={playNextSong}
              />
            )}
            <img
              src={tinyCloseButton}
              alt="Close"
              style={styles.closeButton}
              onClick={handleCloseClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppWrapped;
