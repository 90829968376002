import React, { useState, useRef, useEffect, useContext } from 'react';

import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, getDoc, doc, getFirestore, limit } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { AppContext } from './AppContext';
import Modal from 'react-modal';


const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const bottomAreaHeight = height * 0.18;
const horizontalAreaSpacing = height * 0.015;
const scrollAreaTopPadding = height * 0.16;



const genresList = [
  { label: "Alternative", value: "Alternative" },
  { label: "Blues", value: "blues" },
  { label: "Classical", value: "classical" },
  { label: "Country", value: "country" },
  { label: "Electronic/Dance", value: "electronic_dance" },
  { label: "Folk", value: "folk" },
  { label: "Rap", value: "hip_hop_rap" },
  { label: "Indie", value: "indie" },
  { label: "Jazz", value: "jazz" },
  { label: "Latin", value: "latin" },
  { label: "Metal", value: "metal" },
  { label: "Pop", value: "pop" },
  { label: "R&B/Soul", value: "rnb_soul" },
  { label: "Reggae", value: "reggae" },
  { label: "Rock", value: "rock" },
  { label: "World/International", value: "world_international" }
];




const getCurrentUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.uid : null;
};


const ReleaseMusic = () => {

const { 

      currentUser, setUser,
      currentlyReleasing, setCurrentlyReleasing,
      showSuccessAlertReleasedMusic, setShowSuccessAlertReleasedMusic,
      showSuccessAlertReleasedMusicVideo, setShowSuccessAlertReleasedMusicVideo
} = useContext(AppContext);

const artworkInputRef = useRef(null);
const fileInputRef = useRef(null);




  const [albumMode, setAlbumMode] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({
    artistName: '',
    albumName: '',
    artworkURL: '',
    genres: [],
    tags: [],
    artistFeatures: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
  });
  const [editingAlbumDetails, setEditingAlbumDetails] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState('Alternative');
  const [selectedSubgenre, setSelectedSubgenre] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [producerName, setProducerName] = useState('');
  const [producerTrackName, setProducerTrackName] = useState('');
  const [songwriterName, setSongwriterName] = useState('');
  const [songDetails, setSongDetails] = useState([]);
  const [songTitle, setSongTitle] = useState('');
  const [songArtworkURL, setSongArtworkURL] = useState('');
  const [songUri, setSongUri] = useState('');
  const [titleInput, setTitleInput] = useState('');
  const [lyricsInput, setLyricsInput] = useState('');

  const [showCollaborators, setShowCollaborators] = useState(false);
  const [collaborators, setCollaborators] = useState([
    { name: '', userId: '', percentage: '', searchQuery: '', searchResults: [], isSearching: false }
  ]);

const handleToggleCollaborators = () => {
  setShowCollaborators(!showCollaborators);
};



const handleAddCollaborator = () => {
  setCollaborators([...collaborators, { name: '', email: '', percentage: '' }]);
};

const handleRemoveCollaborator = (index) => {
  const updatedCollaborators = collaborators.filter((_, i) => i !== index);
  setCollaborators(updatedCollaborators);
};



const [searchQueryArtistName, setSearchQueryArtistName] = useState('');
const [searchResultsArtistName, setSearchResultsArtistName] = useState([]);
const [isSearchingArtistName, setIsSearchingArtistName] = useState(false);

const [searchQueryProducers, setSearchQueryProducers] = useState('');
const [searchResultsProducers, setSearchResultsProducers] = useState([]);
const [isSearchingProducers, setIsSearchingProducers] = useState(false);

const [searchQuerySongwriters, setSearchQuerySongwriters] = useState('');
const [searchResultsSongwriters, setSearchResultsSongwriters] = useState([]);
const [isSearchingSongwriters, setIsSearchingSongwriters] = useState(false);

const [searchQueryCollaborator, setSearchQueryCollaborator] = useState('');
const [searchResultsCollaborator, setSearchResultsCollaborator] = useState([]);
const [isSearchingCollaborator, setIsSearchingCollaborator] = useState(false);

const [searchQueryArtistFeatures, setSearchQueryArtistFeatures] = useState('');
const [searchResultsArtistFeatures, setSearchResultsArtistFeatures] = useState([]);
const [isSearchingArtistFeatures, setIsSearchingArtistFeatures] = useState(false);

const [searchQueryRecordLabels, setSearchQueryRecordLabels] = useState('');
const [searchResultsRecordLabels, setSearchResultsRecordLabels] = useState([]);
const [isSearchingRecordLabels, setIsSearchingRecordLabels] = useState(false);



const [formMode, setFormMode] = useState('add');

  const [displayedArtwork, setDisplayedArtwork] = useState('');
  const [isSearching, setIsSearching] = useState(false);
const firestore = getFirestore();
const usersRef = collection(firestore, 'users');
const [songDisplayName, setSongDisplayName] = useState('');

const [addingSongs, setAddingSongs] = useState(false);
const [selectedTrack, setSelectedTrack] = useState(1);
const [currentTrackNumber, setCurrentTrackNumber] = useState(1);
const [currentForm, setCurrentForm] = useState('Album Details');


const [currentTrack, setCurrentTrack] = useState(1);
const [currentTrackDetails, setCurrentTrackDetails] = useState({
  title: '',
  artistName: '',
  artistFeatures: [],
  artworkURL: '',
  songURL: '',
  genres: [],
  tags: [],
  producers: [],
  songwriters: [],
  recordLabels: [],
  lyrics: '',
  songDisplayName: '',
  trackNumber: null,
});
    

const [tracks, setTracks] = useState([]);
const [trackDetails, setTrackDetails] = useState({ title: '', artworkURL: '', songURL: '' });
const [currentPickerSelection, setCurrentPickerSelection] = useState('Album Details');

const [albumDetailsVisible, setAlbumDetailsVisible] = useState(false);
const [trackDetailsVisible, setTrackDetailsVisible] = useState(new Array(10).fill(false));




const pickerSelectStyles = {
  input: {
    color: 'white',
    fontFamily: 'brhendrix',
    fontSize: `${width * 0.038}px`,
  },
  placeholder: {
    color: 'white',
    fontFamily: 'brhendrix',
  },
};


  const storage = getStorage();
  const functions = getFunctions(); 
  const releaseMusic = httpsCallable(functions, 'releaseMusic');




  const checkIfSquare = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.width === img.height) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };


  const pickSong = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const reader = new FileReader();
      reader.onload = () => {
        const fileUrl = reader.result;
        setCurrentTrackDetails(prevDetails => ({
          ...prevDetails,
          songFile: file,  // Store the file as a Blob
          songDisplayName: fileName,
          songURL: fileUrl, // Store the base64 representation for display purposes
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  
  


  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const uploadSongFile = async (fileBlob, fileName, retries = 3, delayTime = 3000) => {
    const storageRef = ref(storage, `songs/${fileName}`);
    
    const attemptUpload = async (retryCount) => {
      try {
        const uploadTask = uploadBytesResumable(storageRef, fileBlob);
  
        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // You can add progress updates here if needed
            },
            (error) => {
              console.error("Upload failed:", error);
  
              // Check for additional details in the error payload
              if (error.serverResponse) {
                try {
                  const errorPayload = JSON.parse(error.serverResponse);
                  console.error('Server response:', errorPayload);
                } catch (e) {
                  console.error('Error parsing server response:', e);
                }
              }
  
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              });
            }
          );
        });
  
        return await getDownloadURL(uploadTask.snapshot.ref);
      } catch (error) {
        if (retryCount > 0) {
          console.log(`Retrying upload (${retries - retryCount + 1}/3)...`);
          await delay(delayTime);
          return attemptUpload(retryCount - 1);
        } else {
          console.error("Upload failed after 3 retries");
          alert("Low internet connection, please try again");
          throw error; // Re-throw to be caught by caller
        }
      }
    };
  
    return attemptUpload(retries);
  };
  






  const toggleMode = (mode) => {
    setAlbumMode(mode === 'album');
  };
  
  const pickArtwork = async (event) => {
    const file = event.target.files[0];
    if (file) {
      
      const isSquare = await checkIfSquare(file);
      if (!isSquare) {
        alert('Please use a square image.');
        return;
      }
      const fileUrl = URL.createObjectURL(file);
      setAlbumDetails({ ...albumDetails, artworkFile: file, artworkURL: fileUrl });
      
    }
  };
  
  
  const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      default:
        return 'application/octet-stream';
    }
  };
  

  const uploadFile = async (file, folderName = 'photos/') => {
    if (!file) {
      console.error('No file provided for upload');
      return;
    }
  
    const fileRef = ref(storage, `${folderName}${new Date().toISOString()}`);
    const metadata = {
      contentType: file.type || 'image/png', // Use the correct MIME type
    };
  
    try {
      
      
  
      const uploadTask = uploadBytesResumable(fileRef, file, metadata);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            
          },
          (error) => {
            console.error('Error during photo upload:', error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            resolve(downloadURL);
          }
        );
      });
    } catch (error) {
      console.error('Error during photo upload:', error);
      throw error;
    }
  };
  
  
  
  









const handleAddGenreSelect = (event) => {
  setSelectedGenre(event.target.value);
};

const addGenre = () => {
  if (selectedGenre) {
    if (!albumDetails.genres.includes(selectedGenre) && albumDetails.genres.length < 1) {
      setAlbumDetails(prevState => ({
        ...prevState,
        genres: [...prevState.genres, selectedGenre]
      }));
    }
    setSelectedGenre(null);
  }
};


  const addTag = () => {
    if (selectedTag && !albumDetails.tags.includes(selectedTag) && albumDetails.tags.length < 50) {
      setAlbumDetails(prevState => ({ ...prevState, tags: [...prevState.tags, selectedTag] }));
    }
  };

  const removeGenre = (genre) => {
    setAlbumDetails(prevState => ({ ...prevState, genres: prevState.genres.filter(g => g !== genre) }));
  };

  const removeTag = (tag) => {
    setAlbumDetails(prevState => ({ ...prevState, tags: prevState.tags.filter(t => t !== tag) }));
  };

const addProducer = () => {
  const input = searchQueryProducers.trim();

  if (albumDetails.producers.length >= 10) {
    return;
  }

  if (input) {
    const newProducer = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      producers: [...prevState.producers, newProducer]
    }));
  } else {
  }

  setSearchQueryProducers('');
};


const removeProducer = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    producers: prevState.producers.filter(producer => producer.id !== id)
  }));
};


const addSongwriter = () => {
  const input = searchQuerySongwriters.trim();

  if (albumDetails.songwriters.length >= 10) {
    return;
  }

  if (input) {
    const newSongwriter = {
      tag: input,
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      songwriters: [...prevState.songwriters, newSongwriter]
    }));
  } else {
  }

  setSearchQuerySongwriters('');
};

const removeSongwriter = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    songwriters: prevState.songwriters.filter(songwriter => songwriter.id !== id)
  }));
};




const addArtistFeature = () => {
  const input = searchQueryArtistFeatures.trim();

  if (albumDetails.artistFeatures.length >= 10) {
    return;
  }

  if (input) {
    const newArtistFeature = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      artistFeatures: [...prevState.artistFeatures, newArtistFeature]
    }));
  } else {
  }

  setSearchQueryArtistFeatures(''); 
};

    const removeArtistFeature = (id) => {
      setAlbumDetails(prevState => ({
        ...prevState,
        artistFeatures: prevState.artistFeatures.filter(artistFeature => artistFeature.id !== id)
      }));
    };

const removeArtistName = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    artistName: null 
  }));
};



const addRecordLabel = () => {
  const input = searchQueryRecordLabels.trim();

  if (albumDetails.recordLabels.length >= 6) {
    return;
  }

  if (input) {
    const newRecordLabel = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      recordLabels: [...prevState.recordLabels, newRecordLabel]
    }));
  } else {
  }

  setSearchQueryRecordLabels(''); 
};

const removeRecordLabel = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    recordLabels: prevState.recordLabels.filter(recordLabel => recordLabel.id !== id)
  }));
};



const newTrackTemplate = {
  title: '',
  artworkURL: '',
  songURL: '',
  producers: [],
  songwriters: [],
  artistFeatures: [],
  recordLabels: [],
  lyrics: ''

};



const handleAddTrack = () => {
  if (currentForm === 'Add Track') {
    const newTrackNumber = tracks.length + 1;
    const newTrack = { ...currentTrackDetails, trackNumber: newTrackNumber };
    setTracks([...tracks, newTrack]);

    setCurrentTrackDetails({
      title: '',
      artworkURL: '',
      songURL: '',
      producers: [],
      songwriters: [],
      artistFeatures: [],
      recordLabels: [],
      lyrics: '',
      trackNumber: newTrackNumber + 1,
    });
  } else {
    const updatedTracks = tracks.map(track => {
      if (track.trackNumber === currentTrackDetails.trackNumber) {
        return currentTrackDetails;
      }
      return track;
    });

    setTracks(updatedTracks);
    setCurrentForm('Add Track');
    setCurrentTrackDetails({
      title: '',
      artworkURL: '',
      songURL: '',
      producers: [],
      songwriters: [],
      artistFeatures: [],
      recordLabels: [],
      lyrics: '',
      trackNumber: tracks.length + 1,
    });
  }
};





useEffect(() => {
  
}, [currentTrackDetails]);

useEffect(() => {
  setTitleInput(currentTrackDetails.title);
}, [currentTrackDetails.title]);

const handleTitleChange = (event) => {
  const text = event.target.value; // Get the value from the event
  setTitleInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, title: text }));
};

useEffect(() => {
  setLyricsInput(currentTrackDetails.lyrics);
}, [currentTrackDetails.lyrics]);

const handleLyricsChange = (text) => {
  setLyricsInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, lyrics: text }));
};



const formatArtistWithFeatures = (artistName, artistFeatures) => {
  if (artistFeatures && artistFeatures.length > 0) {
    const featureNames = artistFeatures.map(feature => feature.tag);
    return `${artistName} ft. ${featureNames.join(', ')}`;
  } else {
    return artistName;
  }
};



const renderTrackModules = () => {
  if (tracks.length === 0) return null;

  return tracks.map((track, index) => (
    <div
      key={track.trackNumber}
      onClick={() => {
        setCurrentForm(`Edit Track ${track.trackNumber}`);
        setEditingAlbumDetails(false);
        setCurrentTrackDetails(JSON.parse(JSON.stringify(track)));
        setFormMode('edit');
      }}
      style={styles.albumInfoContainer}
    >
      <img
        src={track.artworkURL || albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Track Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Track ${track.trackNumber}:\n${track.title}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${formatArtistWithFeatures(track.artistName.tag, track.artistFeatures.tag)}`}
      </p>
      <p style={styles.albumInfoText} >
        {`Song File:\n${track.songDisplayName || 'No file selected'}`}
      </p>
      <p style={styles.albumInfoText} >
        {`Produced By:\n${track.producers.map(p => p.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText} >
        {`Written By:\n${track.songwriters.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText} >
        {`Record Label:\n${track.recordLabels.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText} >
        {`Genre:\n${track.genres?.join(', ')}`}
      </p>
      {/* Add other details as needed */}
    </div>
  ));
};





const [isReviewing, setIsReviewing] = useState(false);







const handleAlbumDetailsSubmit = () => {
  addProducer();
  addArtistFeature();
  addSongwriter();
  addRecordLabel();
  addGenre();
  setFormMode('add');
  setAlbumDetailsVisible(true);
  setAddingSongs(true);

  setCurrentForm('Add Track');
};



const handleSaveTrackChanges = () => {
  const updatedTracks = tracks.map(track => {
    if (track.trackNumber === currentTrackDetails.trackNumber) {
      return { ...currentTrackDetails };
    }
    return track;
  });

  setTracks(updatedTracks);


  setFormMode('add');
  setCurrentForm('Add Track');
};




const handleSaveSingleChanges = () => {
  const updatedTracks = tracks.map(track => {
    if (track.trackNumber === currentTrackDetails.trackNumber) {
      return { ...currentTrackDetails };
    }
    return track;
  });

  setTracks(updatedTracks);


};





const removeTrack = (trackNumber) => {
  const updatedTracks = [...tracks];

  const indexToRemove = updatedTracks.findIndex(t => t.trackNumber === trackNumber);

  if (indexToRemove !== -1) {
    updatedTracks.splice(indexToRemove, 1);

    updatedTracks.forEach((track, index) => {
      if (track.trackNumber > trackNumber) {
        updatedTracks[index].trackNumber -= 1;
      }
    });

    setTracks(updatedTracks);

    if (currentTrackNumber === trackNumber) {
      setCurrentTrackNumber(updatedTracks.length > 0 ? Math.min(trackNumber, updatedTracks.length) : 1);
    }
  }
};

async function testUploadSong() {
  try {
    const firebasePath = `single_songs/${currentTrackDetails.title.replace(/[^a-zA-Z0-9]/g, '_')}`;
    const uploadResult = await uploadSongFileToFirebase(currentTrackDetails.songURL, firebasePath);
    
  } catch (error) {
    console.error('Test upload failed:', error);
  }
}

function base64ToArrayBuffer(base64) {
  const binaryString = global.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

async function uploadSongFileToFirebase(file, firebasePath) {
  try {
    

    
    const blob = new Blob([file], { type: file.type });

    
    const storageRef = ref(storage, firebasePath);
    

    
    const uploadTask = uploadBytesResumable(storageRef, blob);

    await new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
        },
        (error) => {
          console.error("Upload failed:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            
            resolve(downloadURL);
          });
        }
      );
    });

    

    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    

    return downloadURL;
  } catch (error) {
    console.error('Error uploading song file:', error);
    throw error;
  }
}



const handleAddProducer = () => {
  const input = searchQueryProducers.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.producers.some(p => p.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        producers: [...prevDetails.producers, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryProducers('');
};

const handleAddSongwriter = () => {
  const input = searchQuerySongwriters.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.songwriters.some(s => s.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        songwriters: [...prevDetails.songwriters, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQuerySongwriters('');
};

const handleAddArtistFeature = () => {
  const input = searchQueryArtistFeatures.trim();

  if (input) {
    if (!currentTrackDetails.artistFeatures.some(s => s.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        artistFeatures: [...prevDetails.artistFeatures, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryArtistFeatures('');
};


const handleAddRecordLabel = () => {
  const input = searchQueryRecordLabels.trim();

  if (input) {
if (!currentTrackDetails.recordLabels.some(r => r.tag === input)) {

      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        recordLabels: [...prevDetails.recordLabels, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryRecordLabels('');
};


const [alertMessage, setAlertMessage] = useState('');


const handleSubmit = async () => {
  try {
    setCurrentlyReleasing(true);
    
    const userId = getCurrentUserId();
    

    const extractInfoFromTag = (tag) => {
      const parts = tag.split(' - ');
      return {
        username: parts.length > 1 ? parts[0] : null,
        artistName: parts.length > 1 ? parts[1] : tag
      };
    };

    let updateContextData;

    if (albumMode) {
      

      if (!albumDetails.artistName || !albumDetails.albumName || !albumDetails.artworkURL || tracks.length < 4 || !albumDetails.genres.length || !albumDetails.producers.length || !albumDetails.songwriters.length || !albumDetails.recordLabels.length) {
        
        setCurrentlyReleasing(false);
        setAlertMessage('Please fill in all required fields and ensure there are at least 4 tracks.');
        return;
      }

      const sanitizedAlbumName = albumDetails.albumName.replace(/[^a-zA-Z0-9]/g, '_');
      
      
      const albumArtworkURL = await uploadFile(albumDetails.artworkFile, 'albumArt/');


      

      const { username: albumArtistUsername } = extractInfoFromTag(albumDetails.artistName.tag);

      const filteredCollaborators = collaborators.filter(collab => collab.name && collab.percentage);

      const albumData = {
        username: albumArtistUsername,
        isAlbum: true,
        userId,
        ArtistName: extractInfoFromTag(albumDetails.artistName.tag).artistName,
        ArtistNameLower: extractInfoFromTag(albumDetails.artistName.tag).artistName.toLowerCase(),
        artistId: albumDetails.artistName.id,
        albumName: albumDetails.albumName,
        albumNameLower: albumDetails.albumName.toLowerCase(),
        ArtworkURL: albumArtworkURL,
        genres: albumDetails.genres,
        artistFeatures: albumDetails.artistFeatures,
        producers: albumDetails.producers,
        songwriters: albumDetails.songwriters,
        recordLabels: albumDetails.recordLabels,
        collaborators: filteredCollaborators,
        tracks: []
      };

      
      const songsData = await Promise.all(tracks.map(async track => {
        if (!track.title || !track.genres.length || !track.producers.length || !track.songwriters.length || !track.recordLabels.length) {
          throw new Error("All track fields except artist features are required.");
        }
        
        let trackArtworkURL = albumArtworkURL;
        if (track.artworkURL && track.artworkURL !== albumDetails.artworkURL) {
          const artworkURL = await uploadFile(currentTrackDetails.artworkBlob, 'songArt')

        }
        
        const songURL = await uploadSongFile(track.songFile, track.title);



        const { username: trackArtistUsername } = extractInfoFromTag(track.artistName.tag);

        albumData.tracks.push({ title: track.title, artwork: trackArtworkURL });

        return {
          username: trackArtistUsername,
          isAlbum: false,
          userId,
          artistId: track.artistName.id,
          ArtistName: extractInfoFromTag(track.artistName.tag).artistName,
          ArtistNameLower: extractInfoFromTag(track.artistName.tag).artistName.toLowerCase(),
          SongName: track.title,
          songNameLower: track.title.toLowerCase(),
          ArtworkURL: trackArtworkURL,
          SongURL: songURL,
          genres: track.genres,
          artistFeatures: track.artistFeatures,
          producers: track.producers,
          songwriters: track.songwriters,
          recordLabels: track.recordLabels,
          lyrics: track.lyrics
        };
      }));

      
      await releaseMusic({ album: albumData, songs: songsData });
      
      updateContextData = { albums: albumData };
    } else {
      

      if (!currentTrackDetails.artistName || !currentTrackDetails.title || !currentTrackDetails.artworkURL || !currentTrackDetails.genres.length || !currentTrackDetails.producers.length || !currentTrackDetails.songwriters.length || !currentTrackDetails.recordLabels.length) {
        
        setAlertMessage('Please fill in all required fields.');
        setCurrentlyReleasing(false);
        return;
      }

      const artworkURL = await uploadFile(currentTrackDetails.artworkFile, 'songArt/');

      const songURL = await uploadSongFile(currentTrackDetails.songFile, currentTrackDetails.title);



      const { username: singleArtistUsername } = extractInfoFromTag(currentTrackDetails.artistName.tag);

      const filteredCollaborators = collaborators.filter(collab => collab.name && collab.percentage);

      const singleSongData = {
        username: singleArtistUsername,
        isAlbum: false,
        userId,
        ArtistName: extractInfoFromTag(currentTrackDetails.artistName.tag).artistName,
        ArtistNameLower: extractInfoFromTag(currentTrackDetails.artistName.tag).artistName.toLowerCase(),
        artistId: currentTrackDetails.artistName.id,
        SongName: currentTrackDetails.title,
        songNameLower: currentTrackDetails.title.toLowerCase(),
        ArtworkURL: artworkURL,
        SongURL: songURL,
        genres: currentTrackDetails.genres,
        artistFeatures: currentTrackDetails.artistFeatures,
        producers: currentTrackDetails.producers,
        songwriters: currentTrackDetails.songwriters,
        recordLabels: currentTrackDetails.recordLabels,
        lyrics: currentTrackDetails.lyrics,
        collaborators: filteredCollaborators,
        trackNumber: 1
      };
      
      const releaseResponse = await releaseMusic({ songs: [singleSongData] });
      
      updateContextData = { singles: singleSongData };
    }






    setCurrentlyReleasing(false);
    alert('Music Released Successfully.');
    resetForm();
  setIsReviewing(false);
  } catch (error) {
    console.error('Error with the music release process:', error);
    setCurrentlyReleasing(false);
  }
};

function resetForm() {

  setAlbumDetails((prevState) => ({
    ...prevState, // Spread the previous state
    albumName: '',
    artworkURL: '',
    genres: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
    artistFeatures: [],
    tags: []
  }));

  setCurrentTrackDetails((prevState) => ({
    ...prevState, // Spread the previous state
    title: '',
    artworkURL: '',
    genres: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
    artistFeatures: [],
    tags: [],
    lyrics: ''
  }));

  // Assuming setTracks is a setter for track details, resetting it as before
  setTracks([]);
}








useEffect(() => {
  
}, [currentUser]);




useEffect(() => {
  if (currentUser) {
    setAlbumDetails(prev => ({
      ...prev,
      artistName: {
        tag: `@${currentUser.username} - ${currentUser.name}`,
        id: currentUser.uid,
      },
    }));

    setCurrentTrackDetails(prev => ({
      ...prev,
      artistName: {
        tag: `@${currentUser.username} - ${currentUser.name}`,
        id: currentUser.uid,
      },
    }));
  }
}, [currentUser]);



    
const selectProducer = (user) => {
  if (albumDetails.producers.length >= 20) {
    return;
  }

  const newProducer = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    producers: [...prevState.producers, newProducer]
  }));

  setSearchQueryProducers('');
  setIsSearchingProducers(false);
};


const selectSongwriter = (user) => {
  if (albumDetails.producers.length >= 20) {
    return;
  }

  const newSongwriter = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    songwriters: [...prevState.songwriters, newSongwriter]
  }));

  setSearchQuerySongwriters('');
  setIsSearchingSongwriters(false);
};




const selectArtistFeature = (user) => {
  if (albumDetails.artistFeatures.length >= 20) {
    return;
  }

  const newArtistFeature = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    artistFeatures: [...prevState.artistFeatures, newArtistFeature]
  }));

  setSearchQueryArtistFeatures('');
  setIsSearchingArtistFeatures(false);
};

const selectArtistName = (user) => {
  if (albumDetails.artistName) {
    return;
  }

  const newArtistName = {
    tag: `@${currentUser.username} - ${currentUser.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    artistName: newArtistName
  }));

  setSearchQueryArtistName('');
  setIsSearchingArtistName(false);
};



const selectRecordLabel = (user) => {
  if (albumDetails.recordLabels.length >= 6) {
    return;
  }

  const newRecordLabel = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    recordLabels: [...prevState.recordLabels, newRecordLabel]
  }));

  setSearchQueryRecordLabels('');
  setIsSearchingRecordLabels(false);
};



const getPickerItems = () => {
  const items = [{ label: 'Album Details', value: 'Album Details' }];
  tracks.forEach((track, index) => {
    const label = track.name || `Track ${index + 1}`;
    items.push({ label, value: label });
  });
  return items;
};



useEffect(() => {
  if (currentForm === 'Add Track') {
    setCurrentTrackDetails({
      title: '',
      artworkURL: albumDetails.artworkURL,
      artworkFile: albumDetails.artworkFile,
      songURL: '',
      genres: albumDetails.genres || [],
      tags: albumDetails.tags || [],
      producers: albumDetails.producers,
      artistFeatures: albumDetails.artistFeatures,
      songwriters: albumDetails.songwriters,
      recordLabels: albumDetails.recordLabels,
      lyrics: '',
      trackNumber: tracks.length + 1,
      artistName: albumDetails.artistName ? { 
        id: albumDetails.artistName.id, 
        tag: albumDetails.artistName.tag 
      } : null,
    });
  }
}, [currentForm, tracks.length, albumDetails]);






    
useEffect(() => {
  const search = async () => {
    if (searchQueryProducers.startsWith("@") && searchQueryProducers.length > 1) {
      setIsSearchingProducers(true);
      const searchValue = searchQueryProducers.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to use 'username_lower' field for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsProducers(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsProducers([]);
      }
    } else {
      setIsSearchingProducers(false);
      setSearchResultsProducers([]);
    }
  };

  if (searchQueryProducers) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQueryProducers]);



    
useEffect(() => {
  const search = async () => {
    if (searchQuerySongwriters.startsWith("@") && searchQuerySongwriters.length > 1) {
      setIsSearchingSongwriters(true);
      const searchValue = searchQuerySongwriters.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to target 'username_lower' for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsSongwriters(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsSongwriters([]);
      }
    } else {
      setIsSearchingSongwriters(false);
      setSearchResultsSongwriters([]);
    }
  };

  if (searchQuerySongwriters) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQuerySongwriters]);




const selectCollaborator = (user, index) => {
  setCollaborators(prevCollaborators => {
    return prevCollaborators.map((collaborator, idx) => {
      if (idx === index) {
        // Update the selected collaborator with the userId
        return {
          ...collaborator,
          name: `@${user.username} (${user.name})`, // Format the selected collaborator's name
          userId: user.userId, // Add the userId of the selected collaborator
          searchQuery: '', // Clear the search query
          isSearching: false, // Stop the search
          searchResults: [], // Clear the search results
        };
      } else {
        // Ensure all other collaborators' search results are cleared
        return {
          ...collaborator,
          searchQuery: '',
          isSearching: false,
          searchResults: []
        };
      }
    });
  });

  // Optionally, clear any global search input and search results state
  setSearchQueryCollaborator('');
  setSearchResultsCollaborator([]);
  setIsSearchingCollaborator(false);
};


const [searchQueries, setSearchQueries] = useState([]);

const handleCollaboratorChange = (index, field, value) => {
  setCollaborators(prevCollaborators => {
    const updatedCollaborators = prevCollaborators.map((collaborator, idx) => {
      if (idx === index) {
        // Update the collaborator being edited
        return {
          ...collaborator,
          [field]: value,
          isSearching: field === 'searchQuery' ? true : collaborator.isSearching,
        };
      } else if (field === 'searchQuery') {
        // Clear search results for other collaborators
        return {
          ...collaborator,
          searchQuery: '',
          searchResults: [],
          isSearching: false,
        };
      }
      return collaborator;
    });

    // Log the updated collaborators object to see its structure
    console.log('Updated collaborators:', updatedCollaborators);
    return updatedCollaborators;
  });

  if (field === 'searchQuery') {
    setSearchQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      updatedQueries[index] = value;
      return updatedQueries;
    });
  }
};




useEffect(() => {
  const searchCollaborators = async () => {
    const currentSearchQueryIndex = searchQueries.findIndex(searchQuery => searchQuery.startsWith("@") && searchQuery.length > 1);

    // Clear search results for all other collaborators before starting a new search
    setCollaborators(prevCollaborators => {
      return prevCollaborators.map((collaborator, idx) => {
        if (idx !== currentSearchQueryIndex) {
          return {
            ...collaborator,
            searchResults: [],
            isSearching: false,
          };
        }
        return collaborator;
      });
    });

    const searchPromises = searchQueries.map(async (searchQuery, index) => {
      if (searchQuery.startsWith("@") && searchQuery.length > 1) {
        const searchValue = searchQuery.substring(1).toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef,
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );

        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setCollaborators(prevCollaborators => {
            return prevCollaborators.map((collaborator, idx) => {
              if (idx === index) {
                return {
                  ...collaborator,
                  searchResults: users,
                  isSearching: false,
                };
              }
              return collaborator;
            });
          });

        } catch (error) {
          console.error("Error searching users:", error);
          setCollaborators(prevCollaborators => {
            return prevCollaborators.map((collaborator, idx) => {
              if (idx === index) {
                return {
                  ...collaborator,
                  searchResults: [],
                  isSearching: false,
                };
              }
              return collaborator;
            });
          });
        }
      }
    });

    await Promise.all(searchPromises);
  };

  if (searchQueries.some(searchQuery => searchQuery.startsWith("@") && searchQuery.length > 1)) {
    searchCollaborators();
  }
}, [searchQueries]);




useEffect(() => {
  const search = async () => {
    if (searchQueryArtistFeatures.startsWith("@") && searchQueryArtistFeatures.length > 1) {
      setIsSearchingArtistFeatures(true);
      const searchValue = searchQueryArtistFeatures.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to use 'username_lower' field for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsArtistFeatures(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsArtistFeatures([]);
      }
    } else {
      setIsSearchingArtistFeatures(false);
      setSearchResultsArtistFeatures([]);
    }
  };

  if (searchQueryArtistFeatures) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQueryArtistFeatures]);

useEffect(() => {
  const search = async () => {
    // Check if the search query starts with "@" and has more than one character
    if (searchQueryArtistName.startsWith("@") && searchQueryArtistName.length > 1) {
      setIsSearchingArtistName(true);
      // Extract the search value, omitting the "@" symbol and converting to lowercase
      const searchValue = searchQueryArtistName.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Update the query to target the 'username_lower' field for a case-insensitive search
      // and limit the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsArtistName(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsArtistName([]);
      }
    } else {
      // Reset the search if the conditions aren't met
      setIsSearchingArtistName(false);
      setSearchResultsArtistName([]);
    }
  };

  // Debounce the search to reduce the frequency of Firestore queries
  if (searchQueryArtistName) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Wait 300ms after the user stops typing

    // Cleanup function to cancel the timeout when the component unmounts or the effect reruns
    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryArtistName]);




useEffect(() => {
  const search = async () => {
    // Check if the query starts with "@" and has more than one character
    if (searchQueryRecordLabels.startsWith("@") && searchQueryRecordLabels.length > 1) {
      setIsSearchingRecordLabels(true);
      const searchValue = searchQueryRecordLabels.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to target a lowercase field for case-insensitive search,
      // here I'm assuming 'username_lower' is the field you're interested in
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the results to improve efficiency
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsRecordLabels(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsRecordLabels([]);
      }
    } else {
      setIsSearchingRecordLabels(false);
      setSearchResultsRecordLabels([]);
    }
  };

  // Debounce the search to prevent excessive Firestore queries
  if (searchQueryRecordLabels) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // 300ms delay

    // Cleanup to cancel the timeout when the component unmounts or the input changes
    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryRecordLabels]);




const renderSingleSongForm = () => {
  return renderTrackFormContent(currentTrackDetails);
};




const renderAlbumDetailsModule = () => {
  if (!albumDetailsVisible) return null;

  return (
    <div
      onClick={() => {
        setCurrentForm('Album Details');
        setEditingAlbumDetails(true);
      }}
      style={styles.albumInfoContainer}
    >
      <img
        src={albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Album Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Album Name:\n${albumDetails.albumName}`}
      </p>
      {/* Check if albumDetails.artistName exists before trying to access its properties */}
      <p style={styles.albumInfoText}>
        {`Artist:\n${albumDetails.artistName ? albumDetails.artistName.tag : 'Not selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Features:\n${albumDetails.artistFeatures.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${albumDetails.producers.map(producer => producer.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${albumDetails.songwriters.map(songwriter => songwriter.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${albumDetails.recordLabels.map(recordLabel => recordLabel.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${albumDetails.genres.join(', ')}`}
      </p>
    </div>
  );
};






  const handleBlur = () => {
  };







const renderTrackForm = () => {
  if (currentForm === 'Add Track') {

    return renderTrackFormContent(currentTrackDetails);
  } else if (currentForm === 'Album Details') {
    return renderAlbumDetailsForm();
  } else {
    const trackNumber = parseInt(currentForm.split(' ')[2], 10);
    const trackToEdit = tracks.find(track => track.trackNumber === trackNumber);
    return renderTrackFormContent(trackToEdit);
  }
};



const renderAlbumDetailsForm = () => {
  return (
    <div style={styles.scrollViewInnerContainerCentered}>
      <p style={styles.subtitle3}>Artist</p>

      {/* Display Selected Artist Name */}
      {albumDetails.artistName && (
        <div style={styles.listItem}>
          <p style={styles.tagText}>{albumDetails.artistName.tag}</p>
        </div>
      )}

      <input
        style={styles.inputWider}
        value={albumDetails.albumName}
        onChange={(e) =>
          albumMode
            ? setAlbumDetails({ ...albumDetails, albumName: e.target.value })
            : setSongTitle(e.target.value)
        }
        placeholder="Album Name"
        maxLength={50}
      />

{albumMode && (
  <div style={{ marginTop: 20 }}>
  <button onClick={handleToggleCollaborators} style={styles.customButtonStyle2}>
    + Collaborators
  </button>
  {showCollaborators && (
    <div style={{ marginTop: 10 }}>
      {collaborators.map((collaborator, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <p style={{ color: 'white', textAlign: 'center' }}>Collaborator {index + 1}</p>
          
          {/* Search Input */}
          <input
            style={styles.inputWider}
            placeholder="Search by @username"
            value={collaborator.searchQuery}
            onChange={(e) => handleCollaboratorChange(index, 'searchQuery', e.target.value)}
          />
          
          {collaborator.searchResults && collaborator.searchResults.length > 0 && collaborator.searchQuery && 
            collaborator.searchResults.map((user, idx) => (
              <div key={idx} onClick={() => selectCollaborator(user, index)} style={{ cursor: 'pointer', marginBottom: 5 }}>
                <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
              </div>
            ))
          }

          {/* Display Selected Collaborator */}
          {collaborator.name && (
            <div style={styles.listItem}>
              <p style={styles.tagText}>{collaborator.name}</p>
              <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
                Remove Collaborator
              </button>
            </div>
          )}

          {/* Percentage Input */}
          <div style={styles.inputContainer2}>
            <div style={styles.inputWrapper}>
              <input
                style={styles.inputWider}
                placeholder="Percentage"
                value={collaborator.percentage}
                onChange={(e) => {
                  let formattedText = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  if (formattedText.startsWith('0')) {
                    formattedText = formattedText.slice(1); // Remove leading zero
                  }
                  formattedText = formattedText.slice(0, 2); // Limit to 2 digits
                  handleCollaboratorChange(index, 'percentage', formattedText);
                }}
                type="number"
                maxLength={2}
                disabled={!collaborator.name} // Disable if no collaborator is selected
              />
              <span style={styles.percentageSymbol}>%</span>
            </div>
          </div>

          <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
            Remove Collaborator
          </button>
        </div>
      ))}
      <button onClick={handleAddCollaborator} style={styles.customButtonStyle2}>
        {collaborators.length === 0 ? '+ Add Collaborator' : '+ Add Another Collaborator'}
      </button>
    </div>
  )}
</div>
)}
      
      <div style={styles.buttonContainer}>
        <input type="file" accept=".jpg,.jpeg,image/jpeg" onChange={pickArtwork} style={{ display: 'none' }} ref={artworkInputRef} />
        <button onClick={() => artworkInputRef.current.click()} style={styles.artworkButton}>Album Artwork</button>
        {albumDetails.artworkURL && (
          <img
            src={albumDetails.artworkURL}
            style={styles.imagePreview}
            alt="Artwork Preview"
          />
        )}
      </div>

      <p style={styles.genresTitle}>Genres</p>


      {/* Display added genres */}
      <div style={styles.tagList}>
        {albumDetails.genres.map((genre, index) => (
          <div key={index} style={styles.tagItem}>
            <p style={styles.tagText}>{genre}</p>
            <button onClick={() => removeGenre(genre)} style={{...styles.removeButton, ...styles.whiteText}}>X</button>
          </div>
        ))}
      </div>

      <div style={styles.inputContainer}>
  <div style={styles.pickerContainer}>
    <select
      onChange={handleAddGenreSelect}
      value={selectedGenre}
      style={pickerSelectStyles}
    >
      <option value="" disabled>Select Genre...</option>
      {genresList.map((genre, index) => (
        <option key={index} value={genre.value}>{genre.label}</option>
      ))}
    </select>
  </div>
  <button onClick={addGenre} style={styles.customButtonStyle2}>Add</button>
</div>

      <p style={{ color: 'white' }}>Artist Features</p>

      {isSearchingArtistFeatures &&
        searchResultsArtistFeatures.map((user, index) => (
          <div key={index} onClick={() => selectArtistFeature(user)} style={styles.userItem}>
      <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </div>
        ))
      }
      {albumDetails.artistFeatures.map((artistFeature) => (
        <div key={artistFeature.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{artistFeature.tag}</p>

          <button onClick={() => removeArtistFeature(artistFeature.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          value={searchQueryArtistFeatures}
          onChange={(e) => setSearchQueryArtistFeatures(e.target.value)}
          placeholder="Feature's Name or @username"
          maxLength={50}
          autoComplete="off" 
          name="feature-name-input"
          type="text"
          onBlur={handleBlur}
        />
        <button onClick={addArtistFeature} style={styles.customButtonStyle}>Add</button>
      </div>

      <p style={{ color: 'white' }}>Producers</p>

      {isSearchingProducers &&
        searchResultsProducers.map((user, index) => (
          <div key={index} onClick={() => selectProducer(user)} style={styles.userItem}>
      <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </div>
        ))
      }

      {albumDetails.producers.map((producer) => (
        <div key={producer.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{producer.tag}</p>

          <button onClick={() => removeProducer(producer.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          value={searchQueryProducers}
          onChange={(e) => setSearchQueryProducers(e.target.value)}
          placeholder="Producer's Name or @username"
          maxLength={50}
          onBlur={handleBlur}
        />
        <button onClick={addProducer} style={styles.customButtonStyle}>Add</button>
      </div>

      <p style={{ color: 'white' }}>Songwriters</p>

      {isSearchingSongwriters &&
        searchResultsSongwriters.map((user, index) => (
          <div key={index} onClick={() => selectSongwriter(user)} style={styles.userItem}>
      <p style={{ color: 'white', marginRight: '10px' }}>{`@${user.username} (${user.name})`}</p>
          </div>
        ))
      }

      {albumDetails.songwriters.map((songwriter) => (
        <div key={songwriter.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{songwriter.tag}</p>

          <button onClick={() => removeSongwriter(songwriter.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          value={searchQuerySongwriters}
          onChange={(e) => setSearchQuerySongwriters(e.target.value)}
          placeholder="Songwriter's Name or @username"
          maxLength={50}
          onBlur={handleBlur}
        />
        <button onClick={addSongwriter} style={styles.customButtonStyle}>Add</button>
      </div>

      <p style={{ color: 'white' }}>Record Labels</p>

      {isSearchingRecordLabels &&
        searchResultsRecordLabels.map((user, index) => (
          <div key={index} onClick={() => selectRecordLabel(user)} style={styles.userItem}>
      <p style={{ color: 'white', marginRight: '10px' }}>{`@${user.username} (${user.name})`}</p>
          </div>
        ))
      }

      {albumDetails.recordLabels.map((recordLabel) => (
        <div key={recordLabel.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{recordLabel.tag}</p>

          <button onClick={() => removeRecordLabel(recordLabel.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          style={styles.input}
          value={searchQueryRecordLabels}
          onChange={(e) => setSearchQueryRecordLabels(e.target.value)}
          placeholder="Record Label's Name or @username"
          maxLength={50}
          onBlur={handleBlur}
        />
        <button onClick={addRecordLabel} style={styles.customButtonStyle}>Add</button>
      </div>

      {albumMode && !editingAlbumDetails && (
        <div>
          {!addingSongs && (
            <button onClick={handleAlbumDetailsSubmit} style={styles.customButtonStyle2}>
              Start Adding Songs
            </button>
          )}
        </div>
      )}
      {editingAlbumDetails && (
        <button onClick={handleAlbumDetailsSubmit} style={{ backgroundColor: '#62fbfa' }}>
          Save Changes
        </button>
      )}
    </div>
  );
};






const handleAddNewTrack = () => {
  const newTrack = {
    ...currentTrackDetails,
    artistName: albumDetails.artistName ? { ...albumDetails.artistName } : null,
    trackNumber: tracks.length + 1
  };

  setTracks([...tracks, newTrack]);

  setCurrentForm('Add Track');
};




const renderTrackFormContent = (editingTrack = {}) => {
  const isEditingExistingTrack = editingTrack && editingTrack.trackNumber;
  const trackDetails = isEditingExistingTrack ? editingTrack : currentTrackDetails;

  const handleGenreSelect = (event) => {
    setSelectedGenre(event.target.value);
  };

const addTrackGenre = () => {
  if (selectedGenre) {
    if (!currentTrackDetails.genres.includes(selectedGenre) && currentTrackDetails.genres.length < 1) {
      setCurrentTrackDetails(prevState => ({
        ...prevState,
        genres: [...prevState.genres, selectedGenre]
      }));
    }
    setSelectedGenre(null);
  }
};


const handleRemoveTrackGenre = (index) => {
  setCurrentTrackDetails(prevState => ({
    ...prevState,
    genres: prevState.genres.filter((_, i) => i !== index)
  }));
};






const selectTrackProducer = (user) => {
  if (currentTrackDetails.producers.length >= 20) {
    return;
  }

  const newProducer = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    producers: [...prevState.producers, newProducer]
  }));

  setSearchQueryProducers('');
  setIsSearchingProducers(false);
};








const handleRemoveProducer = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    producers: prevDetails.producers.filter((_, i) => i !== index)
  }));
};


const selectTrackSongwriter = (user) => {
  if (currentTrackDetails.songwriters.length >= 20) {
    return;
  }

  const newSongwriter = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    songwriters: [...prevState.songwriters, newSongwriter]
  }));

  setSearchQuerySongwriters('');
  setIsSearchingSongwriters(false);
};
    
    
    const selectTrackArtistFeature = (user) => {
      if (currentTrackDetails.artistFeatures.length >= 20) {
        return;
      }

      const newArtistFeature = {
        tag: `@${user.username} - ${user.name}`,
        id: user.id
      };

      setCurrentTrackDetails(prevState => ({
        ...prevState,
        artistFeatures: [...prevState.artistFeatures, newArtistFeature]
      }));

      setSearchQueryArtistFeatures('');
      setIsSearchingArtistFeatures(false);
    };


const selectTrackRecordLabel = (user) => {
  if (currentTrackDetails.recordLabels.length >= 6) {
    return;
  }

  const newRecordLabel = {
        tag: `@${user.username} - ${user.name}`,
        id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    recordLabels: [...prevState.recordLabels, newRecordLabel]
  }));

  setSearchQueryRecordLabels('');
  setIsSearchingRecordLabels(false);
};

const selectTrackArtistName = (user) => {
  if (currentTrackDetails.artistName) {
    return;
  }

  const newArtistName = {
    tag: `@${currentUser.username} - ${currentUser.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    artistName: newArtistName
  }));

  setSearchQueryArtistName('');
  setIsSearchingArtistName(false);
};






const handleRemoveRecordLabel = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    recordLabels: prevDetails.recordLabels.filter((_, i) => i !== index)
  }));
};



const handleRemoveArtistName = () => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    artistName: null
  }));
};




const handleRemoveSongwriter = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    songwriters: prevDetails.songwriters.filter((_, i) => i !== index)
  }));
};

const handleRemoveArtistFeature = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    artistFeatures: prevDetails.artistFeatures.filter((_, i) => i !== index)
  }));
};



const handleAddTag = () => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    tags: [...prevDetails.tags, selectedTag]
  }));
};



const handleRemoveTag = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    tags: prevDetails.tags.filter((_, tagIndex) => tagIndex !== index)
  }));
};

const pickTrackArtwork = async (event) => {
  const file = event.target.files[0];
  if (file) {
    
    const isSquare = await checkIfSquare(file);
    if (!isSquare) {
      alert('Please use a square image.');
      return;
    }
    const fileUrl = URL.createObjectURL(file);
    setCurrentTrackDetails(prevDetails => ({
      ...prevDetails,
      artworkFile: file,
      artworkURL: fileUrl,
    }));
    
    
  }
};






const handleReview = () => {
  

  // Add genres to the track
  addTrackGenre();
  handleAddProducer();
  handleAddSongwriter();
  handleAddRecordLabel();
  handleAddArtistFeature();

  // Log current track details after attempting to add genres
  

  // Add a delay
  setTimeout(() => {
    setCurrentTrackDetails(prevDetails => {
      

      const newTrack = {
        ...prevDetails,
        trackNumber: tracks.length + 1,
        artworkFile: prevDetails.artworkFile // Ensure artworkFile is preserved
      };

      

      const updatedTracks = [...tracks, newTrack];

      // Update the tracks state
      setTracks(updatedTracks);

      // Update the form state
      setCurrentForm(`Edit Track ${newTrack.trackNumber}`);
      setEditingAlbumDetails(false);
      setFormMode('edit');

      setIsReviewing(true);

      
      return newTrack; // Ensure to return the newTrack object
    });
  }, 100); // 100 milliseconds delay
};






return (
  <div key={`track-form-${currentTrackDetails.trackNumber}`} style={styles.scrollViewInnerContainer2}>

    <p style={styles.subtitle3}>Artist</p>
    {/* Display Selected Artist */}
    <div style={styles.list}>
      {currentTrackDetails.artistName && (
        <div key={currentTrackDetails.artistName.id} style={styles.listItem}>
          <span style={styles.tagText}>{currentTrackDetails.artistName.tag}</span>
        </div>
      )}
    </div>

    <input
      style={styles.inputWider}
      value={titleInput}
      onChange={handleTitleChange}
      placeholder="Song Title"
      maxLength={50}
    />

{!albumMode && (
  <div style={{ marginTop: 20 }}>
  <button onClick={handleToggleCollaborators} style={styles.customButtonStyle2}>
    + Collaborators
  </button>
  {showCollaborators && (
    <div style={{ marginTop: 10 }}>
      {collaborators.map((collaborator, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <p style={{ color: 'white', textAlign: 'center' }}>Collaborator {index + 1}</p>
          
          {/* Search Input */}
          <input
            style={styles.inputWider}
            placeholder="Search by @username"
            value={collaborator.searchQuery}
            onChange={(e) => handleCollaboratorChange(index, 'searchQuery', e.target.value)}
          />
          
          {collaborator.searchResults && collaborator.searchResults.length > 0 && collaborator.searchQuery && 
            collaborator.searchResults.map((user, idx) => (
              <div key={idx} onClick={() => selectCollaborator(user, index)} style={{ cursor: 'pointer', marginBottom: 5 }}>
                <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
              </div>
            ))
          }

          {/* Display Selected Collaborator */}
          {collaborator.name && (
            <div style={styles.listItem}>
              <p style={styles.tagText}>{collaborator.name}</p>
              <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
                Remove Collaborator
              </button>
            </div>
          )}

          {/* Percentage Input */}
          <div style={styles.inputContainer2}>
            <div style={styles.inputWrapper}>
              <input
                style={styles.inputWider}
                placeholder="Percentage"
                value={collaborator.percentage}
                onChange={(e) => {
                  let formattedText = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  if (formattedText.startsWith('0')) {
                    formattedText = formattedText.slice(1); // Remove leading zero
                  }
                  formattedText = formattedText.slice(0, 2); // Limit to 2 digits
                  handleCollaboratorChange(index, 'percentage', formattedText);
                }}
                type="number"
                maxLength={2}
                disabled={!collaborator.name} // Disable if no collaborator is selected
              />
              <span style={styles.percentageSymbol}>%</span>
            </div>
          </div>

          <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
            Remove Collaborator
          </button>
        </div>
      ))}
      <button onClick={handleAddCollaborator} style={styles.customButtonStyle2}>
        {collaborators.length === 0 ? '+ Add Collaborator' : '+ Add Another Collaborator'}
      </button>
    </div>
  )}
</div>
)}

    
    <div style={styles.buttonContainer}>
      <button onClick={() => artworkInputRef.current.click()} style={styles.artworkButton}>Artwork</button>
    </div>
    <input type="file" accept=".jpg,.jpeg,image/jpeg" onChange={pickTrackArtwork} style={{ display: 'none' }} ref={artworkInputRef} />
    
    {currentTrackDetails.artworkURL && (
      <img
        src={currentTrackDetails.artworkURL}
        style={styles.imagePreview}
        alt="Artwork Preview"
      />
    )}

<p style={styles.genresTitle}>Genres</p>


    {/* Display Added Genres */}
    <div style={styles.tagList}>
      {currentTrackDetails.genres.map((genre, index) => (
        <div key={index} style={styles.tagItem}>
          <p style={styles.tagText}>{genre}</p>
          <button onClick={() => handleRemoveTrackGenre(index)} style={styles.removeButton}>X</button>
        </div>
      ))}
    </div>

    <div style={styles.inputContainer}>
      {/* Genre Selection */}
      <div style={styles.pickerContainer}>
        <select onChange={handleGenreSelect} value={selectedGenre} style={pickerSelectStyles}>
          <option value="" disabled>Select Genre...</option>
          {genresList.map((genre, index) => (
            <option key={index} value={genre.value}>{genre.label}</option>
          ))}
        </select>
      </div>
      <button onClick={addTrackGenre} style={styles.customButtonStyle2}>Add</button>
    </div>

    <p style={{ color: 'white' }}>Artist Features</p>


    {/* Display Added Artist Features */}
    <div style={styles.list}>
      {currentTrackDetails.artistFeatures.map((artistFeature, index) => (
        <div key={artistFeature.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{artistFeature.tag}</p>
          <button onClick={() => handleRemoveArtistFeature(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingArtistFeatures &&
      searchResultsArtistFeatures.map((user, index) => (
        <div key={index} onClick={() => selectTrackArtistFeature(user)} style={styles.userItem}>
      <p style={{ color: 'white', marginRight: '10px' }}>{`@${user.username} (${user.name})`}</p>
        </div>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        style={styles.input}
        value={searchQueryArtistFeatures}
        onChange={(e) => setSearchQueryArtistFeatures(e.target.value)}
        placeholder="Feature's Name or @username"
        maxLength={50}
        onBlur={handleBlur}
      />
      <button onClick={handleAddArtistFeature} style={styles.customButtonStyle}>Add</button>
    </div>

    <p style={{ color: 'white' }}>Producers</p>


    {/* Display Added Producers */}
    <div style={styles.list}>
      {currentTrackDetails.producers.map((producer, index) => (
        <div key={producer.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{producer.tag}</p>

          <button onClick={() => handleRemoveProducer(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingProducers &&
      searchResultsProducers.map((user, index) => (
        <div key={index} onClick={() => selectTrackProducer(user)} style={styles.userItem}>
      <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
        </div>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        style={styles.input}
        value={searchQueryProducers}
        onChange={(e) => setSearchQueryProducers(e.target.value)}
        placeholder="Producer's Name or @username"
        maxLength={50}
        onBlur={handleBlur}
      />
      <button onClick={handleAddProducer} style={styles.customButtonStyle}>Add</button>
    </div>

    <p style={{ color: 'white' }}>Songwriters</p>


    {/* Display Added Songwriters */}
    <div style={styles.list}>
      {currentTrackDetails.songwriters.map((songwriter, index) => (
        <div key={songwriter.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{songwriter.tag}</p>

          <button onClick={() => handleRemoveSongwriter(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingSongwriters &&
      searchResultsSongwriters.map((user, index) => (
        <div key={index} onClick={() => selectTrackSongwriter(user)} style={styles.userItem}>
      <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
        </div>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        style={styles.input}
        value={searchQuerySongwriters}
        onChange={(e) => setSearchQuerySongwriters(e.target.value)}
        placeholder="Songwriter's Name or @username"
        maxLength={50}
        onBlur={handleBlur}
      />
      <button onClick={handleAddSongwriter} style={styles.customButtonStyle}>Add</button>
    </div>

    <p style={{ color: 'white' }}>Record Labels</p>


    <div style={styles.list}>
      {currentTrackDetails.recordLabels.map((recordLabel, index) => (
        <div key={recordLabel.id} style={styles.listItem}>
<p style={{ color: 'white', marginRight: '10px' }}>{recordLabel.tag}</p>

          <button onClick={() => handleRemoveRecordLabel(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingRecordLabels &&
      searchResultsRecordLabels.map((user, index) => (
        <div key={index} onClick={() => selectTrackRecordLabel(user)} style={styles.userItem}>
      <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
        </div>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        style={styles.input}
        value={searchQueryRecordLabels}
        onChange={(e) => setSearchQueryRecordLabels(e.target.value)}
        placeholder="Record Label's Name or @username"
        maxLength={50}
        onBlur={handleBlur}
        
      />
      <button onClick={handleAddRecordLabel} style={styles.customButtonStyle}>Add</button>
    </div>

    <input type="file" accept="audio/mpeg,audio/mp3,audio/wav" onChange={pickSong} style={{ display: 'none' }} ref={fileInputRef} />
    <div style={styles.buttonContainer}>
      <button onClick={() => fileInputRef.current.click()} style={styles.customButtonStyle2}>
        {trackDetails.songURL ? "Change Song File" : "Add Song File"}
      </button>
    </div>

    {currentTrackDetails.songURL && (
      <p style={styles.selectedSong}>
        {currentTrackDetails.songDisplayName || 'Song file selected'}
      </p>
    )}

    {!albumMode && !isReviewing && (
      <button onClick={handleReview} style={styles.reviewButton}>Review Single</button>
    )}

    {albumMode ? (
      <button onClick={formMode === 'edit' ? handleSaveTrackChanges : handleAddNewTrack} style={styles.customButtonStyle2}>
        {formMode === 'edit' ? "Save Changes" : "Add Song"}
      </button>
    ) : (
      isReviewing && (
        <button onClick={handleSaveSingleChanges} style={styles.customButtonStyle2}>Save Changes</button>
      )
    )}
  </div>
);
};




  const [keyboardHeight, setKeyboardHeight] = useState(0);





  const [termsModalVisible, setTermsModalVisible] = useState(false);
const [termsAccepted, setTermsAccepted] = useState(false);


const DISPLAY_TEXT = `By selecting agree, you hereby acknowledge that you have full authorization to upload this content. You are also authorizing SPNDAT to distribute this content to all of it’s users throughout all of the app’s functions, and hold SPNDAT and any of their affiliated parties harmless to any copyright claims and infringements, as referenced in 3.3 of the Terms of Service - Music & Video Component.`;


return (
  <div style={styles.fullScreen}>
              <style>
        {`
          .input-placeholder::placeholder {
            color: black; /* Change this to your desired placeholder text color */
          }
        `}
      </style>
      {alertMessage && (
      <div style={styles.alertOverlay} onClick={() => setAlertMessage('')}>
        <div style={styles.alertBox}>
          <p style={styles.alertText}>{alertMessage}</p>
        </div>
      </div>
    )}
    <div
      style={styles.scrollContainer}
    >
      <div style={styles.scrollViewInnerContainer}>
        <h1 style={styles.title}>Release Music</h1>

        <div style={styles.modeToggleContainer}>
          <button onClick={() => toggleMode('album')} style={albumMode ? styles.activeModeText : styles.inactiveModeText}>
            Album
          </button>
          <button onClick={() => toggleMode('single')} style={!albumMode ? styles.activeModeText : styles.inactiveModeText}>
            Single
          </button>
        </div>

        <div style={styles.containerTest}>
          {
            albumMode && renderTrackForm()
          }

          {
            albumMode && tracks.map((track, index) => (
              <div key={index} style={styles.trackModule}>
                <p style={{ ...styles.trackInfo, color: 'white' }}>Track {track.trackNumber}: {track.title}</p>
                <button onClick={() => removeTrack(track.trackNumber)} style={{ color: "#f63bb0" }}>Remove</button>
              </div>
            ))
          }
        </div>

        {
          !albumMode && renderSingleSongForm()
        }

        {
          !albumMode && isReviewing && renderTrackModules()
        }

        {
          renderAlbumDetailsModule()
        }

        {
          albumMode && addingSongs && renderTrackModules()
        }

        {!albumMode && isReviewing && tracks.length > 0 && (
          <button onClick={handleSubmit} style={styles.button}>
            Release Music
          </button>
        )}
        {tracks.length >= 5 && (
          <button onClick={handleSubmit} style={styles.button}>
            Release Music
          </button>
        )}

        <Modal
          isOpen={termsModalVisible}
          onRequestClose={() => setTermsModalVisible(!termsModalVisible)}
          style={customStyles}
        >
          <div style={styles.centeredView}>
            <div style={styles.modalView}>
              <div style={{ marginBottom: 20, overflowY: 'scroll' }}>
              <p style={{ ...styles.modalText, color: 'white' }}>{DISPLAY_TEXT}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={styles.buttonAgree}
                  onClick={() => {
                    setTermsAccepted(true);
                    setTermsModalVisible(false);
                    handleSubmit();
                  }}
                >
                  I Agree
                </button>
                <button
                  style={styles.buttonDisagree}
                  onClick={() => {
                    setTermsAccepted(false);
                    setTermsModalVisible(false);
                  }}
                >
                  I Disagree
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
  isOpen={currentlyReleasing}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      zIndex: 1000000,
    }
  }}
>
  <h2>Releasing Music...</h2>
</Modal>

      </div>
      <div style={styles.extraSpace}></div>
    </div>

    <div style={{ height: bottomAreaHeight, backgroundColor: 'transparent' }} />

  </div>
);
};

const styles = {
fullScreen: {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'relative',
  zIndex: 10,
  overflow: 'hidden',
},
removeButton: {
  backgroundColor: '#852061',
  color: 'white',
  border: 'none',
  padding: '5px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  fontWeight: 'bold',
  marginTop: '10px',
  fontFamily: 'brhendrix',
},

extraSpace: {
  height: '320px',
},
albumInfoContainer: {
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  marginBottom: '50px',
  borderRadius: 5,
  alignItems: 'center',
},
albumInfoText: {
  color: 'white',
  textAlign: 'center',
  fontSize: '20px',
  marginHorizontal: 10,
  fontFamily: 'brhendrix',

},
imagePreview: {
  display: 'flex',
  justifyContent: 'center',
alignSelf: 'center',
width: '150px',
height: '150px',
},
alertOverlay: {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black background with 0.7 opacity
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 1000, // Ensure it is on top of other content
},
alertBox: {
  backgroundColor: 'black', // Black background
  padding: '20px',
  borderRadius: '5px',
  textAlign: 'center',
},
alertText: {
  color: 'white',
  fontWeight: 'bold',
  fontSize: '16px',
},
inputWider: {
  width: '87%',
  fontFamily: 'brhendrix',
  fontSize: '16px',
  color: 'white',
  padding: '8px',
  backgroundColor: 'transparent',
  marginBottom: '4px',
  marginTop: '20px',
  borderWidth: '1px',
  borderColor: 'gray',
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '8px',
  zIndex: 3,
  boxSizing: 'border-box',
  borderStyle: 'solid', // Ensure a consistent border style
},
userItem: {
  cursor: 'pointer',

},

inputWider2: {
  width: '87%',
  alignSelf: 'center',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '2vw',
  fontFamily: 'brhendrix',
  padding: 8,
  marginBottom: 4,
  marginTop: 4,
  borderWidth: 1,
  borderColor: 'gray',
  borderRadius: 8,
  zIndex: 3,
},
selectedSong: {
  marginTop: 10,
  fontSize: '20px',
  zIndex: 3,
  fontFamily: 'brhendrix',
  color: 'white',
},

subtitle3: {
  fontSize: '20px',
  fontWeight: 'regular',
  fontFamily: 'brhendrix',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 8,
  alignSelf: 'center',
  zIndex: 3,
  color: 'white',
},
tagList: {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: 10,
  zIndex: 3,
  fontFamily: 'brhendrix',
},
tagText: {
  zIndex: 3,
  fontFamily: 'brhendrix',
  color: 'white',
  fontSize: '16px',
  marginRight: '10px',
},
tagItem: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '#240f48',
  padding: '5px 10px',
  marginTop: '15px',
  borderRadius: 20,
  marginVertical: 5,
  marginRight: 5,
  fontFamily: 'brhendrix',
  zIndex: 3,
},
listItem: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#240f48',
  padding: '5px 10px',
  borderRadius: 5,
  marginVertical: 5,
  zIndex: 3,
},
input: {
  width: '87%',
  padding: '5px', // Increase padding for a larger input area
  fontSize: '16px', // Increase font size for better readability
  color: 'white', // Set text color to white
  textAlign: 'left', // Centered text
  marginBottom: 20, // Increase margin bottom for spacing
  borderRadius: 5, // Rounded corners
  border: '1px solid #ccc', // Border styling
  backgroundColor: 'transparent', // Transparent background
},
inputContainer: {
  width: '87%',
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: 'transparent',
},
fullScreenImage: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 0,
},
buttonTouchableArea: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
scrollContainer: {
  position: 'absolute',
  top: 0, 
  width: '100%',
  height: '100%',
  zIndex: 20,
  overflowY: 'auto',
},
scrollViewInnerContainer: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  minHeight: '100%',
  
  paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
  zIndex: 10,
},
scrollViewInnerContainer2: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  minHeight: '100%',
  paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
  zIndex: 10,
},
scrollViewInnerContainerCentered: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  width: '100%',
  paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
  zIndex: 10,
},
title: {
  fontSize: '25px',
  fontWeight: 'bold',
  fontFamily: 'brhendrix',
  marginBottom: 20,
  marginTop: '2vh',
  zIndex: 3,
  color: 'white',
},
genresTitle: {
  fontSize: '16px',
  fontWeight: 'bold',
  fontFamily: 'brhendrix',
  marginTop: '5px',
  marginBottom: '-10px',
  zIndex: 3,
  color: 'white',
},
modeToggleContainer: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  
},
activeModeText: {
  color: '#62fbfa',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '20px',
  marginHorizontal: 10,
  fontFamily: 'brhendrix',
  
},
inactiveModeText: {
  color: '#b3b3b3',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '20px',
  marginHorizontal: 10,
  fontFamily: 'brhendrix',
  cursor: 'pointer',
},
containerTest: {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignSelf: 'center',
  alignItems: 'center',

},
trackModule: {
  paddingBottom: '5px',
  borderWidth: 1,
  borderColor: '#62fbfa',
},
subtitle2: {
  color: 'white', // Set text color to white
  fontSize: 16, // Set font size as needed
  fontFamily: 'brhendrix', // Add your font family
  padding: 10, // Optional: Add padding for better readability
},

button: {
  backgroundColor: 'transparent',
  padding: 10,
  color: '#62fbfa',
  border: 'none',
  display: 'flex',
  fontSize: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '200px',
},
buttonText: {
  color: '#62fbfa',
  fontSize: 16,
},
trackInfo: {
  color: 'white',
  textAlign: 'center',
  fontSize: '16px',
  marginHorizontal: 10,
  fontFamily: 'brhendrix',
  padding: 3,
},
centeredView: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 22,
},
modalView: {
  marginTop: '30vw',
  marginBottom: '30vw',
  backgroundColor: 'black',
  borderRadius: 20,
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 4,
  elevation: 5,
},
modalText: {
  color: 'white',
  marginBottom: '2vh',
  fontSize: '3.5vw',
  lineHeight: 30,
  marginTop: '2vh',
  fontFamily: 'brhendrix',
  textAlign: 'center',
},
textStyle: {
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
},
buttonAgree: {
  backgroundColor: '#2196F3',
  borderRadius: 10,
  padding: 10,
  elevation: 2,
  marginVertical: 10,
  marginLeft: '25%',
},
buttonDisagree: {
  backgroundColor: '#f44336',
  borderRadius: 10,
  padding: 10,
  elevation: 2,
  marginVertical: 10,
},
fullScreenImageOverlay: {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 10,
},
artworkContainer: {
display: 'flex',
width: '150px',
height: '150px',
alignItems: 'center',
justifyContent: 'center',
alignSelf: 'center',

},
artworkButton: {
  backgroundColor: 'transparent',
  color: '#62fbfa',
  fontSize: '16px',
  justifyContent: 'center',
  alignSelf: 'center',
  marginTop: '15px',
  display: 'flex',
  padding: '10px',
  borderRadius: '5px',
  marginBottom: '10px',
  borderWidth: '1px',
  borderColor: 'gray',
  borderStyle: 'solid',
  boxSizing: 'border-box',
  cursor: 'pointer',
},
customButtonStyle: {
  backgroundColor: 'transparent',
  color: '#62fbfa',
  fontSize: '16px',
  justifyContent: 'center',
  alignSelf: 'center',
  cursor: 'pointer',
  display: 'flex',
  padding: '10px',
  border: 'none',
  marginBottom: '10px',
  boxSizing: 'border-box',
},
customButtonStyle2: {
  backgroundColor: 'transparent',
  color: '#62fbfa',
  fontSize: '16px',
  justifyContent: 'center',
  alignSelf: 'center',
  cursor: 'pointer',
  display: 'flex',
  padding: '10px',
  border: 'none',
  boxSizing: 'border-box',
},

reviewButton: {
  backgroundColor: 'transparent',
  color: '#62fbfa',
  border: 'none',
  padding: 10,
  fontSize: '16px',
  borderRadius: 5,
  cursor: 'pointer',
},
buttonContainer: {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  marginBottom: 10,
},

};

const customStyles = { 
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

    export default ReleaseMusic;


