import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import SPNDATButton from './assets/SPNDATtouchablebutton.png'; // Update the path as per your project structure
import spndatTouchableButton from './assets/purchasedPlayButtonPlay.png';
import BUYDATButton from './assets/BUYDATtouchablebutton.png'; // Update the path as per your project structure
import ExpandArrow from './assets/ExpandArrow.png'; // Update the path as per your project structure
import { AppContext } from './AppContext'; // Update the path as per your project structure
import { ProfileComponentSongView } from './ProfileComponentSongView';
import { ShareDATPopup } from './ShareDATPopup';
import { ProfilesDisplayShareDAT } from './ProfilesDisplayShareDAT';
import { PostScreenShareDAT } from './PostScreenShareDAT';
import videojs from 'video.js';
import Modal from 'react-modal';
import 'video.js/dist/video-js.css';
import { getFunctions, httpsCallable } from 'firebase/functions';

const SongDetails = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Added navigate
  const { song } = location.state;
  const [userProfile, setUserProfile] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); // Added state for video playing
  const [isFullscreen, setIsFullscreen] = useState(false); // Added state for fullscreen
  const firestore = getFirestore();
  const { setCurrentView, setSelectedProfile, creditsIOS, playPlaylistSequentially, userId2, shareDATactive, setShareDATactive, setShowShareDATPopup, showShareDATPopup, duration, setDuration, currentTime, setCurrentTime, miniPlayer, audioRef, handleCloseClick, currentUser, setMiniPlayer, playSong } = useContext(AppContext);
  const containerRef = useRef(null); // Added ref
  const videoRef = useRef(null); // Added ref for video player
  const playerRef = useRef(null); // Ref to store video.js player instance
  const [miniPlayerVideo, setMiniPlayerVideo] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [confirmPurchaseModalOpen, setConfirmPurchaseModalOpen] = useState(false);
const [songToPurchase, setSongToPurchase] = useState(null);



  const functions = getFunctions();
const streamContentWEB = httpsCallable(functions, 'streamContentWEB');
const purchaseContentWEB = httpsCallable(functions, 'purchaseContentWEB');

// Extract the base contentId without any trailing hyphen and numbers
const baseContentId = song.contentId.replace(/-\d+$/, '');

// Perform the checks with the sanitized contentId
const isPurchasedMedia = 
  currentUser?.purchasedMedia?.some(media => media.contentId === baseContentId) ||
  currentUser?.singles?.some(single => single.contentId === baseContentId) ||
  currentUser?.videos?.some(video => video.contentId === baseContentId) ||
  currentUser?.albums?.some(album => album.contentId === baseContentId) ||
  currentUser?.beats?.some(beat => beat.contentId === baseContentId);



  useEffect(() => {
    console.log('Song data:', song.SongURL);
    if (song.userId && !userProfile) {
      fetchUserProfile(song.userId);
    }
    // Scroll to the top when song changes
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [song]);


  
  

  useEffect(() => {
    if (audioRef.current) {
      const handleTimeUpdate = () => {
        if (!isDragging) {
          setCurrentTime(audioRef.current.currentTime);
        }
      };

      const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
      };

      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [audioRef.current, isDragging]);

  useEffect(() => {
    if (miniPlayerVideo?.contentId === song.contentId && isVideoPlaying && videoRef.current && !isPurchasedMedia) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        controlBar: {
          children: [
            'playToggle',
            'volumePanel',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'fullscreenToggle',
            'airPlayButton',
          ],
        },
      });

      playerRef.current.src({
        src: song.VideoURL,
        type: 'video/mp4'
      });

      // Add event listeners to adjust styles based on fullscreen state
      playerRef.current.on('fullscreenchange', () => {
        const isFullscreen = playerRef.current.isFullscreen();
        setIsFullscreen(isFullscreen);
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [isVideoPlaying]);

  const fetchUserProfile = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userProfileData = userDoc.data();
        setUserProfile(userProfileData);
        updateSongWithProfileData(userProfileData);
      } else {
        
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const updateSongWithProfileData = (profileData) => {
    const { singles = [], albums = [], videos = [], beats = [] } = profileData;
    const allReleases = [...singles, ...albums, ...videos, ...beats];
  
    // Sort releases by release timestamp in descending order
    allReleases.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  
    const matchedSong = allReleases.find(release => release.contentId === song.contentId);
    
    if (matchedSong) {
      song.ArtistName = matchedSong.ArtistName || song.ArtistName;
      song.SongName = matchedSong.SongName || song.SongName;
      song.albumName = matchedSong.albumName || song.albumName;
      song.producers = matchedSong.producers || song.producers;
      song.songwriters = matchedSong.songwriters || song.songwriters;
      song.recordLabels = matchedSong.recordLabels || song.recordLabels;
      song.VideoURL = matchedSong.VideoURL || song.VideoURL;
      song.ArtworkURL = matchedSong.ArtworkURL || song.ArtworkURL;
    }
  
    // Update the userProfile state with sorted releases
    setUserProfile({
      ...profileData,
      singles: singles.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp),
      albums: albums.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp),
      videos: videos.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp),
      beats: beats.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp),
    });
  };


  const handleBUYDATButtonClick = async (song) => {


    if (song?.isBeat && song?.licensing === 'Exclusive') {
  handleExclusiveBeatBuy();
  return;
}

if (song.isAlbum && creditsIOS < 200) {
  alert('You do not have enough credits to purchase this album');
  return;
}

if (!song.isAlbum && !song.isVideo && !song?.isBeat && creditsIOS < 100) {
  alert('You do not have enough credits to purchase this single');
  return;
}

if (!song.isAlbum && !song.isVideo && song?.isBeat && creditsIOS < 100) {
  alert('You do not have enough credits to purchase this beat');
  return;
}

if (song.isVideo && creditsIOS < 100) {
  alert('You do not have enough credits to purchase this video');
  return;
}

    setIsPurchasing(true); 
    const isVideo = song.isVideo !== undefined ? song.isVideo : false;
  
    const now = new Date();
    const timestamp = now.getTime();
    const readableTimestamp = now.toLocaleString('en-US', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  
    try {
      // Log the data before calling the cloud function to ensure all fields are correct
      console.log('Calling cloud function with data:', {
        contentId: song.contentId,
        userId: song.userId,
        isVideo,
        song,
        readableTimestamp,
      });
  
      // Call the cloud function to process the purchase
      const result = await purchaseContentWEB({
        contentId: song.contentId,
        userId: song.userId,
        centeredRelease: song,
        readableTimestamp,
      });
  
      // If the purchase is successful, handle media playback and UI updates
      if (result.data.status === 'success') {
        handleSPNDATButtonClick(song, userId2, true);
      } else {
        
      }
  
      // Handle the result if necessary
      
    } catch (error) {
      console.error('Error during purchase:', error);
    } finally {
      setIsPurchasing(false); // Hide modal regardless of result
    }
  };

  const handleSPNDATButtonClick = async (song, userId2, skipStreamContent = false) => {
    const isVideo = song.isVideo !== undefined ? song.isVideo : false;
  
    console.log('handleSPNDATButtonClick called with:', {
      song,
      userId2,
      isVideo,
      skipStreamContent
    });
  
    if (song.isVideo) {
      
      setMiniPlayerVideo(song);
      handleCloseClick();
      setIsVideoPlaying(true);
    } else if (song.isAlbum) {
      
      
      playPlaylistSequentially(song.songs);
      
      setMiniPlayer(song.songs[0]);
      const clonedSong = JSON.parse(JSON.stringify(song));
      navigate('/view-song-playing', { state: { song: clonedSong } });
    } else {
      
      setMiniPlayer(song);
      
      playSong(song.SongURL);
    }
  
    // Get the current date and time
    const now = new Date();
    const timestamp = now.getTime();
    const readableTimestamp = now.toLocaleString('en-US', {
      hour12: true,
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  
    console.log('Current timestamp:', {
      now,
      timestamp,
      readableTimestamp
    });
  
    try {
      // Log the data before calling the cloud function to ensure all fields are correct
      console.log('Calling cloud function with data:', {
        contentId: song.contentId,
        userId: userId2,
        isVideo,
        SongName: song.SongName,
        readableTimestamp,
        timestamp,
      });
  
      if (skipStreamContent || isPurchasedMedia) {
        
      } else {
        const result = await streamContentWEB({
          contentId: song.contentId,
          userId: song.userId,
          isVideo: song.isVideo,
          SongName: song.SongName,
          readableTimestamp,
          timestamp,
        });
        // Handle the result if necessary
        
      }
    } catch (error) {
      console.error('Error calling cloud function:', error);
    }
  };
  
  

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  const handleExclusiveBeatBuy = () => {
    const clonedProfile = JSON.parse(JSON.stringify(userProfile));
    setSelectedProfile({
      ...clonedProfile,
      isBeat: true,
      BeatName: song?.SongName
  });
    setCurrentView('conversation');
    navigate('/spnskn');
  };
  

  const handlePressBegins = (release) => {
    setMiniPlayerVideo(null);
    const clonedSong = JSON.parse(JSON.stringify(release));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const fetchProfileById = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userProfile = userDoc.data();
        handlePressProfile(userProfile);
      } else {
        
      }
    } catch (error) {
      console.error('Error fetching user profile by id:', error);
    }
  };

  const handleTagClick = (id) => {
    fetchProfileById(id);
  };

  const formatCredit = (credit) => {
    if (Array.isArray(credit)) {
      return credit.map(item => formatCreditItem(item)).reduce((prev, curr) => [prev, ', ', curr]);
    } else if (typeof credit === 'object' && credit !== null) {
      return Object.values(credit).map(item => formatCreditItem(item)).reduce((prev, curr) => [prev, ', ', curr]);
    }
    return formatCreditItem(credit);
  };

  const formatCreditItem = (item) => {
    if (item && typeof item === 'object' && item.tag && item.id) {
      const parts = item.tag.split(' - ');
      if (parts.length === 2 && parts[0].startsWith('@')) {
        const handle = parts[0];
        return (
          <span key={item.id} onClick={() => handleTagClick(item.id)} style={styles.clickableTag}>
            {handle}
          </span>
        );
      }
      return item.tag;
    }
    return item;
  };

  const renderReleaseRow = (title, releases) => {
    return (
      <div style={styles.releaseSection}>
        <h2 style={styles.releaseTitle}>{title}</h2>
        <div style={styles.releaseRow}>
          {releases.map((release, index) => (
            <div 
              key={index} 
              style={release?.isVideo ? styles.releaseItemVideo : styles.releaseItem} 
              onClick={() => handlePressBegins(release)} // Added onClick to handlePressBegins
            >
              <img src={release.ArtworkURL} alt="Artwork" style={release?.isVideo ? styles.videoArtwork : styles.releaseArtwork} />
              <div style={styles.releaseTextContainer}>
                <p style={styles.releaseArtistName}>{release.ArtistName}</p>
                <p style={styles.releaseSongName}>{release.SongName}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleProgressBarChange = (event) => {
    const newTime = (event.target.value / 100) * duration;
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  const handleProgressBarMouseDown = () => {
    setIsDragging(true);
  };

  const handleProgressBarMouseUp = () => {
    setIsDragging(false);
  };

  if (!song) {
    return <div style={styles.error}>No song data available</div>;
  }




  const fullscreenStyles = {
    videoContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    videoPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  };

  const nonFullscreenStyles = {
    videoContainer: {
      display: 'flex',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      width: '100%',
      height: 'auto',
      position: 'relative',
    },
    videoPlayer: {
      width: '100%',
      maxWidth: '640px', // Ensure the max width doesn't exceed the desired size
      height: 'auto',
      aspectRatio: '16/9', // Maintain the aspect ratio
    },
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const stylesToApply = isFullscreen ? fullscreenStyles : nonFullscreenStyles;

  return (
    <div style={styles.pageContainer}>
      <div style={styles.container} ref={containerRef}>
        {userProfile && (
          <div style={styles.profileItem} onClick={() => handlePressProfile(userProfile)}>
            <ProfileComponentSongView userProfile={userProfile} />
          </div>
        )}
        {isVideoPlaying && miniPlayerVideo?.contentId === song.contentId ? (
          isPurchasedMedia ? (
            <video controls autoPlay style={styles.videoPlayer}>
              <source src={song.VideoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div data-vjs-player style={stylesToApply.videoContainer}>
              <video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered" style={stylesToApply.videoPlayer}></video>
            </div>
          )
        ) : (
          <>
            <div style={styles.artworkContainer}>
              <img onClick={() => setShareDATactive(true)} src={song.ArtworkURL} alt="Artwork" style={song.isVideo ? styles.videoArtwork2 : styles.artwork} />
              <img src={ExpandArrow} alt="Expand" style={styles.expandArrow} onClick={() => setShareDATactive(true)} />
            </div>
            {isPurchasedMedia && miniPlayer?.contentId === song.contentId && (
              <div style={styles.progressBarContainer}>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={(currentTime / duration) * 100}
                  onChange={handleProgressBarChange}
                  onMouseDown={handleProgressBarMouseDown}
                  onMouseUp={handleProgressBarMouseUp}
                  style={styles.progressBar}
                  className="progressBar"
                />
                <div style={styles.timeContainer}>
                  <span style={styles.currentTime}>{formatTime(currentTime)}</span>
                  <span style={styles.duration}>{formatTime(duration)}</span>
                </div>
                <style>
                  {`
                    .progressBar::-webkit-slider-runnable-track {
                      width: 100%;
                      height: 5px;
                      background: rgba(255, 255, 255, 0.5);
                      border-radius: 2.5px;
                    }
                    .progressBar::-moz-range-track {
                      width: 100%;
                      height: 5px;
                      background: rgba(255, 255, 255, 0.5);
                      border-radius: 2.5px;
                    }
                    .progressBar::-ms-track {
                      width: 100%;
                      height: 5px;
                      background: rgba(255, 255, 255, 0.5);
                      border-radius: 2.5px;
                      border-color: transparent;
                      color: transparent;
                    }
                    .progressBar::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      appearance: none;
                      width: 15px;
                      height: 15px;
                      background: #62fbfa;
                      border-radius: 50%;
                      cursor: pointer;
                      margin-top: -5px;
                    }
                    .progressBar::-moz-range-thumb {
                      width: 15px;
                      height: 15px;
                      background: #62fbfa;
                      border-radius: 50%;
                      cursor: pointer;
                    }
                    .progressBar::-ms-thumb {
                      width: 15px;
                      height: 15px;
                      background: #62fbfa;
                      border-radius: 50%;
                      cursor: pointer;
                    }
                  `}
                </style>
              </div>
            )}
          </>
        )}
        <div style={styles.textContainer}>
          <p style={styles.artistName}>{song.ArtistName}</p>
          <p style={styles.songName}>{song.SongName}</p>
          {song.albumName && <p style={styles.albumName}>{song.albumName}</p>}
          {song?.isBeat ? (
  <>
      <p style={styles.creditItem}>Licensing: {song.licensing}</p>
  {song.producers && song.producers.length > 1 && (
    <p style={styles.creditItem}>Additional Producers: {formatCredit(song.producers)}</p>
  )}
    <p style={styles.creditItem}>BPM: {song.bpm}</p>
    <p style={styles.creditItem}>Key: {song.key}</p>
  </>
) : (
  <>
    <p style={styles.creditItem}>Produced By: {formatCredit(song.producers)}</p>
    <p style={styles.creditItem}>Songwriters: {formatCredit(song.songwriters)}</p>
  </>
)}

          <p style={styles.creditItem}>Record Labels: {formatCredit(song.recordLabels)}</p>
        </div>
        <div style={styles.buttonContainer}>
          {!isPurchasedMedia && !song.isAlbum && (
          <img src={SPNDATButton} alt="SPNDAT" style={styles.button} onClick={() => handleSPNDATButtonClick(song, userId2)}  />
          )}
          {isPurchasedMedia && (
          <img src={spndatTouchableButton} alt="SPNDAT" style={styles.button2} onClick={() => handleSPNDATButtonClick(song, userId2)} />
          )}
          {!isPurchasedMedia && (
            <img src={BUYDATButton} alt="BUYDAT" style={styles.button}   onClick={() => {
              if (song?.isBeat) {
                handleExclusiveBeatBuy(); // Pass the appropriate profile object here
              } else {
                setSongToPurchase(song);
                setConfirmPurchaseModalOpen(true);
              }
            }}  />

          )}
        </div>
        {userProfile && (
          <div style={styles.moreReleases}>
            <h2 style={styles.moreReleasesTitle}>More releases by {song.ArtistName}</h2>
            {userProfile.albums && userProfile.albums.length > 0 && renderReleaseRow('Albums', userProfile.albums)}
            {userProfile.singles && userProfile.singles.length > 0 && renderReleaseRow('Singles', userProfile.singles)}
            {userProfile.videos && userProfile.videos.length > 0 && renderReleaseRow('Videos', userProfile.videos)}
            {userProfile.beats && userProfile.beats.length > 0 && renderReleaseRow('Beats', userProfile.beats)}
          </div>
        )}
      </div>
      <Modal
      isOpen={shareDATactive}
      onRequestClose={() => setShareDATactive(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '470px',
          display: 'flex',
          width: '80%',
          flexDirection: 'column',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>

      <ProfilesDisplayShareDAT/>
      </div>
      <div style={styles.sharingPost2}>
      <PostScreenShareDAT centeredRelease={song} />
      </div>
      <div style={styles.cancelContainer}>
      <button style={styles.cancelButton} onClick={() => setShareDATactive(false)}>Cancel</button>
      </div>
    </Modal>

    <Modal
      isOpen={showShareDATPopup}
      onRequestClose={() => setShowShareDATPopup(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '400px',
          width: '90%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>
      <ShareDATPopup
      centeredRelease={song}
      />
      </div>
    </Modal>
    <Modal
  isOpen={isPurchasing}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center'
    }
  }}
>
  <h2>Purchasing Content...</h2>
</Modal>
<Modal
  isOpen={confirmPurchaseModalOpen}
  onRequestClose={() => setConfirmPurchaseModalOpen(false)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center'
    }
  }}
>
<h2>
  {songToPurchase?.isAlbum
    ? "Purchase this Album for 200 credits?"
    : songToPurchase?.isVideo
    ? "Purchase this Video for 100 credits?"
    : songToPurchase?.isBeat
    ? "Purchase this Beat for 100 credits?"
    : "Purchase this Single for 100 credits?"}
</h2>

  <button style={{ marginRight: '10px', backgroundColor: 'transparent', color: '#62fbfa', fontSize: '16px'}} onClick={() => {
    handleBUYDATButtonClick(songToPurchase);
    setConfirmPurchaseModalOpen(false);
  }}>Confirm</button>
  <button style={{marginLeft: '10px', backgroundColor: 'transparent', color: '#62fbfa', fontSize: '16px'}} onClick={() => setConfirmPurchaseModalOpen(false)}>Cancel</button>
</Modal>


    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    padding: '20px',
    boxSizing: 'border-box',
    zIndex: 10,
  },
  sharingPost: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignSelf: 'center',
    marginBottom: '60px',
    position: 'relative',
    justifyContent: 'center',
        },
        sharingPost2: {
          position: 'relative',

          width: '100%',
          height: '100%',
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'center',
              },
        cancelContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
    zIndex: 10,
        },
        cancelButton: {
          padding: '10px 20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          margin: '0 auto',
          position: 'absolute',
          bottom: '10px',
          backgroundColor: 'transparent',
          color: '#62fbfa',
          border: 'none',
          fontSize: '20px',
          borderRadius: '4px',
          cursor: 'pointer',
          zIndex: 10,
        },
  artworkContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    
  },
  expandArrow: {
    position: 'absolute',
    top: '10px',
    right: '-35px',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  profileItem: {
    width: '90%',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
    width: '100%',
    zIndex: 10,
    overflowX: 'hidden',
    overflowY: 'scroll',
    marginBottom: '125px',
  },
  artwork: {
    width: '210px',
    height: '210px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  videoPlayer: {
    width: '100%',
    maxWidth: '640px',
    height: '360px',
    borderRadius: '10px',
  },
  textContainer: {
    marginTop: '-10px', // Reduced from 20px to 10px
  },
  artistName: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'white',
  },
  songName: {
    fontSize: '20px',
    color: 'white',
  },
  albumName: {
    fontSize: '18px',
    fontStyle: 'italic',
    color: 'white',
  },
  creditItem: {
    fontSize: '18px',
    marginTop: '10px',
  },
  error: {
    color: 'red',
    fontSize: '24px',
    textAlign: 'center',
    marginTop: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px', // Adjust width as necessary
  },
  button: {
    width: '100px', // Adjust width as necessary
    height: '40px', // Adjust height as necessary
    cursor: 'pointer',
  },
  button2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px',
    width: '100px', // Adjust width as necessary
    height: '35px', // Adjust height as necessary
    cursor: 'pointer',
  },
  moreReleases: {
    marginTop: '10px',
    width: '100%',
  },
  moreReleasesTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  releaseSection: {
    marginBottom: '20px',
  },
  releaseTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  releaseRow: {
    display: 'flex',
    overflowX: 'auto',
    whiteSpace: 'nowrap', // Ensure items are in a single row
  },
  timeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    marginTop: '5px',
  },
  currentTime: {
    color: 'white',
  },
  duration: {
    color: 'white',
  },
  releaseItem: {
    display: 'inline-block', // Change to inline-block to align items horizontally
    marginRight: '10px',
    width: '150px', // Adjust the width if necessary
    cursor: 'pointer',
  },
  releaseItemVideo: {
    display: 'inline-block', // Change to inline-block to align items horizontally
    marginRight: '10px',
    width: '245px',
    cursor: 'pointer',
  },
  releaseArtwork: {
    width: '150px',
    height: '150px',
    borderRadius: '10px',
  },
  videoArtwork: {
    width: '240px', // 16:9 aspect ratio width
    height: '135px', // 16:9 aspect ratio height
    borderRadius: '10px',
  },
  videoArtwork2: {
    width: '290px', // 16:9 aspect ratio width
    height: '185px', // 16:9 aspect ratio height
    borderRadius: '10px',
  },
  releaseTextContainer: {
    marginTop: '10px',
    textAlign: 'left',
  },
  releaseArtistName: {
    fontSize: '16px',
    fontWeight: 'bold',
    display: 'flex',
    marginBottom: '-10px',
    whiteSpace: 'nowrap', // Ensure single line
    overflow: 'hidden', // Hide overflow text
    textOverflow: 'ellipsis', // Add ellipsis for overflow text
  },
  releaseSongName: {
    fontSize: '14px',
    whiteSpace: 'normal', // Allow wrapping
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Show a maximum of 2 lines
  },
  progressBarContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progressBar: {
    width: '80%',
    '-webkit-appearance': 'none', // Remove default styling for WebKit browsers
    appearance: 'none', // Remove default styling for standard browsers
    background: 'transparent', // Make background transparent
    outline: 'none', // Remove outline
  },
  progressBarTrack: {
    width: '100%',
    height: '5px', // Adjust the height
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Change track color to white
    borderRadius: '2.5px', // Optional: add border radius for rounded track
  },
  progressBarThumb: {
    '-webkit-appearance': 'none', // Remove default styling for WebKit browsers
    appearance: 'none', // Remove default styling for standard browsers
    width: '15px', // Adjust the thumb width
    height: '15px', // Adjust the thumb height
    backgroundColor: '#62fbfa', // Change thumb color as needed
    borderRadius: '50%', // Make the thumb round
    cursor: 'pointer', // Change cursor to pointer
    marginTop: '-5px', // Center the thumb with the track
  },
  clickableTag: {
    color: '#62fbfa',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export default SongDetails;
