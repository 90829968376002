import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { arrayRemove, arrayUnion, getFirestore, updateDoc, runTransaction, addDoc, deleteDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { firestore, storage } from './firebase';
import { AppContext } from './AppContext';
import MYDATProfilePicSelectorUser from './MYDATProfilePicSelectorUser';
import MYDATsongs from './MYDATsongs';
import MYDATscreenSKN from './MYDATscreenSKN';
import MYDATTwoRowScrollView from './MYDATTwoRowScrollView';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProfileFeedUser from './ProfileFeedUser';
import { ProfilesDisplayShareDATprofile } from './ProfilesDisplayShareDATprofile';
import { PostScreenShareDATprofile } from './PostScreenShareDATprofile';
import { ShareDATPopupProfile } from './ShareDATPopupProfile';
import Modal from 'react-modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import iconFeed from './assets/iconFeed.png';
import iconFeedActive from './assets/iconFeedActive.png';
import iconMusic from './assets/iconMusic.png';
import iconMusicActive from './assets/iconMusicActive.png';
import iconBeats from './assets/iconBeats.png';
import iconBeatsActive from './assets/iconBeatsActive.png';
import iconVideo from './assets/iconVideo.png';
import iconVideoActive from './assets/iconVideoActive.png';
import iconStore from './assets/iconStore.png';
import iconStoreActive from './assets/iconStoreActive.png';
import iconShare from './assets/iconShare.png';
import iconShareActive from './assets/iconShareActive.png';
import blurb from './assets/blurb.png';
import spndatTouchableButton from './assets/buyDATtouchable.png';
import BlankBG from './assets/BlankBG.png';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import VoiceDropMuteButton from './assets/VoiceDropMuteButton.png';
import VoiceDropMuteButtonMuted from './assets/VoiceDropMuteButtonMuted.png';
import { object } from 'prop-types';
import followBar from './assets/followBar.png';
import followingBar from './assets/followingBar.png';
import { ShareCreditsPopup } from './ShareCreditsPopup';
import PlayVoiceDropUser from './PlayVoiceDropUser';


const width = window.innerWidth;
const height = window.innerHeight;

const bottomAreaHeight = height * 0.2;
const horizontalAreaSpacing = height * 0;
const offsetFromCenter = 236;
const leftPosition = width / 2 - (width / 1.6) / 2 - offsetFromCenter;

const UserProfileInfo = ({ currentUser }) => {
  const location = useLocation();
  const { profile, fromURL } = location.state;

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(profile?.username);

  const centeredViewStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
   top: '583px',
    position: 'absolute',
    width: '100%',
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '615px' }}>
      <div style={centeredViewStyle}>
        <div style={styles.profilePicUsername}>
          @{profile?.username}
        </div>
        {profile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
          <img 
            src={require('./assets/ArtistIcon1.png')} 
            style={styles.profilePicIcon} 
          />
        )}
        {profile?.accountType !== 'Fan' && isArtistIcon2Visible && (
          <img 
            src={require('./assets/ArtistIcon2.png')} 
            style={styles.profilePicIcon} 
          />
        )}
      </div>
    </div>
  );
};


const BioUpdateComponent = ({ onSave }) => {
  const location = useLocation();

  const { profile = {}  } = location.state;
  const { currentUser, bio, setBio } = useContext(AppContext);
  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const userId = getCurrentUserId();

  const [lineLimitExceeded, setLineLimitExceeded] = useState(false);
  const maxLength = 400;
  const maxLines = 50;
  const [isEditing, setIsEditing] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const inputRef = useRef(null);
  const prefix = 'Bio: ';

  const updateUserBio = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, { bio }); // Save bio directly
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's bio:", error);
    }
  };

  const handlePressOut2 = () => {
    if (isEditing) {
      updateUserBio();
    }
  };

  const handleBioChange = (text) => {
    if (!text.startsWith(prefix)) {
      setBio('');  // Reset the bio to empty
      setIsEditing(false);
      if (inputRef.current) {
        inputRef.current.value = prefix;
      }
    } else {
      setBio(text.substring(prefix.length));
    }
    if (initialLoad) {
      setInitialLoad(false);
    }
  };

  const handleOnFocus = () => {
    setIsEditing(true);
    const cursorPosition = prefix.length + bio.length;
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  const [isOverflowScroll, setIsOverflowScroll] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOverflowScroll(true);
    }, 1000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  

  const textDisplayStyle = {
    color: 'white',
    fontFamily: 'brhendrix',
    width: '145px',
    height: '150px',
    padding: 10, 
    marginBottom: '65px',
    marginRight: 50,
    marginLeft: '55px',
    textAlignVertical: 'top',
    fontSize: '15px',
    fontWeight: 'bold',
    position: 'absolute',
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: 10,
    overflow: 'hidden',
  };

  return (
    <div onClick={handlePressOut2} style={styles.BioContainer}>
      <div style={styles.accountTypeLabel}>{profile?.accountType}</div>
      <div style={{...textDisplayStyle, overflowY: isOverflowScroll ? 'scroll' : 'auto', }}>
        {profile?.bio || 'No bio available'}
      </div>
    </div>
  );
};

const BlurbUpdateComponent = ({ onSave }) => {
  const location = useLocation();

  const { profile = {} } = location.state;
  const { currentUser, blurb, setBlurb } = useContext(AppContext);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const userId = getCurrentUserId();

  const [lineLimitExceeded, setLineLimitExceeded] = useState(false);
  const maxLength = 80;
  const maxLines = 10;
  const [isEditing, setIsEditing] = useState(false);

  const updateUserBlurb = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, {
        blurb: blurb,
      });
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's blurb:", error);
    }
  };

  const handlePressOut = () => {
    if (isEditing) {
      updateUserBlurb();
    }
  };

  return (
    <div onClick={handlePressOut} style={styles.BlurbContainer}>
      <div style={styles.textDisplayBlurb}>
        {profile?.blurb || 'No blurb available'}
      </div>
    </div>
  );  
};


const BlurbUpdateComponent2 = ({ onSave }) => {
  const location = useLocation();

  const { profile = {}  } = location.state;
  const { currentUser, blurb, setBlurb } = useContext(AppContext);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const userId = getCurrentUserId();

  const [lineLimitExceeded, setLineLimitExceeded] = useState(false);
  const maxLength = 80;
  const maxLines = 10;
  const [isEditing, setIsEditing] = useState(false);



  const handlePressOut = () => {
    if (isEditing) {

    }
  };

  return (
    <div onClick={handlePressOut} style={styles.BlurbContainer3}>
      <div style={styles.textDisplayBlurb2}>
      {profile?.blurb || 'No blurb available'}
      </div>
    </div>
  );  
};

const MYDATprofileUser = ({ activeProfileSection, onSKN, onSectionChange, onPlayVideo, onProfilePress, onMoveMP, onMoveMPProfile }) => {
  const isMounted = useRef(false);

  const [followActive, setFollowActive] = useState(false);

  const location = useLocation();



  const { profile, fromURL = true } = location.state || {};

  const navigate = useNavigate();

  const [isViewingScreenSKN, setIsViewingScreenSKN] = useState(false);
  const [screenSKNbuttonImage, setScreenSKNbuttonImage] = useState(null);
  const [screenSKNname, setScreenSKNname] = useState(null);
  const [screenSKNviewing, setScreenSKNviewing] = useState(null);
const [isViewingPurchasedScreenSKN, setIsViewingPurchasedScreenSKN] = useState(false);
const [tempButtonImageUri, setTempButtonImageUri] = useState(null);

const [isPurchasing, setIsPurchasing] = useState(false);

const [confirmPurchaseModalOpen, setConfirmPurchaseModalOpen] = useState(false);

const [sknToPurchase, setSknToPurchase] = useState(null);

  const handleSKN = (media) => {
    handlePressBeginsScreenSKN(media);
  };

  const { username } = useParams();

  const getCachedUserDocProfile = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      let userData = { ...userDocSnap.data(), userId: userId };

      return userData;
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const fetchUserProfileByUsername = async (username) => {
    if (!username) return;

    try {
      const q = query(collection(firestore, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Since you're assuming username is unique, you directly use the first document
        const doc = querySnapshot.docs[0];
        const userData = await getCachedUserDocProfile(doc.id);

        if (userData) {
          // Now passing both userData and the document ID (which is the userId)
          handlePressProfile(userData);
        } else {
          console.error(`UserData could not be prepared for username: ${username}`);
        }
      } else {
        console.error(`No user found with username: ${username}`);
      }
    } catch (error) {
      console.error('Error fetching user by username:', error);
    } finally {
    }
  };

  useEffect(() => {
    if (fromURL) {
    fetchUserProfileByUsername(username);
    };
  }, [username, location]);


  const handlePressBeginsScreenSKN = async (item) => {
    
  
    setScreenSKNbuttonImage(item.buttonImage);
    setScreenSKNname(item.screenSKNName);
  
  
    const updatedScreenSKNViewing = {
      screenSKNId: item.screenSKNId,
      userId: item.userId,
      artistName: item.artistName,
      username: item.username,
      screenSKNName: item.screenSKNName,
      buttonImage: item.buttonImage,
      barImage: item.barImage,
      hasBar: item.hasBar
    };

    setScreenSKNviewing(updatedScreenSKNViewing);
  
    setIsViewingScreenSKN(true);
    
  
  const ownsScreenSKN = (currentUser?.ScreenSKN || []).some(item => item.screenSKNId === updatedScreenSKNViewing.screenSKNId);
  const ownsScreenSKNpurchased = (currentUser?.ScreenSKNpurchased || []).some(item => item.screenSKNId === updatedScreenSKNViewing.screenSKNId);
  
    if (ownsScreenSKN || ownsScreenSKNpurchased) {
      setIsViewingPurchasedScreenSKN(true);
      return;
    } else {
      setIsViewingPurchasedScreenSKN(false); // Ensure the flag is false if not owned or purchased
    }
  
  
    setTempButtonImageUri(item.buttonImage);
    
  };



  const purchaseScreenSKN = async () => {
    if (!screenSKNviewing) {
      console.error("No screen SKN data to purchase");
      return;
    }
  
  const ownsScreenSKN = (currentUser?.ScreenSKN || []).some(item => item.screenSKNId === screenSKNviewing.screenSKNId);
  const ownsScreenSKNpurchased = (currentUser?.ScreenSKNpurchased || []).some(item => item.screenSKNId === screenSKNviewing.screenSKNId);
  
  
    if (ownsScreenSKNpurchased) {
      return;
    }
  
    if (ownsScreenSKN) {
      return;
    }

    setIsPurchasing(true);
  
    try {
      const functions = getFunctions();
      const purchaseFunction = httpsCallable(functions, 'purchaseScreenSKNweb');
  
  const now = new Date();
  const readableTimestamp = now.toLocaleString('en-US', {
    hour12: true,
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  
  
      const screenSKNData = {
        userId: screenSKNviewing.userId,
        screenSKNId: screenSKNviewing.screenSKNId,
        artistName: screenSKNviewing.artistName,
        username: screenSKNviewing.username,
        screenSKNName: screenSKNviewing.screenSKNName,
        buttonImage: screenSKNviewing.buttonImage,
        hasBar: screenSKNviewing.hasBar,
        ...(screenSKNviewing.barImage && { barImage: screenSKNviewing.barImage }),
      };
  
  setCurrentlyBuying(true);
  
      
  const result = await purchaseFunction({
    screenSKNData,
    readableTimestamp,
  });
  
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', userId2);
  
      
  
      // Prepare the update object for Firestore
      const updates = {
        screenSKNbutton: screenSKNviewing.buttonImage,
        screenSKNbar: screenSKNviewing?.barImage ? screenSKNviewing?.barImage : '',
      };
      if (screenSKNviewing.barImage) {
        updates.hasBar = true; // Optionally update `hasBar` if `barImage` is present
      }
  
      await updateDoc(userDocRef, updates); // Update Firestore
      
  
  setCurrentlyBuying(false);
  setShowSuccessAlertSKN(true);
      setIsViewingPurchasedScreenSKN(true);
      setIsPurchasing(false);
    } catch (error) {
      console.error("Purchase failed:", error);
  setCurrentlyBuying(false);
  setIsPurchasing(false);
    }
  };





  const [isEditing, setIsEditing] = useState(false);

  const [visibleItem, setVisibleItem] = useState(null);

  const [isAvatarSelectionVisible, setIsAvatarSelectionVisible] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [screenSKNs, setScreenSKNs] = useState([]);

  const { profileViewing, setProfileViewing, setSelectedUserIdShare, selectedUserIdShare, setShowSuccessAlertShareCredits, setCurrentlySharingCredits, showCreditSharePopup, setShowCreditSharePopup, followers, setShowNewMessageScreen, setSelectedProfile, setCurrentView, following, currentlyBuying, setCurrentlyBuying, showSuccessAlertSKN, setShowSuccessAlertSKN, showShareDATPopupProfile, setShowShareDATPopupProfile, bio, currentUser, mediaData, musicVideos, videoAlbums, updateProfileImage, setInitialExpandedAlbumAndSong, setArtworkUri, setCenteredRelease, hideExpandedAlbum, activeTab, setInitiallySelectedItemId, setTrackToView, userId2, notificationsVisible, voiceDropMuted, setVoiceDropMuted, tempImageUri, setTempImageUri, myPosts, setPostScreenVisible, setMyPosts } = useContext(AppContext);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  useEffect(() => {
    setProfileViewing(profile);
  }, [profile]);

  useEffect(() => {
    
  
    if (!currentUser) {
      
      return;
    }
    try {
      setScreenSKNs(profile?.ScreenSKN || []);
    } catch (error) {
      console.error("Error setting music data:", error);
    } finally {

    }
  }, [currentUser]);

  const updateUserBio = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, { bio }); // Save bio directly
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's bio:", error);
    }
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const userId = getCurrentUserId();

  const handleToggleProfile = async (image, section) => {
};

  const updateUserProfileImage = async (imageUrl) => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, { profileImageUrl: imageUrl });
      updateProfileImage(imageUrl);
      
    } catch (error) {
      console.error("Error updating user's profile image URL in Firestore user document:", error);
    }
  };

  const onImagePicked = async (url) => {
    try {
      await updateUserProfileImage(url);
    } catch (error) {
      console.error('Error updating profile image:', error);
    }
  };

  const uploadImage = async (uri, onProgress) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    const storageRef = ref(storage, 'profile_pictures/' + userId);
    const uploadTask = uploadBytesResumable(storageRef, blob);
    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const pickImage = (imageUri) => {
    setTempImageUri(imageUri);
  };
  

  const handleConfirmImage = useCallback(async () => {
    if (!tempImageUri) {
      console.error('No image URI available to upload.');
      return;
    }
    try {
      const uploadedImageUrl = await uploadImage(tempImageUri, (progress) => setUploadProgress(progress));
      await updateUserProfileImage(uploadedImageUrl);
      onImagePicked(uploadedImageUrl);
      setTempImageUri(null);
    } catch (error) {
      console.error("Failed to complete the image confirmation process:", error);
    }
  }, [tempImageUri, onImagePicked]);

  const muteVoiceDrop = async () => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', userId2);
    try {
      setVoiceDropMuted(prevState => {
        const newState = !prevState;
        updateDoc(userDocRef, {
          voiceDropMuted: newState
        }).then(() => {
          
        }).catch((error) => {
          console.error("Error updating voiceDropMuted:", error);
        });
        return newState;
      });
    } catch (error) {
      console.error("Error toggling voiceDropMuted:", error);
    }
  };

  const positionStyle = {
    position: 'absolute',
    left: width * 0.355,
    bottom: height * 0.72,
    zIndex: 200,
  };

  const hasSingles = mediaData.singles.length > 0;
const hasAlbums = mediaData.albums.length > 0;

const albumSectionStyle = hasSingles ? 
    styles.horizontalSection2 : 
    {
        ...styles.horizontalSection2, 
    top: height * 0.558,
    };

const singleSectionStyle = hasAlbums ? 
    styles.horizontalSection : 
    {
        ...styles.horizontalSection, 
    bottom: height * 0.3,
    };


const hasMusicVideos = mediaData.videos.length > 0;
const singleMusicVideo = mediaData.videos.length === 1;


const musicVideosSectionStyle = singleMusicVideo ? 
    {...styles.horizontalSectionMusicVideos } : 
    styles.horizontalSectionMusicVideos;


    const [activeIcon, setActiveIcon] = useState('feed');

    const handleIconClick = (icon) => {
      setActiveIcon(icon);
    };

    const iconStyles = (icon) => ({
      width: '100px',
      height: '100px',
      cursor: 'pointer',
      margin: '-10px',
      zIndex: 10,
      filter: activeIcon === icon ? 'brightness(1.5)' : 'brightness(0.8)',
    });

    const postsLimit = 10;

    const lastVisiblePostRef = useRef(null);

    const [loadingMore, setLoadingMore] = useState(false); // Manage loading state for loading more posts
const [allPostsLoaded, setAllPostsLoaded] = useState(false);

const [isLoading, setIsLoading] = useState(false);


    const fetchPosts = async (loadMore = false) => {
      if (!userId2) {
        setMyPosts([]);
        setAllPostsLoaded(true);
        return;
      }
  
      if (loadMore && allPostsLoaded) return;
      if (isLoading) return; // Prevent new fetch if already loading
  
      setIsLoading(true);
  
      const db = getFirestore();
      let postsQuery = query(
        collection(db, 'posts'),
        where('userId', '==', userId2),
        orderBy('createdAt', 'desc'),
        limit(postsLimit)
      );
  
      if (loadMore && lastVisiblePostRef.current) {
        postsQuery = query(postsQuery, startAfter(lastVisiblePostRef.current));
      }
  
      try {
        const querySnapshot = await getDocs(postsQuery);
        const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        if (querySnapshot.docs.length > 0) {
          lastVisiblePostRef.current = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
  
        if (!loadMore) {
          setMyPosts(fetchedPosts);
        } else {
          setMyPosts(prevPosts => {
            const newPosts = fetchedPosts.filter(newPost => !prevPosts.some(post => post.id === newPost.id));
            return [...prevPosts, ...newPosts];
          });
        }
  
        if (fetchedPosts.length < postsLimit) {
          setAllPostsLoaded(true);
        } else {
          setAllPostsLoaded(false);
        }
      } catch (error) {
        console.error("Error fetching posts: ", error);
      } finally {
        setIsLoading(false);
      }
    };


    const handleToggleFollow = async () => {
      const currentUserId = getCurrentUserId();
      const mandatoryFollowingIds = [
        "h0fEIStkM4XHTDErZM5i8XIo9rA3",
        "OHaY20b54nTGd5BBgbJOL2BHEbX2",
        "rdX289s3YBP31Hw5qn2eXVywtBq1",
        "Mkf4631LCEOYmHXWRzGirPdxBrt1"
      ];
    
      // Check if the profile's userId is in the mandatory following list
      if (mandatoryFollowingIds.includes(profile?.userId)) {
        console.log("Cannot unfollow a mandatory user.");
        return;
      }
    
      if (!currentUserId || !profile?.userId) {
        console.error("No authenticated user found or invalid target userId.");
        return;
      }
    
      const userDocRef = doc(firestore, "users", currentUserId);
      const targetUserDocRef = doc(firestore, "users", profile?.userId);
      const followerDocRef = doc(firestore, `users/${profile?.userId}/followers`, currentUserId);
    
      try {
        // Toggle follow status
        if (following.includes(profile?.userId)) {
          // Unfollow logic
          await updateDoc(userDocRef, {
            following: arrayRemove(profile?.userId),
          });
          await updateDoc(targetUserDocRef, {
            followers: arrayRemove(currentUserId),
          });
          await deleteDoc(followerDocRef);
          
        } else {
          // Follow logic
          await updateDoc(userDocRef, {
            following: arrayUnion(profile?.userId),
          });
          await updateDoc(targetUserDocRef, {
            followers: arrayUnion(currentUserId),
          });
          await setDoc(followerDocRef, { followedAt: new Date() });
        }
      } catch (error) {
        console.error("Error updating user's following list:", error);
      }
    };
    

    useEffect(() => {
      
      
    
      if (following.includes(profile?.userId)) {
        setFollowActive(true);
        
      } else {
        setFollowActive(false);
        
      }
    }, [profile, following]);
    
    


    const handlePressProfile = (profile) => {
      const clonedProfile = JSON.parse(JSON.stringify(profile));
      const targetRoute = profile?.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
      navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
    };

    const handleMessage = () => {
      const match = followers.find(follower => {
          // Log each userId comparison
          
          return follower === profile.userId;
      });
      
      if (match) {
          // Construct the selected profile object with the matched follower as userId and the viewing profile's image URL
          const profileToSet = {
              userId: profile.userId, // 'match' is directly the userId since 'follower' is the userId
              profileImageUrl: profile.profileImageUrl,
              username: profile.username,
              name: profile.artistName,
              accountType: profile.accountType,
          };
  
          setShowNewMessageScreen(true);
          setSelectedProfile(profileToSet);
          setCurrentView('conversation');
          navigate('/spnskn');
      } else {
          
      }
  };


  const handleDonateCredits = () => {
    const selectedUserId = profile.userId;
    setSelectedUserIdShare(selectedUserId);
    setShowCreditSharePopup(true);
  };

  const handleShareCredits = async (amount) => {
    setCurrentlySharingCredits(true);
    setShowCreditSharePopup(false);
   const functions = getFunctions();
   const transferCredits = httpsCallable(functions, 'transferCredits');
 
   try {
      // Detailed logging of selectedUserIdShare
     const response = await transferCredits({
       receiverId: selectedUserIdShare,
       amount: amount // the amount of credits to transfer
     });
 
     
     setShowSuccessAlertShareCredits(true);
    setCurrentlySharingCredits(false);
 } catch (error) {
    setCurrentlySharingCredits(false);
     console.error("handleShareCredits: Error sharing credits:", error);
 }
 };

    return (
      <div style={styles.fullScreen}>
        <div style={styles.scrollContainerMain}>
          <div style={styles.container}>
          <img src={blurb} style={styles.BlurbContainer2}img/>
            <div style={styles.topSection}>

            <div style={styles.BlurbContainerOuter}>
  {profile ? (
    <BlurbUpdateComponent2 />
  ) : null}
</div>

              <div style={styles.BlurbContainerOuter2}>
              {profile ? (
                <BlurbUpdateComponent />
              ) : null}
              </div>
              <div style={styles.avatarContainer}>
                <div style={styles.profilePicWrapper}>
                  {}
                  <MYDATProfilePicSelectorUser profile={profile} />
                </div>
                {profile ? (
                <div style={styles.profileInfoWrapper}>
               
                  <UserProfileInfo currentUser={profile} />
                  <div style={styles.profilePicName}>
                    {profile?.name}
                  </div>
                  
                  <div style={styles.profileLocation}>
                    {profile?.location}
                  </div>
               
                </div>
                 ) : null}
              </div>
              {profile ? (
              <div style={styles.BioContainer}>
                <BioUpdateComponent />
              </div>
               ) : null}
            </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '0 auto' }}>
            <div style={styles.iconRow}>
              <img
                src={activeIcon === 'feed' ? iconFeedActive : iconFeed}
                style={iconStyles('feed')}
                alt="Feed"
                onClick={() => handleIconClick('feed')}
              />
              {profile?.accountType !== 'Fan' && (
                <>
                  <img
                    src={activeIcon === 'music' ? iconMusicActive : iconMusic}
                    style={iconStyles('music')}
                    alt="Music"
                    onClick={() => handleIconClick('music')}
                  />
                  <img
src={
  profile?.accountType === 'Producer'
    ? activeIcon === 'video'
      ? iconBeatsActive
      : iconBeats
    : activeIcon === 'video'
    ? iconVideoActive
    : iconVideo
}

                    style={iconStyles('video')}
                    alt="Video"
                    onClick={() => handleIconClick('video')}
                  />
                  <img
                    src={activeIcon === 'store' ? iconStoreActive : iconStore}
                    style={iconStyles('store')}
                    alt="Store"
                    onClick={() => handleIconClick('store')}
                  />
                </>
              )}
              <img
                src={activeIcon === 'share' ? iconShareActive : iconShare}
                style={iconStyles('share')}
                alt="Share"
                onClick={() => handleIconClick('share')}
              />
            </div>
            </div>
            <div style={styles.iconRow2}>
              <div onClick={handleToggleFollow} style={styles.horizontalLine}> </div>
              <div onClick={handleMessage} style={styles.horizontalLine2}> </div>
              <div onClick={handleDonateCredits} style={styles.horizontalLine3}> </div>
              <img src={followActive ? followingBar : followBar} style={styles.followBar} />
              </div>
    
            <div style={{ flexGrow: 1 }}>
              {activeIcon === 'feed' && (
                <>
                  <ProfileFeedUser profile={profile} />
                </>
              )}
            </div>
    
            {profile?.accountType !== 'Fan' && activeIcon === 'music' && (
  <>
    {profile?.singles.length > 0 && (
      <div style={singleSectionStyle}>
        <div style={styles.horizontalText}>Singles</div>
        <div style={styles.horizontalScroll}>
          {profile?.singles.map(item => (
            <MYDATsongs key={item.contentId} item={item} onPress={handlePressBegins} />
          ))}
        </div>
      </div>
    )}

    {profile?.albums.length > 0 && (
      <div style={albumSectionStyle}>
        <div style={styles.horizontalText}>Albums</div>
        <div style={styles.horizontalScroll}>
          {profile?.albums.map(item => (
            <MYDATsongs key={item.contentId} item={item} onPress={handlePressBegins} />
          ))}
        </div>
      </div>
    )}

    {profile?.accountType === 'Producer' && (
      <>
        {profile?.videos.length === 0 ? (
          <div style={styles.noContentText}>
            This artist hasn't released any music videos yet
          </div>
        ) : (
          <div style={musicVideosSectionStyle}>
            <div style={styles.horizontalText}>Music Videos</div>
            <MYDATTwoRowScrollView musicVideos={profile?.videos} onPress={handlePressBegins} />
          </div>
        )}
        <div style={styles.extraSpace}></div>
      </>
    )}

    <div style={styles.extraSpace}></div>
  </>
)}

    
    {profile?.accountType !== 'Fan' && activeIcon === 'video' && (
  <>
    {profile?.accountType === 'Producer' ? (
      <>
      
      {Array.isArray(profile?.beats) && profile?.beats.length === 0 ? (
  <div style={styles.noContentText}>
    This producer hasn't released any beats yet
  </div>
) : (
  <div style={singleSectionStyle}>
    <div style={styles.horizontalText}>Beats</div>
    <div style={styles.horizontalScroll}>
      {Array.isArray(profile?.beats) && profile?.beats.map(item => (
        <MYDATsongs key={item.contentId} item={item} onPress={handlePressBegins} />
      ))}
    </div>
  </div>
)}
<div style={styles.extraSpace}></div>
      </>
    ) : (
      <>
        {profile?.videos.length === 0 ? (
          <div style={styles.noContentText}>
            This artist hasn't released any music videos yet
          </div>
        ) : (
          <div style={musicVideosSectionStyle}>
            <div style={styles.horizontalText}>Music Videos</div>
            <MYDATTwoRowScrollView musicVideos={profile?.videos} onPress={handlePressBegins} />
          </div>
        )}
        <div style={styles.extraSpace}></div>
      </>
    )}
  </>
)}


{activeIcon === 'share' && (
              <>
                                <div style={musicVideosSectionStyle}>
                    <div style={styles.horizontalText}></div>
                    <PostScreenShareDATprofile viewingProfile={profile} />
                  </div>
                  <div style={musicVideosSectionStyle}>
                    <div style={styles.horizontalText}></div>
                    <ProfilesDisplayShareDATprofile />
                  </div>
              </>
            )}
    
            {profile?.accountType !== 'Fan' && activeIcon === 'store' && (
              <>
                {screenSKNs.length === 0 ? (
                  <div style={styles.noContentText}>
                    This artist hasn't released any Verch yet
                  </div>
                ) : (
                  <div style={styles.horizontalSectionScreenSKN}>
                    <div style={styles.horizontalText}>ScreenSKN</div>
                    <div style={styles.horizontalScroll2}>
                      {screenSKNs.map(item => (
                        <MYDATscreenSKN key={item.buttonImage} item={item} onPress={() => handleSKN(item)} />
                      ))}
                    </div>
                  </div>
                )}
                               <div style={styles.extraSpace}></div>
              </>
            )}
          </div>
    
          <div style={{ height: bottomAreaHeight }} />
        </div>
        <Modal
      isOpen={showShareDATPopupProfile}
      onRequestClose={() => setShowShareDATPopupProfile(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '400px',
          width: '80%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    > 
      <div style={styles.sharingPost}>
      <ShareDATPopupProfile viewingProfile={profile}/>
      </div>
    </Modal>
    <Modal
      isOpen={isViewingScreenSKN}
      onRequestClose={() => setIsViewingScreenSKN(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '400px',
          width: '80%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.centeredScreenSKN}>
        
      <div style={styles.imageStack} >
        
      <img src={screenSKNbuttonImage} style={styles.circularImageSKN} />
      <div style={styles.sknName}>
                    {screenSKNname}
                  </div>
                  {!isViewingPurchasedScreenSKN && (
                  <button onClick={() => setConfirmPurchaseModalOpen(true)} style={styles.createIconTouchable}>
              <img src={spndatTouchableButton} style={styles.createIcon1} alt="Create Icon" />
            </button>
                  )}
      </div>
      
      </div>
      
      <div style={styles.cancelContainer}>
        
      <button style={styles.cancelButton} onClick={() => setIsViewingScreenSKN(false)}>Cancel</button>
      </div>
    </Modal>
    <Modal
      isOpen={showCreditSharePopup}
      onRequestClose={() => setShowCreditSharePopup(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          height: '400px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>
      <ShareCreditsPopup
      onShare={handleShareCredits}
      />
      </div>
    </Modal>
    <Modal
  isOpen={isPurchasing}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      zIndex: 1000000,
    }
  }}
>
  <h2>Purchasing Content...</h2>
</Modal>
<Modal
  isOpen={confirmPurchaseModalOpen}
  onRequestClose={() => setConfirmPurchaseModalOpen(false)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      zIndex: 100000000,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      zIndex: 100000000,
    }
  }}
>
  <h2>{"Purchase ScreenSKN for 50 credits?"}</h2>
  <button style={{ marginRight: '10px', backgroundColor: 'transparent', color: '#62fbfa', fontSize: '16px'}} onClick={() => {
    purchaseScreenSKN();
    setConfirmPurchaseModalOpen(false);
  }}>Confirm</button>
  <button style={{marginLeft: '10px', backgroundColor: 'transparent', color: '#62fbfa', fontSize: '16px'}} onClick={() => setConfirmPurchaseModalOpen(false)}>Cancel</button>
</Modal>
<PlayVoiceDropUser profile={profile} />
      </div>
    );
    
    
  };
  
  const imageSize = width * 0.3;
  const imageSizeSKN = '100px';
  const circularImageSize = '100px';
  
  const styles = {
    fullScreen: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%', 
overflow: 'hidden',
overflowX: 'hidden',
    },
    horizontalLine: {
    backgroundColor: 'transparent',
    width: '17vh',
    height: '35px',
    display: 'flex',
    left: '0',
    zIndex: 10,
    position: 'absolute',
    cursor: 'pointer',
    },
    horizontalLine2: {
      backgroundColor: 'transparent',
      width: '17vh',
      height: '35px',
      display: 'flex',
      left: '33.5%',
      zIndex: 10,
      position: 'absolute',
      cursor: 'pointer',
      },
      extraSpace: {
        height: '500px',
          },
      horizontalLine3: {
        backgroundColor: 'transparent',
        width: '17vh',
        height: '35px',
        display: 'flex',
        right: '0',
        zIndex: 10,
        position: 'absolute',
        cursor: 'pointer',
        },
    sharingPost: {
      width: '100%',
      height: '200px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
          },
    createIconTouchable: {
      margin: '0 10px',
      width: '150px',
      alignSelf: 'center',
      justifyContent: 'center',
      display: 'flex',
      height: '50px',
      marginTop: '200px',
      alignItems: 'center',
      position: 'absolute',
      objectFit: 'contain',
      backgroundColor: 'transparent',
      border: 'none',
    },
    createIcon1: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
      alignItems: 'center',
objectFit: 'contain',
      alignSelf: 'center',
      zIndex: 100,
      backgroundColor: 'transparent',
    },
    centeredScreenSKN: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    imageStack: {
      width: '250px',
      height: '350px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      zIndex: 5,
    },
    circularImageSKN: {
      width: '200px',
      height: '200px',
      borderRadius: '180px',
      position: 'absolute',
      marginBottom: '190px',
      resizeMode: 'cover',
      display: 'flex',
      zIndex: 5,
    },
    sknName: {
      fontSize: '20px',
display: 'flex',
marginTop: '90px',
      fontWeight: 'bold',
      fontFamily: 'brhendrix',
      color: 'white',
      zIndex: 3,
      alignSelf: 'center',
    },
    cancelContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
      zIndex: 10,
          },
          cancelButton: {
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
            margin: '0 auto',
            position: 'absolute',
            bottom: '5px',
            backgroundColor: 'transparent',
            color: '#62fbfa',
            border: 'none',
            fontSize: '20px',
            borderRadius: '4px',
            cursor: 'pointer',
            zIndex: 10,
          },
    noContentText: {
      fontSize: 16,
      color: 'white',
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
    },
    textInput: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '150px',
      height: '150px',
      padding: 10,
      marginBottom: '65px',
      marginRight: 40,
      marginLeft: '40px',
      textAlignVertical: 'top',
      fontSize: '20px',
      fontWeight: 'bold',
      position: 'absolute',
      border: 'none',
      backgroundColor: 'transparent',
      zIndex: 10,
    },
    BioContainer: {
      marginBottom: '25px',
      display: 'flex',
      width: '150px',
      height: '150px',
      marginRight: '15px',
      marginLeft: '17px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      marginTop: '20px',
      overflow: 'hidden',
    },
    accountTypeLabel: {
      fontSize: 28,
      fontWeight: 'bold',
      color: '#62fbfa',
      marginBottom: 260,
      textAlign: 'center',
      position: 'absolute',
      zIndex: 10,
    },
    textDisplay: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '140px',
      height: '150px',
      padding: 10, 
      marginBottom: '65px',
      marginRight: 40,
      marginLeft: '55px',
      textAlignVertical: 'top',
      fontSize: '15px',
      fontWeight: 'bold',
      position: 'absolute',
      border: 'none',
      backgroundColor: 'transparent',
      zIndex: 10,
      overflow: 'hidden',
    },
    textDisplayBlurb: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '133px',
      height: '100px',
      padding: 10,
      display: 'flex',
      textAlignVertical: 'top',
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '19px',
      border: 'none',
      backgroundColor: 'transparent',
      position: 'absolute',
      zIndex: 10,
    },
    textDisplayBlurb2: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '150px',
      height: '100px',
      padding: 10,
      opacity: 0,
      display: 'flex',
      textAlignVertical: 'top',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '19px',
      border: 'none',
      backgroundColor: 'transparent',
      position: 'absolute',
      zIndex: 10,
    },
    textInputBlurb: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '150px',
      height: '100px',
      padding: 10,
      textAlignVertical: 'top',
      fontSize: '20px',
      fontWeight: 'bold',
      border: 'none',
      backgroundColor: 'transparent',
      position: 'absolute',
      zIndex: 10,
    },
    profilePicWrapper: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    profileInfoWrapper: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      flexDirection: 'column',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '320px',
    },
    container: {

      alignItems: 'center',
      justifyContent: 'center',
      overflowX: 'hidden',
      position: 'relative',
      flexGrow: 1,
    },
    scrollContainerMain: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      overflowY: 'auto', 
      overflowX: 'hidden',
      zIndex: 10,
      
    },
    topSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '20px',
    },
    avatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      marginBottom: '90px',
      
    },
    profilePicName: {
      display: 'flex',
      color: 'white',
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    profileLocation: {
      color: '#62fbfa',
      fontSize: '18px',
      textAlign: 'center',
    },
    profilePicUsername: {
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '5px',
    },
    profilePicIcon: {
      width: '20px',
      height: '20px',
      marginLeft: '5px',
    },
    BlurbContainer2: {
      left: '-20px',
      top: '-50px',
      marginBottom: '125px',
      alignItems: 'center',
      position: 'absolute',
marginRight: '25px',
      width: '170px',
      height: '194px',
      
      marginTop: '20px',
    },
    BlurbContainerOuter: {
      marginBottom: '125px',
      display: 'flex',
      marginLeft: '15px',
      marginRight: '15px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      marginTop: '20px',
    },
    BlurbContainerOuter2: {
      marginBottom: '125px',
      display: 'flex',
      marginLeft: '15px',
      marginRight: '15px',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      marginTop: '20px',
    },
    BlurbContainer: {
      marginBottom: '125px',
      display: 'flex',
      marginRight: '68px',
      alignItems: 'center',
      justifyContent: 'left',
      width: '100%',
      position: 'absolute',
      height: '100%',
      marginTop: '20px',
    },
    BlurbContainer3: {
      marginBottom: '125px',
      display: 'flex',
      marginLeft: '15px',
      marginRight: '15px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      marginTop: '20px',
    },
    muteButton: {
      width: '50px',
      height: '40px',
      position: 'absolute',
      right: '20px',
      marginTop: '-180px',
      border: 'none',
      zIndex: 50,
      backgroundColor: 'transparent',
    },
    iconRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      marginTop: '-50px', 
    },
    iconRow2: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'transparent',
      marginTop: '-16px', 
    },
    followBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
    },
    horizontalSectionScreenSKN: {
      marginBottom: '50px',
      marginLeft: '30px',
      flexDirection: 'column',
      overflowX: 'auto', 
    },
    horizontalSection: {

      marginLeft: '30px',
    },
    horizontalSection2: {
      marginTop: '20px',
      marginLeft: '30px',
      marginBottom: '50px',
    },
    horizontalSectionMusicVideos: {
      marginLeft: '30px',
      marginBottom: '50px',
    },
    horizontalText: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'white',
      marginBottom: '10px',
      textAlign: 'left',
    },
    horizontalScroll: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'auto',  // Ensures content can scroll horizontally
      whiteSpace: 'nowrap',
    },
    horizontalScroll2: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'auto',  // Ensures content can scroll horizontally
      whiteSpace: 'nowrap',
    },
    tapToReleaseTouchable: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      marginTop: '100px',
      backgroundColor: '#62fbfa',
      borderRadius: '8px',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 10,
    },
    tapToReleaseTouchable2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      marginTop: '250px',
      backgroundColor: '#62fbfa',
      borderRadius: '8px',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 10,
    },
    tapToReleaseText: {
      color: 'black',
      fontSize: '16px',
      textAlign: 'center',
    },
    fullScreenImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
    },
  };
  
  export default MYDATprofileUser;
