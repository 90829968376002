import React, { useState, useRef, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { AppContext } from './AppContext';
import { getFirestore, doc, query, where, setDoc, serverTimestamp, getDocs, collection, limit } from 'firebase/firestore';
import { firestore } from './firebase';

const PostScreenShareDAT = ({ centeredRelease, onNavigate, onPost }) => {
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);
  const [tempImageUri, setTempImageUri] = useState(null);

  const { showMediaPopup, userId2, currentUser, posts, setPosts, setCurrentlyLoadingProfile, setViewingProfile, setActivePopupMedia, activePopupMedia } = useContext(AppContext);

  const [searchResultsComments, setSearchResultsComments] = useState([]);
  const [isSearchingComments, setIsSearchingComments] = useState(false);
  const [tags, setTags] = useState([]);
  const commentInputRef = useRef(null);
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  useEffect(() => {
    const keyboardDidHide = () => {
      setIsSearchingComments(false);
    };

    const keyboardHideListener = window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        keyboardDidHide();
      }
    });

    return () => {
      window.removeEventListener('keydown', keyboardHideListener);
    };
  }, []);

  useEffect(() => {
    const searchUsers = async (queryText) => {
      const lastAtPos = queryText.lastIndexOf('@');
      if (lastAtPos === -1) {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
        return;
      }

      const spaceAfterAt = queryText.indexOf(' ', lastAtPos);
      if (spaceAfterAt !== -1 && spaceAfterAt < queryText.length) {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
        return;
      }

      const searchQuery = queryText.substring(lastAtPos + 1);
      if (searchQuery.length > 0) {
        setIsSearchingComments(true);
        const searchValue = searchQuery.toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef,
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );

        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSearchResultsComments(users);
        } catch (error) {
          console.error('Error searching users:', error);
          setSearchResultsComments([]);
        }
      } else {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
      }
    };

    if (text.includes('@')) {
      const delayDebounce = setTimeout(() => {
        searchUsers(text);
      }, 300);

      return () => clearTimeout(delayDebounce);
    }
  }, [text]);

  const handleSelectUser = (user) => {
    const cursorPosition = selection.start;
    const lastAtPos = text.lastIndexOf('@', cursorPosition);

    const beforeAt = text.substring(0, lastAtPos);
    const afterAt = text.substring(cursorPosition);

    const newComment = `${beforeAt}@${user.username} ${afterAt}`;
    setText(newComment);

    const newTag = {
      username: user.username,
      userId: user.id,
      start: beforeAt.length + 1,
      end: beforeAt.length + 1 + user.username.length,
    };

    const updatedTags = [...tags.filter((tag) => tag.end <= lastAtPos), newTag].sort((a, b) => a.start - b.start);
    setTags(updatedTags);

    setIsSearchingComments(false);
    setSearchResultsComments([]);

    const newCursorPos = beforeAt.length + user.username.length + 2;
    setSelection({ start: newCursorPos, end: newCursorPos });
  };

  const handleTextChange = (text) => {
    setText(text);

    const lastAtPos = text.lastIndexOf('@');
    const cursorPosition = selection.start;
    if (lastAtPos > (tags.length > 0 ? Math.max(...tags.map((tag) => tag.end)) : -1)) {
      setIsSearchingComments(true);

      const searchQuery = text.substring(lastAtPos + 1, cursorPosition);
      if (text.endsWith(' ') && searchQuery.length > 0) {
        const matchedUser = searchResultsComments.find((user) => user.username.toLowerCase() === searchQuery.trim().toLowerCase());
        if (matchedUser) {
          handleSelectUser(matchedUser);
          return;
        }
      }
    } else {
      setIsSearchingComments(false);
    }

    const adjustedTags = tags
      .map((tag) => {
        if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
          return tag;
        } else {
          const startPos = text.indexOf(`@${tag.username}`);
          if (startPos !== -1) {
            return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
          }
        }
        return null;
      })
      .filter((tag) => tag !== null);

    setTags(adjustedTags);

    
  };

  const handlePost = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert('Please log in to post');
      return;
    }

    setUploading(true);

    try {
      const userRef = userId2 ? doc(firestore, 'users', userId2) : null;
      const docRef = doc(collection(getFirestore(), 'posts'));
      const media = {
        contentId: centeredRelease.contentId,
        SongName: centeredRelease?.songName || centeredRelease?.albumName || centeredRelease?.SongName,
        ArtistName: centeredRelease?.artistName || centeredRelease?.ArtistName,
        ArtworkURL: centeredRelease?.artworkUrl || centeredRelease?.ArtworkURL || centeredRelease?.VideoThumbnailURL,
        artistUserId: centeredRelease?.userId,
        isAlbum: centeredRelease?.isAlbum || false,
        isVideo: centeredRelease?.isVideo || false,
        username: centeredRelease?.username,
      };
      const postData = {
        id: docRef.id,
        postId: docRef.id,
        text,
        userRef,
        accountType: currentUser.accountType,
        imageUri: centeredRelease.ArtworkURL || '',
        userId: user.uid,
        username: currentUser.username,
        name: currentUser.name,
        profileImageUrl: currentUser.profileImageUrl,
        createdAt: serverTimestamp(),
        likeCount: 0,
        repostCount: 0,
        likes: {},
        comments: [],
        repostUserIds: [],
        media: media,
        tags,
      };

      await setDoc(docRef, postData);
      

      setPosts((prevPosts) => [...prevPosts, postData]);

      const navigationPostData = {
        ...postData,
        createdAt: {
          seconds: Math.floor(Date.now() / 1000),
          nanoseconds: 0,
        },
      };


      setText('');
      setTempImageUri(null);
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Error creating post. Please try again later.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div style={{ flex: 1, display: 'flex', alignSelf: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignSelf: 'center', width: '100%', padding: '20px' }}>
        <div style={styles.commentSectionContainer}>
          <div style={styles.addCommentContainer}>
            <textarea
              ref={commentInputRef}
              placeholder="Share to Feed..."
              value={text}
              onChange={(e) => handleTextChange(e.target.value)}
              style={{
                backgroundColor: 'rgba(81, 33, 131, 0.5)',
                padding: '10px',
                width: '80%',
                height: '50px',
                fontSize: '16px',
                alignSelf: 'center',
                display: 'flex',
                justifyContent: 'center',
                margin: '0 auto',
                color: 'white',
                borderRadius: '10px',
                borderColor: 'rgba(189, 252, 251, 0.3)',
              }}
              onSelect={(e) => setSelection({ start: e.target.selectionStart, end: e.target.selectionEnd })}
            />
          </div>
        </div>
        {centeredRelease?.ArtworkURL && <img src={centeredRelease.ArtworkURL} alt="Preview" style={styles.imagePreview} />}
        {uploading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="loader">Loading...</div>
          </div>
        ) : (
          <button
            onClick={handlePost}
            style={{
              width: '140px',
              height: '50px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              backgroundColor: 'transparent',
              color: 'white',
              fontSize: '16px',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            <img src={require('./assets/postDAT.png')} alt="Post" style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }} />
          </button>
        )}
        {isSearchingComments && (
          <div style={styles.overlayContainer}>
            <div style={styles.userSuggestionContainer}>
              {searchResultsComments.map((user) => (
                <button key={user.id} onClick={() => handleSelectUser(user)} style={styles.userSuggestion}>
                  {user.username}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  overlayContainer: {
    position: 'absolute',
    top: '12vh',
    left: '10vw',
    right: '10vw',
    zIndex: 5,
    alignSelf: 'center',
  },
  addCommentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2vh',
    marginTop: '2vh',
    top: '3vh',
    zIndex: 3,
  },
  userSuggestionContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingHorizontal: '10px',
    borderRadius: '5px',
    zIndex: 50000,
    alignSelf: 'center',
  },
  userSuggestion: {
    color: 'white',
    padding: '10px',
    fontSize: '16px',
    borderWidth: '1px',
    borderColor: '#CCCCCC',
    borderRadius: '5px',
    marginVertical: '2px',
    zIndex: 5000,
    alignSelf: 'center',
  },
  textInput: {
    backgroundColor: 'rgba(81, 33, 131, 0.5)',
    paddingHorizontal: '4vw',
    width: '80vw',
    paddingVertical: '0.7vh',
    marginBottom: '3vh',
    fontSize: '4vw',
    color: 'white',
    borderRadius: '20px',
    display: 'flex',
    alignSelf: 'center',
  },
  imagePreview: {
    width: '150px',
    height: '150px',
alignSelf: 'center',

    borderRadius: '10px',
  },
  POSTDAT: {
    width: '40vw',
    height: '7vh',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#6200ea',
    color: 'white',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
    alignSelf: 'center',
  },
  POSTDAT2: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    alignSelf: 'center',
  },
};

export { PostScreenShareDAT };
