import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from './AppContext';
import { doc, getDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import { firestore } from './firebase';
import { ProfileComponentFollowing } from './ProfileComponentFollowing';
import { ShareCreditsPopup } from './ShareCreditsPopup';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { getFunctions, httpsCallable } from 'firebase/functions';

const ProfilesDisplayShareCredits = ({ on1, on2, on3, on4, onProfilePress }) => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { selectedUserIdShare, setShowSuccessAlertShareCredits, setCurrentlySharingCredits, showCreditSharePopup, sharePostActive, setSharePostActive, followers, setShowCreditSharePopup, userId2, setSelectedUserIdShare } = useContext(AppContext);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);

  const navigate = useNavigate();

  const [paypalButton, setPaypalButton] = useState(false);
  const [paypalButtonsLoaded, setPaypalButtonsLoaded] = useState(false);

  const functions = getFunctions();

  const clientId = 'AUPVBkAu1c37lWNuP2jimRK5RnbpOs4pPv8ft6YYTBTS3RANAZ3bIoSaGUG4ymLDuLbTz6gh4QCxinmV'; 

  const loadPayPalScript = (clientId) => {
    return new Promise((resolve, reject) => {
      if (window.paypal) {
        resolve(window.paypal);
        return;
      }
      
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.onload = () => {
        if (window.paypal) {
          resolve(window.paypal);
        } else {
          reject(new Error('PayPal SDK could not be loaded.'));
        }
      };
      script.onerror = () => reject(new Error('PayPal SDK could not be loaded.'));
      document.body.appendChild(script);
    });
  };

  const loadPayPal = async () => {
    try {
      await loadPayPalScript(clientId);
    } catch (error) {
      console.error("Failed to load PayPal SDK", error);
    }
  };

  useEffect(() => {
    loadPayPal();
  }, []);
  
  const handlePayPalPayment = async (credits, cost) => {
    
    setPaypalButton(true);
    setPaypalButtonsLoaded(false);
    const createPayPalTransaction = httpsCallable(functions, 'createPayPalTransaction');
    try {
      const payload = { amount: cost.toString() };
      
      const response = await createPayPalTransaction(payload);
      const orderId = response.data.id;
  
      
  
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: cost.toString()
              }
            }]
          }).then(orderId => {
            
            return orderId;
          }).catch(error => {
            console.error("PayPal createOrder: Error creating order:", error);
            throw error;
          });
        },
        onApprove: async (data, actions) => {
          
          try {
            const details = await actions.order.capture();
            const { id } = details;
  
            
  
            // Proceed with credit addition after PayPal order is captured
            const addCreditsWeb = httpsCallable(functions, 'addCreditsWeb');
            

            setPaypalButton(false);
  
            await addCreditsWeb({ orderId: id, credits });

            
  
            setPaypalButton(false);
            alert('Transaction completed by ' + details.payer.name.given_name);
  
          } catch (error) {
            console.error("PayPal onApprove: Error capturing payment or adding credits:", error);
            if (error.message.includes('ORDER_ALREADY_CAPTURED')) {
              
              alert('Transaction already completed.');
            } else {
              alert('There was an error processing your transaction. Please try again.');
            }
            setPaypalButton(false);
          }
        },
        onCancel: (data) => {
          
          setPaypalButton(false);
        },
        onError: (err) => {
          console.error("PayPal Buttons onError: Error with PayPal buttons:", err);
          alert('There was an error loading PayPal. Please try again later.');
          setPaypalButton(false);
        },
        onRender: () => {
          
          setPaypalButtonsLoaded(true);
        }
      }).render('#paypal-button-container');
  
    } catch (error) {
      console.error("Error creating PayPal transaction:", error);
      setPaypalButton(false);
    }
  };

  // Listener to close the PayPal window
  window.addEventListener('message', (event) => {
    if (event.data === 'closePayPalWindow') {
      window.close();
    }
  });

  const handleShareCredits = async (amount) => {
    setCurrentlySharingCredits(true);
    setShowCreditSharePopup(false);
    const functions = getFunctions();
    const transferCredits = httpsCallable(functions, 'transferCredits');
 
    try {
       // Detailed logging of selectedUserIdShare
      const response = await transferCredits({
        receiverId: selectedUserIdShare,
        amount: amount // the amount of credits to transfer
      });
 
      
      setShowSuccessAlertShareCredits(true);
      setCurrentlySharingCredits(false);
    } catch (error) {
      setCurrentlySharingCredits(false);
      console.error("handleShareCredits: Error sharing credits:", error);
    }
  };

  useEffect(() => {
    if (isSelecting) {
      onProfilePress();
      setIsSelecting(false);
    }
  }, [isSelecting, onProfilePress]);

  useEffect(() => {
    loadInitialProfiles();
  }, [followers]);

  const loadInitialProfiles = async () => {
    if (Array.isArray(followers)) {
      setIsLoading(true);
      let loadedProfiles = [];
      for (const userId of followers.slice(0, 9)) {
        if (userId !== userId2) {
          const userData = await getCachedUserDocMinimal(userId);
          if (userData) {
            loadedProfiles.push(userData);
          }
        }
      }
      setProfiles(loadedProfiles);
      setFilteredProfiles(loadedProfiles);
      setIsLoading(false);
    } else {
      console.warn("Followers data is not valid.");
    }
  };

  const getCachedUserDocMinimal = async (userId) => {
    let userDataCached = [];

    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const freshUserData = userDocSnap.data();
      userDataCached = {
        ...freshUserData,
        timestamp: new Date().getTime()
      };

      return {
        userId: userId,
        voiceDropMuted: userDataCached.voiceDropMuted,
        accountType: userDataCached.accountType,
        name: userDataCached.name,
        username: userDataCached.username,
        profileImageUrl: userDataCached.profileImageUrl
      };
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const handleProfileSelect = async (profileData) => {
    const selectedUserId = profileData.userId;
    setSelectedUserIdShare(selectedUserId);
    setShowCreditSharePopup(true);
  };

  const fetchProfilesFromFirestore = async (searchText) => {
    if (!searchText.trim()) {
      setFilteredProfiles(profiles); // Show initial batch when search is empty
      return;
    }
  
    const searchTextLower = searchText.toLowerCase().trim();
    const usernameSearchText = searchTextLower.replace(/\s+/g, ''); // Remove spaces for username search
  
    setIsLoading(true);
  
    try {
      // Query for username fields matching the search text partially
      const userQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '>=', searchTextLower),
        where('username_lower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      // Query for artist names matching the search text partially
      const userArtistNameQuery = query(
        collection(firestore, 'users'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      // Query for exact username match with spaces removed
      const exactUsernameQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '==', usernameSearchText),
        limit(7)
      );
  
      const results = await Promise.all([
        getDocs(userQuery),
        getDocs(userArtistNameQuery),
        getDocs(exactUsernameQuery)
      ]);
  
      const combinedItems = results.flatMap((snap) => 
        snap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          type: 'user'
        }))
      );
  
      const uniqueItems = Object.values(combinedItems.reduce((acc, item) => {
        const key = `${item.id}_${item.type}`;
        if (!acc[key]) {
          acc[key] = item;
        }
        return acc;
      }, {}));
      
      // Sort by username matching name without spaces
      uniqueItems.sort((a, b) => {
        const aNameNoSpaces = a.name.replace(/\s+/g, '').toLowerCase();
        const bNameNoSpaces = b.name.replace(/\s+/g, '').toLowerCase();
        const aUsername = a.username.toLowerCase();
        const bUsername = b.username.toLowerCase();
      
        if (aUsername === aNameNoSpaces && bUsername !== bNameNoSpaces) return -1;
        if (aUsername !== aNameNoSpaces && bUsername === bNameNoSpaces) return 1;
      
        // Prioritize "Artist" account type
        if (a.accountType === 'Artist' && b.accountType !== 'Artist') return -1;
        if (a.accountType !== 'Artist' && b.accountType === 'Artist') return 1;
      
        return 0;
      });
  
      setFilteredProfiles(uniqueItems);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    debounceFetchProfiles(text);
  };

  const debounceFetchProfiles = debounce((text) => {
    fetchProfilesFromFirestore(text);
  }, 600);

  return (
    <div style={styles.container}>
      <img
        src={require('./assets/creditShareText2.png')}
        style={styles.CreditsDropdownImage}
        alt="Credit Share"
      />
      <div style={styles.addCreditsContainer}>
      <button style={styles.transparentButton} onClick={() => handlePayPalPayment(50, "5.64")}>
      </button>
      <button style={styles.transparentButton2} onClick={() => handlePayPalPayment(100, "10.79")}>
      </button>
      <button style={styles.transparentButton3} onClick={() => handlePayPalPayment(200, "21.09")}>
      </button>
      <button style={styles.transparentButton4} onClick={() => handlePayPalPayment(250, "26.24")}>
      </button>
      </div>
      <div style={styles.container2}>
        <input
          style={styles.searchInput}
          placeholder="Search..."
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div style={styles.scrollableContainer}>
          <div style={styles.listContainer}>
            {filteredProfiles.map(profile => (
              <ProfileComponentFollowing
                key={profile.userId}
                userProfile={profile}
                onPress={() => handleProfileSelect(profile)}
              />
            ))}
          </div>
        </div>
      </div>
      <Modal
        isOpen={showCreditSharePopup}
        onRequestClose={() => setShowCreditSharePopup(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 10000,
          },
          content: {
            height: '400px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            backgroundColor: 'transparent',
            padding: '20px',
            border: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <div style={styles.sharingPost}>
          <ShareCreditsPopup
            onShare={handleShareCredits}
          />
        </div>
      </Modal>
      <Modal
        isOpen={paypalButton}
        onRequestClose={() => setPaypalButton(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 10000,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '400px',
            width: '80%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            padding: '20px',
            border: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <div style={styles.sharingPost}>
          {!paypalButtonsLoaded && <div style={styles.loadingText}>Loading Checkout...</div>}
          <div id="paypal-button-container"></div>
        </div>
        {paypalButtonsLoaded && (
          <div style={styles.cancelContainer}>
            <button style={styles.cancelButton} onClick={() => setPaypalButton(false)}>Cancel</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  addCreditsContainer: {
    display: 'flex',
    width: '100%',
    height: '20%',
    top: '5.6%',
    position: 'absolute',
    margin: '0 auto',
    flexDirection: 'column',
    zIndex: 9000,
    opacity: 0.3,
    backgroundColor: 'transparent',
  },
  container2: {
    marginTop: '36vh',
    minHeight: '18vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  sharingPost: {
    width: '100%',
    height: '200px',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  cancelContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  cancelButton: {
    padding: '10px 20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    position: 'absolute',
    top: '180px',
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: 'none',
    fontSize: '20px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  loadingText: {
    fontSize: '20px',
    color: '#62fbfa',
    marginBottom: '10px',
  },
  CreditsDropdownImage: {
    position: 'absolute',
    top: '-15vh',
    height: '100vh',
    zIndex: 900,
  },
  searchInput: {
    height: '4vh',
    width: '75%',
    borderColor: 'rgba(189, 252, 251, 0.6)',
    fontSize: '16px',
    backgroundColor: 'transparent',
    borderWidth: 1,
    marginBottom: '2vh',
    borderRadius: 5,
    paddingLeft: 10,
    color: 'white',
    fontFamily: 'brhendrix',
    zIndex: 9000,
  },
  transparentButton: {
    position: 'relative',
    width: '200px',
    height: '35px',
    left: '5vw',
    marginTop: '0.2vh',
    marginBottom: '0.2vh',
    alignSelf: 'flex-start',
    padding: 10,
    zIndex: 9000,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  transparentButton2: {
    position: 'relative',
    width: '200px',
    height: '35px',
    left: '5vw',
    marginTop: '0.2vh',
    marginBottom: '0.2vh',
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    padding: 10,
    zIndex: 9000,
    border: 'none',
    cursor: 'pointer',
  },
  transparentButton3: {
    position: 'relative',
    width: '200px',
    height: '36px',
    left: '5vw',
    marginTop: '0.2vh',
    marginBottom: '0.4vh',
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    padding: 10,
    zIndex: 9000,
    border: 'none',
    cursor: 'pointer',
  },
  transparentButton4: {
    position: 'relative',
    width: '200px',
    height: '45px',
    left: '5vw',
    marginTop: '0.2vh',
    marginBottom: '0.2vh',
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
    padding: 10,
    zIndex: 9000,
    border: 'none',
    cursor: 'pointer',
  },
  activityIndicator: {
    marginTop: '2vh',
    left: '8vw',
  },
  scrollableContainer: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    zIndex: 9000,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
  },
};

export default ProfilesDisplayShareCredits;
