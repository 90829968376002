import React from 'react';
import { useNavigate } from 'react-router-dom';

const SupportPage = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  const navigate = useNavigate();
  const DISPLAY_TEXT = `Q: What is SPNDAT?

A: SPNDAT is an all-in-one social media, music, and video platform that is ad-free, algorithm free, and subscription free.

Q: How do I make purchases with SPNDAT?

A: Once you have uploaded credits to your account, simply select the item of interest and select BUYDAT.

Q: How do I get paid on SPNDAT?

A: When a vendor account is formed, you will add an email for accepting payouts. Here are the steps to receive your payout:

From your navigation wheel, select “MYDAT”.

Select “Earnings”. Here you will see your total earned credits translated into a dollar amount. You will then have the option to cash out the total amount earned or a portion of the total amount.

When you select "CASHDAT", your earnings will be processed through PayPal. You'll receive an email notification from PayPal with instructions on how to accept the payment. You can choose to have the funds deposited into either your PayPal or Venmo account, depending on your preference and availability in your region.

Please be advised that in order to cash out through our system, you must have earned at least $10 USD per cash out.

Q: How do I change my payout email?

A: From your navigation wheel, select "MYDAT", then CASHOUT Details to change the email that you will receive payouts from.

Q: How do I upload songs, videos, and Verch?

A: From your navigation wheel, select “MYDAT”. Select “My Content” - From there you can add content by simply following the prompts.

Q: How do I delete my account?

A: From your navigation wheel, select “MYDAT”. Select “Settings”. Select “delete account” and follow prompt.

Q: How do I block a user?

A: SPNDAT has two types of accounts: A basic user/fan account and Vendor accounts.

User/Fan accounts: In order to block someone on SPNDAT, the user simply needs to unfollow the person they wish to block. By unfollowing that user, the unfollowed user will not be able to message you. There's an additional Block button in user profiles located in the 3 dots section that will prevent them from being able to comment on your posts. SPNDAT user interactions are based on a mutual acceptance policy. 

Vendor accounts: Vendor accounts will have the ability to block specific users due to our spam and harassment guidelines.
Q: How to upload credits?

A: At the top of your screen is a number followed by a dollar sign. Example: “0$”. Tap the dollar sign and follow the prompts.

Q: Is SPNDAT free to use?

A: SPNDAT is a subscription-free service with no fees associated with using the app with the exception of in-app purchases.

Q: How does SPNDAT use my personal information?

A: Unless forced to turn over personal information through legal action, we will by no means share any personal data with any person(s) or entities. We are committed to protecting your privacy.

Q: How much money do I make per SPN/Video SPN on SPNDAT?

A: Price-per-play/view: When a user SPNs a video or song, the artist will make 0.05c per SPN minus an 8% fee from SPNDAT.

Q: Can I change the price of the content I sell on the platform?

A: No. The prices for items sold on SPNDAT are set by SPNDAT.

For any questions or support inquiries not covered in this FAQ, email support@spndat.com. 

`;

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <p style={styles.fullScreenText}>{DISPLAY_TEXT}</p>
      </div>
      <div style={styles.extraSpace}></div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  extraSpace: {
    height: '450px',
      },
  scrollContainer: {
    width: '100%',
    maxWidth: '800px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
    zIndex: 2,
  },
  fullScreenText: {
    marginTop: '3vh',
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'left',
    fontFamily: 'brhendrix',
    letterSpacing: '-0.4px',
    marginLeft: '3vw',
    marginRight: '3vw',
    whiteSpace: 'pre-wrap',
  },
};

export default SupportPage;
